import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useAddPlatformMutation } from "../../services/apis";
import Loader from "../../utils/loader";
import { PopUp } from "../../utils/alert";
import { handleErrors } from "../../utils/error";

function ReplyModal({ show, close }) {
  const senderEmail = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );

  const [addPlatform, { data, isLoading, error }] = useAddPlatformMutation();

  const [query, setQuery] = useState("");

  const handleQuery = () => {
    if (!query.trim()) {
      PopUp("Description is mandatory", "", "error");
      return;
    }
    addPlatform({
      userId: senderEmail?._id,
      query,
    });
  };

  useEffect(() => {
    if (data?.success) {
      PopUp("Query generated successfully", "", "success");
      setQuery("");
      close();
    }
    handleErrors(error);
  }, [data, error]);

  return (
    <>
      <Modal
        show={show}
        size="lg"
        dialogClassName="modal-rounded modal-350"
        centered
        onHide={close}
      >
        <Modal.Header
          className="text-center border-0 justify-content-center border-bottom position-relative abolute-close modal-header flex-column"
          closeButton
        >
          <Modal.Title className="fs-21-600">Generate Query</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center pt-1">
          <div className="mb-3 mt-2 col-md-12">
            <label htmlFor="" className="w-100 text-start">
              Sender Email
            </label>
            <input
              placeholder="Enter Query"
              className="textarea-1 w-100"
              value={senderEmail?.email}
              disabled
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="" className="w-100 text-start">
              Description
            </label>
            <textarea
              placeholder="Enter Query"
              className="textarea-1 w-100"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            ></textarea>
          </div>
          <div className="form-group d-flex justify-content-center gap-3">
            <button
              className="btn btn-outline-primary rounded-pill h-55 px-5 fs-16 w-100"
              onClick={() => close()}
            >
              Close
            </button>
            <button
              className="btn btn-primary rounded-pill h-55 px-5 fs-16 w-100"
              onClick={handleQuery}
            >
              {isLoading ? <Loader /> : "Send"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReplyModal;
