import React, { useEffect, useState } from "react";
import { useGetReportBarQuery } from "../services/apis";
import { useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import HeaderForPDF from "./HeaderForPDF";
import FooterForPDF from "./FooterForPDF";

const ChartWithBarWidth = () => {
  const { toPDF, targetRef } = usePDF({
    filename: `riskByStatus.pdf`,
  });
  const { id } = useParams();
  const { data: info, isFetching, refetch } = useGetReportBarQuery(id);
  const [data, setData] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false)


  useEffect(() => {
    refetch();
  }, []);
  const handleExportToPDF = () => {
    setRefreshPage(true); // Set refreshPage to true
  };
  useEffect(() => {
    // Call toPDF when refreshPage is true
    if (refreshPage) {
      toPDF();
      setRefreshPage(false); // Reset refreshPage to false after calling toPDF
    }
  }, [refreshPage, toPDF]);

  useEffect(() => {
    const newArray = info?.data?.map((item) => ({
      name: item?.status?.name, // Change key name and assign desired value
      value: item?.count, // Change key name and assign desired value
      color:
        (item?.status?.value === "bg-secondary opacity-50" && item?.status?.name === "Draft")
          ? "#6c757d"
          : (item?.status?.value === "bg-orange" && item?.status?.name === "Mitigate")
          ? "#E75D0F"
          : (item?.status?.value === "bg-dark-blue" && item?.status?.name === "Transferred")
          ? "#168eb4"
          : (item?.status?.value === "bg-red" && item?.status?.name === "Closed")
          ? "#B00126"
          : (item?.status?.value === "bg-dark-red" && item?.status?.name === "Rejected")
          ? "#b41616"
          : (item?.status?.value === "bg-green" && item?.status?.name === "Open")
          ? "#3A7557"
          : item?.status?.value, // Retain the original key and value
    }));
    setData(newArray);
  }, [info]);
  // console.log(info.data)

  return (
    <>
      <button className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex"     onClick={() => handleExportToPDF()}>
      PDF Download
      </button>
      <div className="px-5 pt-4" ref={targetRef}>
      {refreshPage ?  <HeaderForPDF /> :""}  

        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={100}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value">
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {refreshPage ?  <FooterForPDF margin="96%"/> :"" }

      </div>
    </>
  );
};

export default ChartWithBarWidth;
