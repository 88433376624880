import React from 'react'
import { Outlet } from 'react-router-dom'
import EPRiskLogo from '../assets/images/EPRisk-logo.png';

const AuthOutlet = () => {
  return (
    <div className='authWrapper'>
        <div className="container">
          <div className='d-flex justify-content-between align-items-center'>
              <img src={EPRiskLogo} className='d-lg-block d-none col-lg-4 col-md-3 mx-auto' />
              <Outlet />
            </div>
        </div>
    </div>
  )
}

export default AuthOutlet