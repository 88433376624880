import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import CreateRiskMatrix from "../components/modals/CreateRiskMatrix";
import CreateProjectManagerModal from "../components/modals/CreateProjectManagerModal";
import ProjectManager from "../components/Contracts/ProjectManager";
import RiskManager from "../components/Contracts/RiskManager";
import ClientProjectManaget from "../components/Contracts/ClientProjectManaget";
import ClientRiskManager from "../components/Contracts/ClientRiskManager";
import { useSelector, useDispatch } from "react-redux";
import {
  useUpdateProjectMutation,
  useGetProjectDetailQuery,
  useUpdateProjectStatusMutation,
} from "../services/apis";
import { PopUp } from "../utils/alert";
import { useLocation } from "react-router-dom";
import { handleErrors } from "../utils/error";
import Loader from "../utils/loader";
import { setMatrixInfo } from "../services/slices/constants";
import { useNavigate } from "react-router-dom";
import {
  setPmName,
  setRmName,
  setClientPmName,
  setClientRmName,
  setPmId,
  setRmId,
  setClientPmId,
  setClientRmId,
  setUpdateBox,
} from "../services/slices/constants";
import EditShowMatrix from "../components/EditShowMatrix";
import Update from "../components/Update";

const EditProject = () => {
  const { id, contractId, projectVersion } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newData, setNewData] = useState([]);

  const profileInfo = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );

  const { data: projectDetail, refetch } = useGetProjectDetailQuery(id);
  const [updateProjectStatus, { data: statusData }] =
  useUpdateProjectStatusMutation();
  
  useEffect(() => {
    refetch();
    dispatch(setUpdateBox(true));
  }, [id]);
  
  useEffect(() => {
    console.log('projectDetail?.data?.result[0]',projectDetail?.data?.result[0])
    setNewData(projectDetail?.data?.result[0]);
  }, [projectDetail]);

  const matrixInfo = useSelector((state) => state?.constants?.matrixInfo);
  const pmId = useSelector((state) => state?.constants?.pmId);
  const rmId = useSelector((state) => state?.constants?.rmId);
  const clientPmId = useSelector((state) => state?.constants?.clientPmId);
  const clientRmId = useSelector((state) => state?.constants?.clientRmId);
  const [updateProject, { data, isLoading, error }] =
    useUpdateProjectMutation();
  const [riskMatrix, setRiskMatrix] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectNo, setProjectNo] = useState("");
  const [client, setClient] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rangeData, setRangeData] = useState({
    likeStatus: "",
    costImpactStatus: "",
    currency: "",
    likeObject: [],
    costImpact: [],
    colorDesc: [],
    selected: [],
    customCost: false,
    customTypeName: "",
    timeStatus: false,
    timeDropdown: "",
    timeImpact: [],
    customImpact: [],
  });
  const showRiskMatrix = () => setRiskMatrix(true);
  const dontshowRiskMatrix = () => setRiskMatrix(false);
  const closeRiskMatrix = () => setRiskMatrix(false);
  const [project, setProject] = useState(false);

  const [conShow, setConShow] = useState(false);
  const showProject = () => setProject(true);

  useEffect(() => {
    if (!newData) return;
    const {
      projectName,
      description,
      startDate,
      endDate,
      projectNumber,
      client,
    } = newData;
    const { epPM, epRM, clientPM, clientRM } = newData || {};
    setProjectName(projectName);
    setDescription(description);
    setStartDate(startDate?.split("T")[0]);
    setEndDate(endDate?.split("T")[0]);
    setProjectNo(projectNumber);
    setClient(client);
    const actions = [
      setPmName(epPM?.name),
      setRmName(epRM?.name),
      setClientPmName(clientPM?.name),
      setClientRmName(clientRM?.name),
      setPmId(epPM?._id),
      setRmId(epRM?._id),
      setClientPmId(clientPM?._id),
      setClientRmId(clientRM?._id),
    ];
    actions.forEach((action) => dispatch(action));
  }, [newData]);

  const handleProject = () => {
    const requiredFields = [
      { field: id, message: "Contract ID is missing" },
      { field: pmId, message: "Project Manager ID is missing" },
      { field: rmId, message: "Risk Manager ID is missing" },
      { field: clientPmId, message: "Client Project Manager ID is missing" },
      { field: clientRmId, message: "Client Risk Manager ID is missing" },
      { field: projectNo, message: "Project Number is missing" },
      { field: projectName, message: "Project Name is required" },
      { field: description, message: "Description is required" },
      { field: startDate, message: "Project start date is required" },
      { field: endDate, message: "Project end date is required" },
      { field: matrixInfo, message: "Please create matrix" },
    ];
    const missingField = requiredFields.find((field) => !field.field);
    if (missingField) {
      PopUp(missingField.message, "", "error");
      return;
    }

    if (new Date(endDate) < new Date(startDate)) {
      PopUp("End date cannot be earlier than the start date", "", "error");
      return;
    }

    const likeArr = rangeData?.likeObject?.map((item) => ({
      ...item,
      likelihood: String(item["likelihood"]),
    }));
    const newLikeArr = likeArr.map(({ _id, ...rest }) => rest);

    const costArr = rangeData?.costImpact?.map((item) => ({
      ...item,
      impact: String(item["impact"]),
    }));

    const newCostArr = costArr.map(({ _id, ...rest }) => rest);

    const timeArr = rangeData?.timeImpact?.map((item) => ({
      ...item,
      impact: String(item["impact"]),
    }));

    const newTimeArr = timeArr.map(({ _id, ...rest }) => rest);

    const customArr = rangeData?.customImpact?.map((item) => ({
      ...item,
      impact: String(item["impact"]),
    }));

    const newCustomArr = customArr.map(({ _id, ...rest }) => rest);
    const newMatrixInfo = rangeData?.matrixInfo?.map(
      ({ _id, ...rest }) => rest
    );

    const newMatrixData = newData?.riskMatrixId?.matrix?.map(
      ({ _id, ...rest }) => rest
    );

    const newColorArr = rangeData?.colorDesc?.map(({ _id, ...rest }) => rest);

    const obj = {
      id: id,
      projectName: projectName,
      description: description,
      epPM: pmId,
      epRM: rmId,
      clientPM: clientPmId,
      clientRM: clientRmId,
      client,
      startDate: startDate,
      endDate: endDate,
      scaleTypeLike: rangeData?.likeStatus,
      scaleTypeCostImpact: rangeData?.costImpactStatus,
      scaleTypeTimeImpact: rangeData?.timeStatus,
      costImpactType: rangeData?.currency,
      timeImpactType: rangeData?.timeDropdown,
      isCustomeImpact: JSON.parse(rangeData?.customCost),
      likelihoodDetail: newLikeArr,
      costImpactDetail: newCostArr,
      timeImpactDetail: newTimeArr,
      projectVersion: Number(projectVersion),
      colorDescription: newColorArr,
      matrix: newMatrixInfo?.length > 0 ? newMatrixInfo : newMatrixData,
    };

    if (JSON.parse(rangeData?.customCost)) {
      obj.customImpactDetail = newCustomArr;
      obj.customImpactTextDetail = rangeData?.customTypeName;
    }

    updateProject(obj);
  };

  useEffect(() => {
    if (data?.success) {
      PopUp("Project updated successfully", "", "success");
      dispatch(setMatrixInfo([]));
      navigate(-1);
    }
    handleErrors(error);
  }, [error, data]);

  const handleRange = (data) => {
    setRangeData(data);
  };

  useEffect(() => {
    if (statusData?.code === 200) {
      refetch();
      return;
    }
  }, [statusData]);

  useEffect(() => {

    if (projectDetail?.success) {
      if (
        projectDetail?.data?.result[0]?.isUpdatingBy?._id !== profileInfo?._id &&
        projectDetail?.data?.result[0]?.isUpdating
      ) {
        setConShow(true);
        return;
      }
      if (projectDetail?.data?.result && projectDetail?.data?.result[0]?.isUpdating === false) {
        updateProjectStatus({ id, isUpdating: true });
      }
    }
    return () => {
      setTimeout(() => {
        if (!window.location.pathname.includes("/edit-project")) {
          if (
            projectDetail?.data?.result[0]?.isUpdatingBy?._id === profileInfo?._id &&
            projectDetail?.data?.result[0]?.isUpdating
          ) {
            updateProjectStatus({ id, isUpdating: false });
          }
        }
      }, 1000);
    };
  }, [projectDetail, location.pathname]);

  return (
    <>
      <div className="seachBarWIthButton d-flex justify-content-between align-items-center">
        <h6 className="section-heading mb-0">UPDATE PROJECT</h6>

      </div>
      <div className="row mt-4">
        <div className="row col-md-9 mx-0 px-0">
          <div className="row mx-0 px-0">
            <div className="mb-4 col-md-4">
              <label className="cf_label" htmlFor="">Project Name</label>{" "}

              <input
                type="text"
                placeholder="Project Name*"
                className="input-1 w-100"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
            <div className="mb-4 col-md-4">
              <label htmlFor="">Project Number</label>{" "}

              <input
                type="text"
                value={projectNo}
                onChange={(e) => setProjectNo(e.target.value)}
                disabled
                className="input-1 w-100"
              />
            </div>
            <div className="mb-4 col-md-4">
              <label htmlFor="">Client</label>{" "}

              <input
                type="text"
                value={client}
                onChange={(e) => setClient(e.target.value)}
                placeholder="Client"
                className="input-1 w-100"
              />
            </div>
          </div>
          <div className="col-md-8 mx-0 px-0 row h-fit-col">
            <div className="mb-4 col-md-6">
              {/* <label htmlFor="">EP Project Manager</label>{" "} */}

              <ProjectManager />
            </div>
            <div className="mb-4 col-md-6">
              {/* <label htmlFor="">EP Risk Manager</label>{" "} */}

              <RiskManager />
            </div>
            <div className="mb-4 col-md-6">
              {/* <label htmlFor="">Client Project Manager</label>{" "} */}

              <ClientProjectManaget />
            </div>
            <div className="mb-4 col-md-6">
              {/* <label htmlFor="">Client Risk Manager</label>{" "} */}

              <ClientRiskManager />
            </div>
            <div className="mb-4 col-md-6">
              <label className="cf_label" htmlFor="">Start Date</label>{" "}

              <input
                type="date"
                className="input-1 w-100"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
            <div className="mb-4 col-md-6">
              <label className="cf_label" htmlFor="">End Date</label>{" "}

              <input
                type="date"
                min={startDate}
                className="input-1 w-100"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </div>
            <div className="col-md-12">
              <label className="cf_label" htmlFor="">Project Description</label>{" "}

              <textarea
                rows="4"
                className="textarea-1 w-100"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              >
                Description
              </textarea>
            </div>
          </div>
          <div className="col-md-4 ms-auto d-flex flex-column gap-3">
            <div  onClick={projectDetail?.data?.totalRiskCount > 0 ? dontshowRiskMatrix : showRiskMatrix}>

              <div className="wh-315 ms-auto">


                    <div className="position-relative w-100 h-100 p-30 flex1 ps-3">
                      <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center pt-5">

                        <p
                          className="m-0 top-arrow" style={{ fontSize: '12px' }} role="button"
                        >
                          Impact
                        </p>
                      </div>
                      <EditShowMatrix
                        info={rangeData?.matrixInfo}
                        data={newData?.riskMatrixId?.matrix}
                        matrixDesc={newData?.riskMatrixId?.colorDescription}
                      />
                      <div className="position-absolute start-0 end-0 m-0 ps-4 flex1">
                        <p
                          className="text-center m-0 right-arrow" style={{ fontSize: '12px' }} role="button"
                        >
                          Likelihood
                        </p>

                      </div>
                    </div>


              </div>
            </div>
            {/* <button
              className="btn btn-outline-primary rounded-pill fs-14-normal px-3 h-48"
              onClick={showRiskMatrix}
            >
              Update Risk Matrix
            </button> */}
            <div className="d-flex flex-column gap-3 mt-4 mt-auto w-315 ms-auto ps-3">
              <button
                className="btn btn-outline-primary fs-16 px-3 h-48"
                onClick={() => navigate(-1)}
              >
                CANCEL
              </button>
              <button
                className="btn btn-trinary fs-16 px-3 h-48"
                onClick={handleProject}
              >
                {isLoading ? <Loader /> : "SAVE"}
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-3 mx-0 px-0">

        </div>
      </div>
      {/* </div> */}
      {conShow && <Update title="project" />}
      <CreateRiskMatrix
        show={riskMatrix}
        close={closeRiskMatrix}
        dataList={newData?.riskMatrixId}
        rangeData={handleRange}
      />
      <CreateProjectManagerModal show={project} close={showProject} />
    </>
  );
};

export default EditProject;
