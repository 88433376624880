import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Edit from "../assets/icons/edit.svg";
import Dummy from "../assets/icons/dummy.svg";
import {
	useGetUserInfoQuery,
	useChangeNotificationMutation,
} from "../services/apis";
import { PopUp } from "../utils/alert";
import { useSelector } from "react-redux";

const Profile = () => {
	const profileId = useSelector(
		(state) => state.constants.profileInfo.data._id
	);

	console.log("profileId", profileId);
	const [image, setImage] = useState("");
	const [imageUrl, setImageUrl] = useState("");

	const { data, refetch } = useGetUserInfoQuery(profileId);
	const [changeNotification, { data: changeData }] =
		useChangeNotificationMutation();

	const handleImage = (event) => {
		const newFile = event.target.files[0];
		setImage(newFile);
		setImageUrl(URL.createObjectURL(newFile));
	};

	const handleChange = (data) => {
		changeNotification({
			allowNotification: data ? "true" : !data ? "false" : "true",
		});
	};

	const handleChangeMail = (data) => {
		changeNotification({
			allowMailNotification: data ? "true" : !data ? "false" : "true",
		});
	};

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		if (changeData?.success) {
			PopUp("Notification updated successfully", "", "success");
			refetch();
		}
	}, [changeData]);

	console.log("data?.data", data?.data);

	return (
		<>
			<div className="row mt-3">
				<div className="col-md-5 mx-auto">
					<div className="changePassword">
						<div class="upateProfileWrap mb-4 mx-auto">
							<img
								src={imageUrl ? imageUrl : Dummy}
								alt=""
								className="w-100 h-100 rounded-circle"
							/>
							<input
								type="file"
								name=""
								onChange={handleImage}
								id="updatePofile"
								class="updatePofile"
								placeholder="Enter New Email*"
							/>
							<label htmlFor="updatePofile">
								<img src={Edit} alt="" />
							</label>
						</div>
						<div>
							<div class="form-check form-switch">
								<input
									class="form-check-input"
									type="checkbox"
									role="switch"
									id="flexSwitchCheckDefault"
									onChange={(e) => handleChange(e.target.checked)}
									checked={data?.data?.allowNotification}
								/>
								<label
									class="form-check-label text-dark ms-1"
									for="flexSwitchCheckDefault"
								>
									System Notification
								</label>
							</div>
						</div>
						<div>
							<div class="form-check form-switch">
								<input
									class="form-check-input"
									type="checkbox"
									role="switch"
									id="flexSwitchCheckDefault"
									onChange={(e) => handleChangeMail(e.target.checked)}
									checked={data?.data?.allowMailNotification}
								/>
								<label
									class="form-check-label text-dark ms-1"
									for="flexSwitchCheckDefault"
								>
									Email Notification
								</label>
							</div>
						</div>
						<div class="input-group mt-4 gap-3">
							<Link
								to="/change-password"
								class="btn btn-primary rounded-pill w-100 justify-content-between px-4"
							>
								Change Password <span>&#8250;</span>
							</Link>
							<Link
								to="/change-email"
								class="btn btn-primary rounded-pill w-100 justify-content-center-between px-4"
							>
								Change Email <span>&#8250;</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;
