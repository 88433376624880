export const INVITE = "/request-invite";

export const FORGOT = "/forget-password";
export const OTP = "/otp-verification";
export const RESET = "/reset-password";
export const SET = "/set-password";
export const CONTRACTS = "/contracts";
export const VIEW_CONTRACT = "/view-contract/:id/:isManager";
export const ADD_CONTRACT = "/add-project/:id";
export const VIEW_PROJECT = "/view-project/:id";
export const ADD_RISK = "/add-risk/:id";
export const EDIT_RISK = "/edit-risk/:id/:projectId/:verify/:bools";
export const RISK_CALC = "/risk-evm-calculation";
export const RISK_MANAGER = "/risk-manager";
export const ENGINNEERS = "/engineers";
export const SAVED_REPORT = "/saved-reports";
export const CMS = "/cms";
export const ADD_CMS = "/add-cms";
export const EDIT_CMS = "/edit-cms";
export const PLATFORM_SUPPORT = "/platform-support";
export const VIEW_TICKETS = "/view-ticket/:id";
export const ASSESSMENT = "/assessment/:id/:riskId";
export const CHANGE_PASS = "/change-password";
export const EDIT_PROJECT = "/edit-project/:id/:contractId/:projectVersion";
