import React, { Component } from "react";

class GlobalErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "grid",
            placeItems: "center",
          }}
        >                
          <h3>Something went wrong globally.</h3>
        </div>
      );
    }

    return this.props.children;
  }
}

export default GlobalErrorBoundary;
