import React from "react";

const NoData = ({ text = "" }) => {
  const noData = {
    width: "100%",
    height: "200px",
    display: "grid",
    placeItems: "center",
    color: "#e75d0f",
    textAlign: "center",
    border: "1px solid",
    borderRadius: "6px",
  };
  return (
    <div style={noData} className="nodatafound">
      <p className="mb-0">
        No data to be found here. <br />
        {text}
      </p>
    </div>
  );
};

export default NoData;
