import React, { useState, useEffect } from "react";
import AssessmentDropDown from "./AssessmentDropDown";
import { useParams } from "react-router-dom";
import { useGetProjectDetailQuery } from "../services/apis";

const AssesmentSection = ({
  title,
  info,
  unmitValue,
  dataValue,
  scoreData,
}) => {
  const { id } = useParams();
  const [cost, setCost] = useState({
    description: "",
    end: "",
    start: "",
    impact: 0,
  });
  const [time, setTime] = useState({
    description: "",
    end: "",
    start: "",
    impact: 0,
  });
  const [quality, setQuality] = useState({
    description: "",
    end: "",
    start: "",
    impact: 0,
  });

  const [impactVal, setImpactVal] = useState("");
  const [costVal, setCostVal] = useState("");
  const [impact, setImpact] = useState("");
  const [timeVal, setTimeVal] = useState("");
  const [qualityVal, setQualityVal] = useState("");

  const { data: projectDetail, refetch } = useGetProjectDetailQuery(id);

  const costInfo = scoreData?.some((item) => item?.value === "Cost");
  const timeInfo = scoreData?.some((item) => item?.value === "Scheduled");
  const qualityInfo = scoreData?.some((item) => item?.value === "Quality");

  const mitigatedIndex = dataValue?.findIndex(
    (item) => item?.assessmentType === "Mitigated"
  );

  useEffect(() => {
    refetch();
  }, [id]);

  const handleValue = (data, id, index) => {
    if (id === 1) {
      setImpact(data);
      setImpactVal(index);
    }
    if (id === 2) {
      setCost(data);
      setCostVal(index);
    }
    if (id === 3) {
      setTime(data);
      setTimeVal(index);
    }
    if (id === 4) {
      setQuality(data);
      setQualityVal(index);
    }
  };

  useEffect(() => {
    info({
      impact: impactVal,
      liklihood: Math.max(costVal, timeVal, qualityVal),
    });
  }, [impactVal, costVal, timeVal, qualityVal]);

  useEffect(() => {
    unmitValue({
      impact,
      cost,
      time,
      quality,
    });
  }, [impact, cost, time, quality]);

  useEffect(() => {
    costInfo && setCost({ impact: dataValue && dataValue?.costScore });
    timeInfo && setTime({ impact: dataValue && dataValue?.timeScore });
    qualityInfo && setQuality({ impact: dataValue && dataValue?.qualityScore });
  }, [dataValue]);

  return (
    <div>
      {/* <h5 className="table-heading">{title}</h5> */}
        <div>
          <div>
            <label className="cf_label" htmlFor="">{title} Probability Score</label>
            <AssessmentDropDown
              title={`${title} Probability Score`}
              data={projectDetail?.data?.result[0]?.riskMatrixId?.likelihoodDetail}
              // projectDetail={projectDetail?.data?.result[0]?.riskMatrixId?.costImpactType}
              projectDetail="%"

              itemValue={handleValue}
              keyValue={1}
              dataInfo={dataValue}
              mitigatedIndex={mitigatedIndex}
            />
          </div>
          <div>
            <label className="cf_label" htmlFor="">{title} Cost Impact Score</label>
            <AssessmentDropDown
              title={`${title} Cost Impact Score`}
              data={projectDetail?.data?.result[0]?.riskMatrixId?.costImpactDetail}
              projectDetail={projectDetail?.data?.result[0]?.riskMatrixId?.costImpactType  == "dollar" ? "$":projectDetail?.data?.result[0]?.riskMatrixId?.costImpactType }
              itemValue={handleValue}
              keyValue={2}
              dataInfo={dataValue}
              mitigatedIndex={mitigatedIndex}
            />
          </div>
          <div>
            <label className="cf_label" htmlFor="">{title} Scheduled Impact Score</label>
            <AssessmentDropDown
              title={`${title} Scheduled Impact Score`}
              data={projectDetail?.data?.result[0]?.riskMatrixId?.timeImpactDetail}
              projectDetail={projectDetail?.data?.result[0]?.riskMatrixId?.timeImpactType}
              itemValue={handleValue}
              keyValue={3}
              dataInfo={dataValue}
              mitigatedIndex={mitigatedIndex}
            />
          </div>

          {projectDetail?.data?.result[0]?.riskMatrixId?.isCustomeImpact && (
            <div>
              <label className="cf_label" htmlFor="">
                {title}{" "}
                {projectDetail?.data?.result[0]?.riskMatrixId?.customImpactTextDetail}{" "}
                Impact Score
              </label>
              <AssessmentDropDown
                title={`${title} ${projectDetail?.data?.result[0]?.riskMatrixId?.customImpactTextDetail} Impact Score`}
                data={projectDetail?.data?.result[0]?.riskMatrixId?.customImpactDetail}
                itemValue={handleValue}
                keyValue={4}
                dataInfo={dataValue}
                mitigatedIndex={mitigatedIndex}
              />
            </div>
          )}
        </div>


          {/* <div>
            <div className="d-flex justify-content-between mb-2 fs-16-06">
              <span className="fs-16 text-black">{title} PI Score Cost</span>
              <span className="fs-16 text-black">
                {cost?.impact ||
                  (!!dataValue?.length &&
                    dataValue[title.includes("Mitigated") ? mitigatedIndex : 0]
                      ?.costScore)}
              </span>
            </div>

            <div className="d-flex justify-content-between mb-2 fs-16-06">
              <span className="fs-16 text-black">{title} PI Score Time</span>
              <span className="fs-16 text-black">
                {time?.impact ||
                  (!!dataValue?.length &&
                    dataValue[title.includes("Mitigated") ? mitigatedIndex : 0]
                      ?.timeScore)}
              </span>
            </div>

            {projectDetail?.data[0]?.riskMatrixId?.isCustomeImpact && (
              <div className="d-flex justify-content-between mb-2 fs-16-06">
                <span className="fs-16 text-black">
                  {title}{" "}
                  {projectDetail?.data[0]?.riskMatrixId?.customImpactTextDetail}{" "}
                  Score
                </span>
                <span className="fs-16 text-black">
                  {quality?.impact ||
                    (!!dataValue?.length &&
                      dataValue[
                        title.includes("Mitigated") ? mitigatedIndex : 0
                      ]?.customPosition)}
                </span>
              </div>
            )}
          </div> */}
      </div>

  );
};

export default AssesmentSection;
