import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Eye from "../assets/icons/eye.svg";
import EyeClose from "../assets/icons/eye-close.svg";
import { CONTRACTS, FORGOT, INVITE } from "../utils/routes";
import { useLoginMutation, useUpdateFCMMutation } from "../services/apis";
import { handleErrors } from "../utils/error";
import Loader from "../utils/loader";
import { useForm } from "react-hook-form";
import { setProfileInfo } from "../services/slices/constants";
import { useDispatch } from "react-redux";
import { getToken } from "../firebase";



const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eyeStatus, setEyeStatus] = useState(false);
  const [login, { data, isLoading, error }] = useLoginMutation();
  const [updateFCM, { data: fcmData, error: fcmERR }] = useUpdateFCMMutation();

  const [isTokenFound, setTokenFound] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    login({
      email: data?.email,
      password: data?.password,
    });
  };

  useEffect(() => {
    handleErrors(fcmERR);
  }, [fcmERR]);

  useEffect(() => {
    if (data?.success) {
      localStorage.setItem("token", data?.data?.token);
      dispatch(setProfileInfo(data));
      navigate(CONTRACTS);
      async function tokenFunc() {
        const item = await getToken(setTokenFound);
        if (item) {
          updateFCM({
            id: data?.data?._id,
            fcmToken: item,
          });
        }
        return item;
      }

      tokenFunc();
    }

    handleErrors(error);
  }, [data, error, navigate]);

  const renderError = (field) => {
    return (
      <p style={{ fontSize: "12px", color: "red" }}>{errors[field]?.message}</p>
    );
  };

  return (
    <div className="mw-480 s">
      <div className="authBox bg-white rounded d-flex align-items-center flex-column">
        <div className="authHead w-100">
          <div className="wh-114 mx-auto">
            <img src={Logo} alt="Logo" className="img-contain" />
          </div>
        </div>
        <div className="authBody w-100">
          <h6 className="authTitle text-center mb-3">Sign in</h6>
          <form>
            <div className="input-group mb-3">
              <input
                type="email"
                className="form-control rounded-pill border"
                placeholder="Email*"
                {...register("email", {
                  required: "Email Address is required",
                })}
              />
            </div>
            {renderError("email")}
            <div className="input-group mb-3 position-relative">
              <span
                className="absolute-center-icon"
                onClick={() => setEyeStatus((prev) => !prev)}
                style={{ cursor: "pointer" }}
              >
                {eyeStatus ? (
                  <img src={EyeClose} alt="password" />
                ) : (
                  <img src={Eye} alt="password" />
                )}
              </span>
              <input
                type={eyeStatus ? "text" : "password"}
                className="form-control rounded-pill border"
                placeholder="Password*"
                {...register("password", { required: "Password is required" })}
              />
            </div>
            {renderError("password")}
            <div className="input-group mt-4">
              <button
                className="btn btn-primary rounded-pill w-100 justify-content-center"
                onClick={handleSubmit(onSubmit)}
                style={{ textAlign: "center" }}
              >
                {isLoading ? <Loader /> : "Sign In"}
              </button>
            </div>
          </form>
        </div>
        <div className="authFoot w-100 text-center ">
          <Link className="link-grey" to={FORGOT}>
            Forgot Password?
          </Link>
        </div>
      </div>
      <div className="mt-20 text-center">
        <Link className="link-dark" to={INVITE}>
          Not registered yet?{" "}
          <span className="text-primary">REQUEST INVITE NOW!</span>
        </Link>
      </div>
    </div>
  );
};

export default Login;
