import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import ProjectManager from "../Contracts/ProjectManager";
import RiskManager from "../Contracts/RiskManager";
import ClientRiskManager from "../Contracts/ClientRiskManager";
import ClientProjectManager from "../Contracts/ClientProjectManaget";
import { useSelector } from "react-redux";
import { useAddContractMutation } from "../../services/apis";
import { PopUp } from "../../utils/alert";
import { useNavigate } from "react-router-dom";
import { CONTRACTS } from "../../utils/routes";
import { handleErrors } from "../../utils/error";
import Loader from "../../utils/loader";

function AddContractModal({ show, close }) {
  const navigate = useNavigate();
  const pmId = useSelector((state) => state?.constants?.pmId);
  const rmId = useSelector((state) => state?.constants?.rmId);
  const clientPmId = useSelector((state) => state?.constants?.clientPmId);
  const clientRmId = useSelector((state) => state?.constants?.clientRmId);
  const profileData = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [addContract, { data, error, isLoading }] = useAddContractMutation();

  const handleContract = () => {
    const validationChecks = [
      { field: title, message: "Title is required" },
      { field: description, message: "Description is required" },
      // { field: pmId, message: "Project manager is required" },
      // { field: rmId, message: "Risk manager is required" },
      // { field: clientPmId, message: "Client project manager is required" },
      // { field: clientRmId, message: "Client risk manager is required" },
    ];

    for (const check of validationChecks) {
      if (!check.field.trim()) {
        PopUp(check.message, "", "error");
        return;
      }
    }

    addContract({
      contractName: title,
      description,
      epPM: profileData?._id,
      epRM: rmId,
      clientPM: clientPmId,
      clientRM: clientRmId,
    });
  };

  useEffect(() => {
    if (data?.success) {
      PopUp("Contract added successfully", "", "success");
      close();
      navigate(CONTRACTS);
    }
    handleErrors(error);
  }, [data, error]);

  useEffect(() => {
    if (!show) {
      setTitle("");
      setDescription("");
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        size="md"
        dialogClassName="modal-rounded modal-600"
        centered
        onHide={close}
      >
        <Modal.Header
          className="text-center border-0 justify-content-center position-relative abolute-close"
          closeButton
        >
          {/* <Modal.Title className="fs-21-600">Create new contract</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="row mx-0 pt-3">
          <div className="col-md-6">
            <div className="input-group mb-2">
              <label htmlFor="" className="cf_label mb-1">Contract Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="input-2 w-100 px-3"
                placeholder=""
              />
            </div>
            <div className="input-group">
            <label htmlFor="" className="cf_label fs-16 mb-1">Contract Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="11"
                className="textarea-2 w-100"
                placeholder=""
              ></textarea>
            </div>
          </div>
          <div className="col-md-6 mt-md-0 mt-2 d-flex flex-column">
            <div className="mb-2">
            <ProjectManager />
            </div>
            <div className="mb-2">
            <RiskManager />
            </div>
            <div className="mb-2">
            <ClientProjectManager />
            </div>
            <div>
            <ClientRiskManager />
            </div>
            <div className="mt-auto">
            <button
              className="btn btn-trinary rounded-pill h-55 px-5 fs-16 w-100 rounded-3px fw-bold"
              onClick={handleContract}
            >
              {isLoading ? <Loader /> : "SAVE"}
            </button>
          </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddContractModal;
