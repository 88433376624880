import React, { useEffect, useState } from "react";
import ChartTwo from "../components/ChartTwo";
import ChartOne from "../components/ChartOne";
import { usePDF } from "react-to-pdf";

import ChartWithBarWidth from "../components/ChartThree";
import TimeLineRisk from "../components/TimeLineRisk";
import { useRiskMatrixStatusReportQuery } from "../services/apis";
import { useParams } from "react-router-dom";
import ShowMatrixReport from "../components/ShowMatrixReport";
import Accordion from "react-bootstrap/Accordion";
import SixMonthChart from "../components/SixMonthChart";
import PiChart from "../components/PiChart";
import PiChartRiskType from "../components/PieChartRiskType";
import RegisterTable from "../components/RegisterTable";

const RiskReport = () => {
	const { toPDF, targetRef } = usePDF({
		filename: `matrixReport.pdf`,
	});
	const { id } = useParams();
	const [unMitData, setUnMitData] = useState([]);
	const [currentData, setCurrentData] = useState([]);
	const [mitData, setMitData] = useState([]);
	const { data, refetch } = useRiskMatrixStatusReportQuery(id);

	useEffect(() => {
		refetch();
	}, []);

	const findCount = (arr) => {
		const firstArray = data?.data?.RiskMatrix?.matrix;
		const secondArray = arr?.map((item) => ({
			xAxis: item?.riskMatrixValue.xAxis,
			yAxis: item?.riskMatrixValue.yAxis,
			counts: item?.count,
		}));
		const secondArrayMap = {};
		secondArray?.forEach((item) => {
			const key = `${item.xAxis}-${item.yAxis}`;
			secondArrayMap[key] = item.counts;
		});
		const updatedFirstArray = firstArray?.map((item) => {
			const key = `${item.xAxis}-${item.yAxis}`;
			const count = secondArrayMap[key] || 0;
			return { ...item, count };
		});

		return updatedFirstArray;
	};

	useEffect(() => {
		const unmitInfo = findCount(data?.data?.UnMitigated);
		setUnMitData(unmitInfo);
		const currentInfo = findCount(data?.data?.Current);
		setCurrentData(currentInfo);
		const miInfo = findCount(data?.data?.Mitigated);
		setMitData(miInfo);
	}, [data]);

	return (
		<>
			<div className="row mt-3">
				<Accordion
					defaultActiveKey=""
					className="border-0 gap-3 d-flex flex-column"
				>
					<Accordion.Item eventKey="15">
						<Accordion.Header>
							Top-10 Risks in Terms of Initial Cost Evaluation
						</Accordion.Header>
						<Accordion.Body>
							<ChartOne />
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							Unmitigated, Current and Mitigated matrix report
						</Accordion.Header>
						<Accordion.Body>
							<button
								className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex"
								onClick={() => toPDF()}
							>
								PDF Download
							</button>
							<div
								className="d-flex justify-content-center px-5 mt-4"
								ref={targetRef}
							>
								<div
									style={{ display: "flex", gap: "2em", flexWrap:"wrap", justifyContent:'center' }}
									className="mt-2 mb-3"
								>
									<div className="">
										<h5 className="mb-4" style={{ fontWeight: "bold" }}>
											UnMitigated
										</h5>
										<div style={{ width:'250px' }}>
											<ShowMatrixReport data={unMitData} />
										</div>
									</div>

									<div className="">
										<h5 className="mb-4" style={{ fontWeight: "bold" }}>
											Current
										</h5>
										<div style={{ width:'250px' }}>
										<ShowMatrixReport data={currentData} />
										</div>
									</div>

									<div className="">
										<h5 className="mb-4" style={{ fontWeight: "bold" }}>
											Mitigated
										</h5>
										<div style={{ width:'250px' }}>
										<ShowMatrixReport data={mitData} />
										</div>
									</div>
								</div>
							</div>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="5">
						<Accordion.Header>6 Months Risk Report</Accordion.Header>
						<Accordion.Body>
							<SixMonthChart list={data?.data?.RiskMatrix?.colorDescription} />
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<Accordion.Header>Risk By Status</Accordion.Header>
						<Accordion.Body>
							<ChartWithBarWidth />
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>
							{" "}
							Risk By Ownership, Severity and Cost Exposure
						</Accordion.Header>
						<Accordion.Body>
							<ChartTwo list={data?.data?.RiskMatrix?.colorDescription} />
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<Accordion.Header> Proximity of Risk</Accordion.Header>
						<Accordion.Body>
							{" "}
							<TimeLineRisk />
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="6">
						<Accordion.Header> Risk Assessment Status Report</Accordion.Header>
						<Accordion.Body>
							<PiChart list={data?.data?.RiskMatrix?.colorDescription} />
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="7">
						<Accordion.Header> Risk Type Status Report</Accordion.Header>
						<Accordion.Body>
							<PiChartRiskType
								list={data?.data?.RiskMatrix?.colorDescription}
							/>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="135">
						<Accordion.Header> Spot Register</Accordion.Header>
						<Accordion.Body>
							<RegisterTable />
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
		</>
	);
};

export default RiskReport;
