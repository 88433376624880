import React, { useState, useEffect, useRef } from "react";
import {
  useGetProjectListMutation,
  useGetProjectListExportMutation,
} from "../services/apis";
import { Link } from "react-router-dom";
import NoData from "../components/NoData";
import Pencil from "../assets/icons/pencil.svg";
import Eye from "../assets/icons/eye-color.svg";
import moment from "moment";
import { useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import Pagination from "../components/Pagination";
import { PAGE_LIMIT } from "../utils/constants";
import { useSelector } from "react-redux";
import { ExportToExcel } from "../components/ExportToExcel";

const ProjectList = ({ isManager }) => {
  const { profileInfo } = useSelector((state) => state?.constants);
  const { id } = useParams();
  const [newData, setNewData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({});

  const [exportData, setExportData] = useState([]);

  const [getProjectList, { data, isLoading, error }] =
    useGetProjectListMutation();

  const [getProjectListExport, { data: projectExport }] =
    useGetProjectListExportMutation();
  const initialRender = useRef(true);
  const obj = {
    id,
    limit: PAGE_LIMIT,
    page,
  };

  useEffect(() => {
    if (!initialRender.current) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      if (search !== "") {
        obj.search = search;
      }
      const timeoutId = setTimeout(() => {
        getProjectList(obj);
      }, 1000);
      setSearchTimeout(timeoutId);
    } else {
      initialRender.current = false;
    }
  }, [search]);

  useEffect(() => {
    if (search !== "") {
      obj.search = search;
    }
    getProjectList(obj);
  }, [page]);

  useEffect(() => {
    if (data?.success) {
      setNewData(data?.data?.data);
    }
  }, [data]);

  useEffect(() => {
    getProjectListExport({ id, limit: 1000000, page: 1 });
  }, []);

  useEffect(() => {
    setPageData(data?.data);
  }, [data]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const newData = projectExport?.data?.data?.map((item) => ({
      "Project No": item?.projectNumber,
      "Project Name": item?.projectName,
      Description: item?.description,
      Client: item?.client,
      "EP Project Manager": item?.epPM?.name,
      "EP Risk Manager": item?.epRM?.name,
      "Client Project Manager": item?.clientPM?.name,
      "Client Risk Manager": item?.clientRM?.name,
      "Start Date": moment(item?.startDate).format("DD MMM YYYY"),
      "End Date": moment(item?.endDate).format("DD MMM YYYY"),
    }));
    setExportData(newData);
  }, [projectExport]);

  return (
    <>
      <div className="row mt-1">
        <div className="col-md-12">
          <div className="px-0 seachBarWIthButton d-flex justify-content-between align-items-center gap-1">
            <input
              type="search"
              placeholder="Search Projets"
              className="rounded-pill searchBar"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
              
              {newData?.length > 0 && (
                <ExportToExcel apiData={exportData} fileName={"projectData"} />
              )}

          </div>
          <div className="table-responsive br-10 mt-1">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <table
                  class="table table-style-1 table-bg-3 mb-0"
                  style={{ width: "100%" }}
                >
                  {newData?.length === 0 ? (
                    <NoData />
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th className="text-dark">
                            EP Code
                          </th>
                          <th>
                            Project Title
                          </th>
                          <th style={{ minWidth: "300px" }}>Description</th>
                          <th className="text-dark">
                            EP Project Manager
                          </th>
                          <th className="text-dark">
                            EP Risk Manager
                          </th>
                          <th>
                            Client Project manager
                          </th>
                          <th>
                            Client Risk manager
                          </th>
                          <th>
                            Start
                          </th>
                          <th>
                            End
                          </th>
                          {/* <th
                            style={{ position: "sticky", top: "0", right: "0" }}
                          >
                            Edit
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {!!newData?.length &&
                          newData?.map((item, index) => {
                            return (
                              <tr key={item?._id}>
                                <td className="fw-bold fs-16">
                                  {" "}
                                  <Link
                                    to={`/view-project/${item?._id}`}
                                    style={{ color: "#e75d0f" }}
                                    className="fs-16 fw-bold"
                                  >
                                    {item?.projectNumber}
                                  </Link>
                                </td>
                                <td>
  {item?.projectName && item?.projectName?.length > 32 ?
    `${item?.projectName?.slice(0, 32)}...` :
    item?.projectName}
</td>

                                <td
                                  style={{
                                    overflowX: "auto",
                                    whiteSpace: "wrap",
                                    cursor: "pointer",
                                  }}
                                  title={item?.description}
                                >
                                  <div className="short-para">
                                  {item?.description?.length > 80
                                    ? item?.description.slice(0, 80) + "..."
                                    : item?.description}
                                  </div>
                                </td>
                                <td>{item?.epPM?.name}</td>
                                <td>{item?.epRM?.name}</td>
                                <td>{item?.clientPM?.name}</td>
                                <td>{item?.clientRM?.name}</td>
                                <td>
                                  {moment(item?.startDate).format(
                                    "DD MMM YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.endDate).format("DD MMM YYYY")}
                                </td>
                                {/* <td
                                  style={{
                                    position: "sticky",
                                    top: "0",
                                    right: "0",
                                  }}
                                >
                                  <div className="table-action">
                                   
                                    {(profileInfo?.data?.userRole === 0 ||
                                      JSON.parse(isManager)) && (
                                      <Link
                                        to={`/edit-project/${item?._id}/${id}/${item?.projectVersion}`}
                                      >
                                        <img
                                          src={Pencil}
                                          alt="Edit"
                                        />
                                      </Link>
                                    )}
                                  </div>
                                </td> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </>
                  )}
                </table>
              </>
            )}
          </div>
          {pageData?.totalRecords > PAGE_LIMIT && (
            <Pagination
              data={pageData}
              pageNo={handlePageChange}
              pageVal={page}
            />
          )}
           {profileInfo?.data?.userRole === 0 && (
                <Link
                  className="mt-1 btn btn-dashed-dark text-uppercase fs-14-normal py-4 align-items-center d-flex flex-column gap-1"
                  to={`/add-project/${id}`}
                >
                    <i
                      class="fa-solid fa-plus"
                      style={{ fontWeight: "bold" }}
                    ></i> ADD PROJECT
                </Link>
              )}
        </div>
       
      </div>
    </>
  );
};

export default ProjectList;
