import React from "react";
import { useNavigate } from "react-router-dom";

const Update = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "fixed",
        top: "0%",
        background: "white",
        width: "80%",
        height: "100%",
        zIndex: "100",
      }}
    >
      <div
        style={{
          background: "white",
          width: "100%",
          height: "100%",
          padding: "7em 2em 2em 2em",
          display: "grid",
          placeItems: "center",
          textAlign: "center",
        }}
      >
        <div>
          <h5>Someone is updating this {title} right now</h5>
          <p>You can wait for a while</p>
          <div
            style={{
              display: "flex",
              gap: "2em",
              justifyContent: "center",
            }}
          >
            <button
              onClick={() => navigate(-1)}
              className="btn btn-trinary btn-sm mt-4 "
            >
              Go back
            </button>
            <button
              onClick={() => window.location.reload()}
              className="btn btn-trinary btn-sm mt-4"
            >
              refresh page
            </button>
          </div>
        </div>
        {/* <button
              onClick={() => navigate(-1)}
              className="btn btn-primary btn-sm mt-4 "
            >
              refresh
            </button> */}
      </div>
    </div>
  );
};

export default Update;
