import { createSlice } from "@reduxjs/toolkit";

export const getConstants = createSlice({
  name: "constants",
  initialState: {
    pmId: "",
    rmId: "",
    clientPmId: "",
    clientRmId: "",
    pmName: "",
    rmName: "",
    clientPmName: "",
    clientRmName: "",
    matrixId: "",
    matrixInfo: {},
    impactValue: [],
    likeValue: [],
    imapctType: "",
    updateBox: true,
    profileInfo: [],
    editMitigationStatus: 0,
    notiHead: "",
    contractLink: {},
    projectLink: {},
    riskLink: {},
  },
  reducers: {
    setPmId: (state, action) => {
      state.pmId = action.payload;
    },
    setRmId: (state, action) => {
      state.rmId = action.payload;
    },
    setClientPmId: (state, action) => {
      state.clientPmId = action.payload;
    },
    setClientRmId: (state, action) => {
      state.clientRmId = action.payload;
    },

    setContractLinks: (state, action) => {
      state.contractLink = action.payload;
    },

    setProjectLinks: (state, action) => {
      state.projectLink = action.payload;
    },

    setRiskLinks: (state, action) => {
      state.riskLink = action.payload;
    },

    setPmName: (state, action) => {
      state.pmName = action.payload;
    },
    setRmName: (state, action) => {
      state.rmName = action.payload;
    },
    setClientPmName: (state, action) => {
      state.clientPmName = action.payload;
    },
    setClientRmName: (state, action) => {
      state.clientRmName = action.payload;
    },

    setMatrixId: (state, action) => {
      state.matrixId = action.payload;
    },
    setImpactValue: (state, action) => {
      state.impactValue = action.payload;
    },
    setLikeValue: (state, action) => {
      state.likeValue = action.payload;
    },
    setMatrixInfo: (state, action) => {
      state.matrixInfo = action.payload;
    },
    setImpactType: (state, action) => {
      state.imapctType = action.payload;
    },

    setUpdateBox: (state, action) => {
      state.updateBox = action.payload;
    },

    setProfileInfo: (state, action) => {
      state.profileInfo = action.payload;
    },

    setEditMitigationStatus: (state, action) => {
      state.editMitigationStatus = action.payload;
    },
    setNotiHead: (state, action) => {
      state.notiHead = action.payload;
    },
  },
});

export const {
  setPmId,
  setRmId,
  setClientPmId,
  setClientRmId,
  setProjectLinks,
  setRiskLinks,
  setMatrixId,
  setMatrixInfo,
  setImpactValue,
  setLikeValue,
  setClientPmName,
  setClientRmName,
  setPmName,
  setRmName,
  setImpactType,
  setUpdateBox,
  setProfileInfo,
  setEditMitigationStatus,
  setNotiHead,
  setContractLinks,
} = getConstants.actions;

export default getConstants.reducer;
