import React from "react";
import LeftSidebar from "./LeftSidebar";
import { Outlet } from "react-router-dom";
import TopHeader from "./TopHeader";

const InnerOutlet = () => {
  return (
    <div className="innerPagesWrapper d-flex flex-lg-row flex-column vh-100">
      <div className="leftSidebar fixed-top">
        <LeftSidebar />
      </div>
      <div className="rightSidebar">
        <TopHeader />
        <div className="p-35-50">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default InnerOutlet;
