import React, { useState, useEffect, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import User from "../assets/icons/ProfileUser.svg";
import CreateRiskMatrix from "../components/modals/CreateRiskMatrix";
import CreateProjectManagerModal from "../components/modals/CreateProjectManagerModal";
import {
  useGetProjectUserQuery,
  useUpdateRiskMutation,
  useGetAllRiskListQuery,
  useGetProjectDetailQuery,
  useGetAssessmentQuery,
  useUpdateRiskStatusMutation,
} from "../services/apis";
import moment from "moment";

import RiskDrop from "../components/RiskDrop";
import MitigationModal from "../components/modals/MitigationModal";
import { useParams } from "react-router-dom";
import ShowRiskMatrix from "../components/ShowRiskMatrix";
import { PopUp } from "../utils/alert";
import { handleErrors } from "../utils/error";
import { useNavigate } from "react-router-dom";
import { useGetRiskByDetailQuery } from "../services/apis";
import MitigationList from "../components/MitigationList";
import Loader from "../utils/loader";
import { useDispatch, useSelector } from "react-redux";
import CreateRiskOwner from "../components/modals/CreateRiskOwner";
import { useLocation } from "react-router-dom";
import Update from "../components/Update";
import { setRiskLinks } from "../services/slices/constants";
import Breadcrumbs from "../components/Breadcrumbs";

const EditRisk = () => {
  const { id, projectId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const statusCount = useSelector(
    (state) => state?.constants?.editMitigationStatus
  );

  const profileInfo = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );

  const navigate = useNavigate();
  const [riskMatrix, setRiskMatrix] = useState(false);
  const [mitiShow, setMitiShow] = useState(false);
  const [userData, setUserData] = useState([]);
  const [riskStatus, setRiskStatus] = useState([]);

  const [rollData, setRollData] = useState("");

  const [riskType, setRiskType] = useState([]);
  const [riskSubType, setRiskSubType] = useState([]);
  const [riskSubTypeOrg, setRiskSubTypeOrg] = useState([]);
  const [riskSubTypeProject, setRiskSubTypeProject] = useState([]);
  const [riskSubTypeExternal, setRiskSubTypeExternal] = useState([]);
  const [projectPhase, setProjectPhase] = useState([]);
  const [projectAllocation, setProjectAllocation] = useState([]);
  const [currency, setCurrency] = useState("");
  const [riskId, setRiskId] = useState("");
  const [title, setTitle] = useState("");
  const [riskOwner, setRiskOwner] = useState({
    name: "",
    value: "",
  });
  const [riskStatusId, setRiskStatusId] = useState("");
  const [riskTypeId, setRiskTypeId] = useState("");
  const [riskSubTypeId, setRiskSubTypeId] = useState("");
  const [riskTypeOrgId, setRiskTypeOrgId] = useState("");
  const [riskTypeProjectId, setRiskTypeProjectId] = useState("");
  const [riskTypeExternalId, setRiskTypeExternalId] = useState("");
  const [projectPhaseId, setProjectPhaseId] = useState("");
  const [allocationId, setAllocationId] = useState("");
  const [riskDate, setRiskDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [comment, setComment] = useState("");
  const [riskCause, setRiskCause] = useState("");
  const [eventRisk, setEventRisk] = useState("");
  const [riskImpact, setRiskImpact] = useState("");
  const [newData, setNewData] = useState([]);
  const [stateName, setStateName] = useState("");
  const [ownerSearch, setOwnerSearch] = useState("");
  const [userShow, setUserShow] = useState(false);
  const [conShow, setConShow] = useState(false);

  const { data: riskDetail, refetch: riskDetailRefetch } =
    useGetRiskByDetailQuery(id);
console.log('riskDetail', riskDetail)
  const { data: projectData } = useGetProjectDetailQuery(projectId);

  const { data: assessInfo, refetch: assessRefetch } =
    useGetAssessmentQuery(id);

  const [updateRiskStatus, { data: statusData }] =
    useUpdateRiskStatusMutation();

  useEffect(() => {
    setNewData(projectData?.data?.result[0]?.riskMatrixId);
  }, [projectData]);

  useEffect(() => {
    setCurrency();
  }, [projectData]);

  const [check, setCheck] = useState(true);

  const { data, refetch } = useGetAllRiskListQuery();

  const { data: users, refetch: userRefetch } = useGetProjectUserQuery();

  const [updateRisk, { data: riskData, isLoading, error }] =
    useUpdateRiskMutation();

  useEffect(() => {
    refetch();
    userRefetch();
    assessRefetch();
  }, []);

  useEffect(() => {
    riskDetailRefetch();
  }, [mitiShow, statusCount]);

  useEffect(() => {
    riskDetailRefetch();
  }, []);

  useEffect(() => {
    const updatedData = users?.data?.filter((item) => item?.userRole !== 0);
    setUserData(updatedData);
  }, []);
  const closeRiskMatrix = () => setRiskMatrix(false);

  const [project, setProject] = useState(false);
  const showProject = () => setProject(true);

  useEffect(() => {
    const setDataFromRiskDetail = () => {
      const riskData = rollData === "" ? riskDetail?.data : rollData;
      if (riskData) {
        setTitle(riskData.riskName);
        setRiskId(riskData.riskNumber || riskDetail?.data?.riskNumber);
        setRiskTypeId(riskData.riskType?._id);
        setRiskDate(riskData.riskDate?.split("T")[0]);
        setRiskStatusId(riskData.riskStatus?._id);
        setRiskTypeExternalId(riskData.externalRisk?._id);
        setRiskTypeProjectId(riskData.pmRisk?._id);
        setRiskTypeOrgId(riskData.orgRisk?._id);
        setRiskSubTypeId(riskData.techRisk?._id);
        setComment(riskData.comment);
        setRiskCause(riskData.casuse);
        setEventRisk(riskData.riskEvent);
        setStartDate(riskData.startDate?.split("T")[0]);
        setEndDate(riskData.endDate?.split("T")[0]);
        setRiskImpact(riskData.riskImpact);
        setProjectPhaseId(riskData.projectPhase?._id);
        setAllocationId(riskData.contAllocation?._id);
        setRiskOwner({
          name: riskData.riskOwner?.name,
          value: riskData.riskOwner?._id,
        });
      }
    };

    setDataFromRiskDetail();
  }, [riskDetail?.data, rollData]);

  useEffect(() => {
    const optionTypes = {
      riskStatus: setRiskStatus,
      riskType: setRiskType,
      riskSubTypeTechnical: setRiskSubType,
      riskSubTypeOrg: setRiskSubTypeOrg,
      riskSubTypeProject: setRiskSubTypeProject,
      riskSubTypeExternal: setRiskSubTypeExternal,
      projectPhase: setProjectPhase,
      projectAllocation: setProjectAllocation,
    };

    data?.data?.forEach((item) => {
      const setter = optionTypes[item?.optionType];
      if (setter) {
        setter(item?.options);
      }
    });
  }, [data]);

  const handleIds = (id, type) => {
    const idSetters = {
      1: setRiskStatusId,
      2: setRiskTypeId,
      3: setRiskSubTypeId,
      4: setRiskTypeOrgId,
      5: setRiskTypeProjectId,
      6: setRiskTypeExternalId,
      7: setProjectPhaseId,
      8: setAllocationId,
    };
    const setter = idSetters[type];
    if (setter) {
      setter(id);
    }
  };

  const handleStateName = (title) => {
    setStateName(title);
  };

  const handleRollData = (data) => {
    setRollData(data);
  };

  const handleRisk = () => {
    const fields = [
      { value: title, message: "Risk Name is Mandatory" },
      { value: riskId, message: "Risk ID is Mandatory" },
      { value: riskOwner, message: "Select Risk Owner" },
      { value: riskStatus, message: "Select Risk Status" },
      { value: riskDate, message: "Risk Date is Mandatory" },
      { value: riskTypeId, message: "Select Risk Type" },
      { value: projectPhaseId, message: "Select Project Phase" },
      { value: allocationId, message: "Select Contractual Allocation" },
      { value: riskCause, message: "Risk Cause is mandatory" },
      { value: eventRisk, message: "Risk Event is mandatory" },
      { value: riskImpact, message: "Risk Impact is mandatory" },
      { value: endDate, message: "Risk Start Date is mandatory" },
      // { value: comment, message: "Comment is mandatory" },
    ];

    for (const field of fields) {
      if (!field.value) {
        PopUp(field.message, "", "error");
        return;
      }
    }

    if (stateName === "Technical" && !riskSubTypeId) {
      PopUp("Risk Subtype (Technical) is mandatory", "", "error");
      return;
    }

    if (stateName === "Organizational" && !riskTypeOrgId) {
      PopUp("Risk Subtype (Technical) is mandatory", "", "error");
      return;
    }

    if (stateName === "Project Management" && !riskTypeProjectId) {
      PopUp("Risk Subtype (Technical) is mandatory", "", "error");
      return;
    }

    if (stateName === "External" && !riskTypeExternalId) {
      PopUp("Risk Subtype (Technical) is mandatory", "", "error");
      return;
    }
    console.log("riskDetail", riskDetail);
    console.log("stateName", stateName);
    const updatedFields = {};
    const riskData = riskDetail?.data;

    if (title !== riskData.riskName) {
      updatedFields.riskName = title;
    }

    if (riskOwner?.value !== riskData.riskOwner?._id) {
      updatedFields.riskOwner = riskOwner?.value;
    }

    if (riskStatusId !== riskData.riskStatus?._id) {
      updatedFields.riskStatus = riskStatusId;
    }

    if (riskDate !== moment(riskData.riskDate).format("YYYY-MM-DD")) {
      updatedFields.riskDate = riskDate;
    }

    if (riskTypeId !== riskData.riskType?._id) {
      updatedFields.riskType = riskTypeId;
    }

    if (riskCause !== riskData.casuse) {
      updatedFields.casuse = riskCause;
    }

    if (eventRisk !== riskData.riskEvent) {
      updatedFields.riskEvent = eventRisk;
    }

    if (riskImpact !== riskData.riskImpact) {
      updatedFields.riskImpact = riskImpact;
    }

    if (startDate !== moment(riskData.startDate).format("YYYY-MM-DD")) {
      updatedFields.startDate = startDate;
    }

    if (endDate !== moment(riskData.endDate).format("YYYY-MM-DD")) {
      updatedFields.endDate = endDate;
    }

    if (comment !== riskData.comment) {
      updatedFields.comment = comment;
    }

    if (projectPhaseId !== riskData.projectPhase?._id) {
      updatedFields.projectPhase = projectPhaseId;
    }

    if (allocationId !== riskData.contAllocation?._id) {
      updatedFields.contAllocation = allocationId;
    }

    switch (stateName) {
      case "Technical":
        if (riskSubTypeId !== riskData.techRisk?._id) {
          updatedFields.techRisk = riskSubTypeId;
        }
        // updatedFields.techRisk = riskSubTypeId || riskSubTypeId?._id;
        break;
      case "Organizational":
        if (riskTypeOrgId !== riskData.orgRisk?._id) {
          updatedFields.orgRisk = riskTypeOrgId;
        }
        // updatedFields.orgRisk = riskTypeOrgId || riskTypeOrgId?._id;
        break;
      case "Project Management":
        if (riskTypeProjectId !== riskData.pmRisk?._id) {
          console.log("Project Management working");
          updatedFields.pmRisk = riskTypeProjectId;
        }
        break;
      case "External":
        if (riskTypeExternalId !== riskData.externalRisk?._id) {
          console.log("External");
          updatedFields.externalRisk = riskTypeExternalId;
        }
        // updatedFields.externalRisk =
        //   riskTypeExternalId || riskTypeExternalId?._id;
        break;
      default:
        updatedFields.orgRisk = riskTypeOrgId || riskTypeOrgId?._id;
        break;
    }
    console.log("updatedFields", updatedFields);
    if (Object.keys(updatedFields).length > 0) {
      updatedFields.id = riskDetail?.data?._id;
      updatedFields.riskVersion = riskDetail?.data?.riskVersion;
      updateRisk(updatedFields);
    } else {
      PopUp("No changes detected", "", "info");
    }
  };

  useEffect(() => {
    if (riskData?.success) {
      PopUp("Risk updated successfully", "", "success");
      navigate(`/view-project/${projectId}`);
    }
    handleErrors(error);
  }, [riskData, error]);

  useEffect(() => {
    const updatedData = users?.data?.filter((item) =>
      item?.name.includes(ownerSearch.toLowerCase())
    );
    setUserData(updatedData);
  }, [ownerSearch]);

  useEffect(() => {
    setTimeout(() => {
      setCheck(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (statusData?.code === 200) {
      riskDetailRefetch();
      return;
    }
  }, [statusData]);

  useEffect(() => {
    dispatch(setRiskLinks({ id, name: riskDetail?.data?.riskName }));
  }, [riskDetail]);

  useEffect(() => {
    if (riskDetail?.success) {
      if (
        riskDetail?.data?.isUpdatingBy?._id !== profileInfo?._id &&
        riskDetail?.data?.isUpdating
      ) {
        setConShow(true);
        return;
      }
      if (riskDetail?.data && riskDetail?.data?.isUpdating === false) {
        updateRiskStatus({ id, isUpdating: true });
      }
    }
    return () => {
      setTimeout(() => {
        if (!window.location.pathname.includes("/edit-risk")) {
          if (
            riskDetail?.data?.isUpdatingBy?._id === profileInfo?._id &&
            riskDetail?.data?.isUpdating
          ) {
            updateRiskStatus({ id, isUpdating: false });
          }
        }
      }, 1000);
    };
  }, [riskDetail, location.pathname]);
  const filteredArray = assessInfo?.data?.filter(
    (obj) => obj.assessmentType !== "Mitigated"
  );

  console.log("riskDetail for id",riskDetail?.data?.riskName)
  return (
    <>
      <div className="row">
        <div className="col-xxl-6 col-lg-5 col-md-6">
          {/* <Breadcrumbs /> */}
          <h6 className="section-heading mb-0">{title?.length > 30 ? title?.slice(0,30) + '...' : title}</h6>
        </div>
        <div className="col-xxl-6 col-lg-7 col-md-6 mt-md-0 mt-3 text-md-end">
          <div className="mobile-48 d-inline-flex gap-md-3 gap-1 mx-0 equal-col-3 justify-content-end">
            <a
              onClick={() => navigate(`/risk-detail/${id}`)}
              className="btn btn-outline-dark fs-16 px-xl-5 px-3 h-48 text-uppercase"
            >
              Report
            </a>
            <button
              className="btn btn-outline-dark fs-16 px-xl-5 px-3 h-48 text-uppercase"
              onClick={() => navigate(`/assessment/${projectId}/${id}`, {
                state: {
                    data: {
                        mitiData: riskDetail?.data?.riskAction,
                        description:eventRisk
                    },
                },
            })}
            >
              Assessment
            </button>
            <button
              className="btn btn-def fs-16 px-xl-5 px-3 h-48 text-uppercase"
              onClick={() => setMitiShow(true)}
            >
              Mitigate
            </button>
          </div>
        </div>
      </div>
      <div
        className="row mt-4"
        // style={{ pointerEvents: rollData === "" ? "" : "none" }}
      >
        <div className="row col-xl-6 h-fit-col px-0 mx-0">
          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              Risk Title
            </label>{" "}
            <input
              type="text"
              value={title}
              placeholder="Risk Title"
              onChange={(e) => setTitle(e.target.value)}
              className="input-1 w-100"
            />
          </div>
          {/* <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">Risk ID</label>{" "}
            <input
              type="text"
              value={riskId}
              placeholder="Risk ID"
              className="input-1 w-100"
              disabled
            />
          </div> */}

          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              Risk Owner
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <Dropdown className="w-100">
              <Dropdown.Toggle
                className="input-1 w-100 dropWithArrow2 px-3"
                id="dropdown-basic"
              >
                {riskOwner?.name || "Risk Owner"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100 br-14 py-2 px-3">
                <input
                  type="search"
                  placeholder="Search user"
                  value={ownerSearch}
                  onChange={(e) => setOwnerSearch(e.target.value)}
                />
                <div className="scroll-120 thin-scroll mt-2">
                  {!!userData?.length &&
                    userData?.map((item) => {
                      return (
                        <label
                          htmlFor={item?._id}
                          className="customRadio d-flex align-items-center gap-2"
                          key={item?._id}
                        >
                          <input
                            type="radio"
                            name="demoradio"
                            id={item?._id}
                            value={riskOwner}
                            checked={item?._id === riskOwner?.value}
                            onChange={(e) =>
                              setRiskOwner({
                                name: item?.name,
                                value: item?._id,
                              })
                            }
                          />
                          <img src={User} alt="user" />
                          <h6 className="mb-0">
                            {item?.name}{" "}
                            <small>
                              {item?.userRole === 1
                                ? "Risk Manager"
                                : item?.userRole === 2
                                ? "Engineer"
                                : ""}
                            </small>
                          </h6>
                        </label>
                      );
                    })}
                </div>
                <Link
                  className="fs-12-600-link mt-2 text-center d-block"
                  onClick={() => setUserShow(true)}
                >
                  + Create new user
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              Contractual Allocation
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <RiskDrop
              list={projectAllocation}
              listTitle="Contractual Allocation"
              state={handleIds}
              updateValue={
                rollData === ""
                  ? riskDetail?.data?.contAllocation
                  : rollData?.contAllocation
              }
              numId={8}
            />
          </div>

          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              Risk Type
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <RiskDrop
              list={riskType}
              listTitle="Risk Type"
              state={handleIds}
              numId={2}
              stateName={handleStateName}
              updateValue={
                rollData === ""
                  ? riskDetail?.data?.riskType
                  : rollData?.riskType
              }
            />
          </div>
          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              Risk Status
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <RiskDrop
              list={riskStatus}
              listTitle="Risk Status"
              state={handleIds}
              numId={1}
              updateValue={
                rollData === ""
                  ? riskDetail?.data?.riskStatus
                  : rollData?.riskStatus
              }
            />
          </div>
          {/* {stateName === "Technical" && (
            <div className="mb-4 col-md-6">
              <label class="cf_label" htmlFor="">Risk Subtype (Technical)</label>{" "}
              <RiskDrop
                list={riskSubType}
                listTitle="Risk Subtype (Technical)"
                state={handleIds}
                numId={3}
                updateValue={
                  rollData === ""
                    ? riskDetail?.data?.techRisk
                    : rollData?.techRisk
                }
              />
            </div>
          )}
          {stateName === "Organizational" && (
            <div className="mb-4 col-md-6">
              <label class="cf_label" htmlFor="">Risk Subtype (Organizational)</label>{" "}
              <RiskDrop
                list={riskSubTypeOrg}
                listTitle="Risk Subtype (Organizational)"
                state={handleIds}
                numId={4}
                updateValue={
                  rollData === ""
                    ? riskDetail?.data?.orgRisk
                    : rollData?.orgRisk
                }
              />
            </div>
          )}
          {stateName === "Project Management" && (
            <div className="mb-4 col-md-6">
              <label class="cf_label" htmlFor="">Risk Subtype (Project Mgmt)</label>{" "}
              <RiskDrop
                list={riskSubTypeProject}
                listTitle="Risk Subtype (Project Mgmt)"
                state={handleIds}
                updateValue={
                  rollData === "" ? riskDetail?.data?.pmRisk : rollData?.pmRisk
                }
                numId={5}
              />
            </div>
          )}
          {stateName === "External" && (
            <div className="mb-4 col-md-6">
              <label class="cf_label" htmlFor="">Risk Subtype (External)</label>{" "}
              <RiskDrop
                list={riskSubTypeExternal}
                listTitle="Risk Subtype (External)"
                state={handleIds}
                updateValue={
                  rollData === ""
                    ? riskDetail?.data?.externalRisk
                    : rollData?.externalRisk
                }
                numId={6}
              />
            </div>
          )} */}
          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              Project Phase
            </label>{" "}
            <RiskDrop
              list={projectPhase}
              listTitle="Project Phase"
              state={handleIds}
              updateValue={
                rollData === ""
                  ? riskDetail?.data?.projectPhase
                  : rollData?.projectPhase
              }
              numId={7}
            />
          </div>
          <div className="mb-4 col-md-12">
            <label class="cf_label" htmlFor="">
              Risk Description
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <textarea
              rows="3"
              className="textarea-1 w-100"
              placeholder="Risk Event (Description)"
              value={eventRisk}
              onChange={(e) => setEventRisk(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4 col-md-12">
            <label class="cf_label" htmlFor="">
              Cause of Risk
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <textarea
              rows="3"
              className="textarea-1 w-100"
              placeholder="Cause Of Risk"
              value={riskCause}
              onChange={(e) => setRiskCause(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4 col-md-12">
            <label class="cf_label" htmlFor="">
              Risk Impact
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <textarea
              rows="3"
              className="textarea-1 w-100"
              placeholder="Risk Impact"
              value={riskImpact}
              onChange={(e) => setRiskImpact(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="row col-xl-6 h-fit-col px-0 mx-0">
          <div className="col-md-12 mb-4 d-flex">
            {filteredArray?.length > 0 &&
              filteredArray?.map((item) => {
                return (
                  <>
                    <div className="px-1" style={{ flex: "0 0 50%" }}>
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          {/* <div className="d-flex me-2 flex-column align-items-center justify-content-between gap-3 mb-4">
                          {projectData?.data[0]?.riskMatrixId?.timeImpactDetail
                            ?.slice()
                            .reverse()
                            .map((item, index) => (
                              <p
                                key={index}
                                className="my-2 m-0"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "11px",
                                }}
                              >
                                {item?.description}
                              </p>
                            ))}
                        </div> */}
                        </div>
                        <div className="col-md-9 mx-auto">
                          <h6
                            className="fs-14-normal text-end mb-0 lh-1"
                            style={{ textAlign: "left" }}
                          >
                            {item?.assessmentType} Risk:{" "}
                            {item?.riskMatrixValue?.yAxis *
                              item?.riskMatrixValue?.xAxis}
                          </h6>
                          <div>
                            <div className="position-relative w-100 h-100 p-30 flex1">
                              <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center pt-5">
                                <div className="d-flex me-2 align-items-center justify-content-between">
                                  {projectData?.data?.result[0]?.riskMatrixId?.timeImpactDetail
                                    ?.slice()
                                    .reverse()
                                    .map((item, index) => (
                                      <OverlayTrigger
                                        placement="left"
                                        overlay={
                                          <Tooltip id="tooltip-disabled">
                                            {item?.description}
                                          </Tooltip>
                                        }
                                      >
                                        <p
                                          key={index}
                                          className="m-0 hoverShow"
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "8px",
                                          }}
                                        >
                                          &nbsp;
                                        </p>
                                      </OverlayTrigger>
                                    ))}
                                </div>
                                <p
                                  className="m-0 top-arrow"
                                  style={{ fontSize: "12px" }}
                                  role="button"
                                >
                                  Impact
                                </p>
                              </div>
                              <ShowRiskMatrix
                                data={newData?.matrix}
                                matrixDesc={newData?.colorDescription}
                                column={item?.riskMatrixValue?.xAxis}
                                row={item?.riskMatrixValue?.yAxis}
                                width="230px"
                              />
                              <div className="position-absolute start-0 end-0 m-0 ps-5 flex1">
                                <p
                                  className="text-center m-0 right-arrow"
                                  style={{ fontSize: "12px" }}
                                  role="button"
                                >
                                  Likelihood
                                </p>
                                <div className="d-flex align-items-end justify-content-between">
                                  {projectData?.data?.result[0]?.riskMatrixId?.likelihoodDetail?.map(
                                    (item) => {
                                      return (
                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              {item?.description}
                                            </Tooltip>
                                          }
                                        >
                                          <p
                                            className="m-0 hoverShow"
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "8px",
                                            }}
                                          >
                                            &nbsp;
                                          </p>
                                        </OverlayTrigger>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="p-30 py-0">
                              <p
                                className="text-center d-flex justify-content-center align-items-center"
                                style={{
                                  marginTop: "1em",
                                  fontWeight: "500",
                                  borderRadius: "6px",
                                  padding: "0.5em 1em",
                                  fontSize: "14px",
                                  height: "46px",
                                }}
                              >
                                Risk Level: {item?.riskMatrixValue?.riskValue}
                              </p>
                            </div>
                            <div className="mb-4 pb-1">
                              <hr className="bg-black" />
                            </div>
                          </div>
                          <div>
                            {/* <div className="d-flex align-items-end justify-content-between mt-2">
                            {projectData?.data[0]?.riskMatrixId?.likelihoodDetail?.map(
                              (item) => {
                                return (
                                  <p
                                    style={{
                                      margin: "5px 0",
                                      fontWeight: "bold",
                                      fontSize: "11px",
                                      flex: 1,
                                      transform: "rotate(20deg)",
                                    }}
                                  >
                                    {item?.description}
                                  </p>
                                );
                              }
                            )}
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ height: "1em" }}></div>
                  </>
                );
              })}
          </div>

          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              Start date
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <input
              type="date"
              className="input-1 w-100"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              End date
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <input
              type="date"
              className="input-1 w-100"
              min={startDate}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 col-md-6"></div>
          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              Set a Reminder date
            </label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <input
              type="date"
              className="input-1 w-100"
              value={riskDate}
              max={endDate}
              onChange={(e) => setRiskDate(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 col-md-6">
            <label class="cf_label" htmlFor="">
              Comments
            </label>{" "}
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows="10"
              className="textarea-1 w-100"
              placeholder="Comments"
            ></textarea>
          </div>
          <div className="mb-4 col-md-6">
            <div className="mb-4">
              <div>
                <label className="cf_label" htmlFor="">
                  Value for Level of likelihood{" "}
                  {/* <span style={{ color: "red" }}>*</span> */}
                </label>
                <div
                  class="btn-group  border-0 overflow-hidden mt-1 w-100  justify-content-between"
                  role="group"
                  aria-label="Basic outlined example"
                  style={{ padding: "0px" }}
                >
                  {!!newData?.likelihoodDetail?.length &&
										newData?.likelihoodDetail?.map((item, index) => {
											return (
												<input
													type="text"
													key={item}
                          disabled={true}
													style={{
														background:
                          assessInfo?.data[0]?.probabilityValue ===
                          (item?.likelihood) &&
                            "#d9d9d9",
														cursor: "pointer",
													}}
													className="form-control text-center"
													value={item?.likelihood}
													// onClick={() =>
													// 	setLikeVal({
													// 		value: item?.likelihood,
													// 		position: item?.position,
													// 		description: item?.description,
													// 	})
													// }
												/>
											);
										})}
                  {/* <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  /> */}
                </div>
              </div>
              {/* <p
								style={{
									fontSize: "15px",
									fontWeight: "bold",
									marginTop: "6px",
									textAlign: "center",
								}}
							>
								{likeVal?.description}
							</p> */}
            </div>

            <div className="mb-4">
              <div>
                <label className="cf_label" htmlFor="">
                  Value for Level of impact{" "}
                  <strong className="text-uppercase">Cost</strong>{" "}
                  {/* <span style={{ color: "red" }}>*</span> */}
                </label>
                <div
                  class="btn-group  border-0 overflow-hidden mt-1 w-100 justify-content-between"
                  role="group"
                  aria-label="Basic outlined example"
                  style={{ padding: "0px" }}
                >
                  {!!newData?.costImpactDetail?.length &&
										newData?.costImpactDetail?.map((item, index) => {
											return (
												<input
													type="text"
													key={item?.impact}
													className="form-control text-center "
                          disabled={true}
													style={{
														background:
                            assessInfo?.data[0]?.costScore ===
                            (item?.impact) &&
															"#d9d9d9",
														cursor: "pointer",
													}}
													value={item?.impact}
													// onClick={() =>
													// 	setImpactVal({
													// 		value: item?.impact,
													// 		position: item?.position,
													// 		description: item?.description,
													// 	})
													// }
												/>
											);
										})}
                  {/* <input
                    type="text"
                    className="form-control text-center "
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    value="5"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  /> */}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <div>
                <label className="cf_label" htmlFor="">
                  Value for Level of impact{" "}
                  <strong className="text-uppercase">Schedule</strong>{" "}
                  {/* <span style={{ color: "red" }}>*</span> */}
                </label>
                <div
                  class="btn-group border-0 overflow-hidden mt-2 w-100 justify-content-between"
                  role="group"
                  aria-label="Basic outlined example"
                  style={{ padding: "0px" }}
                >
                  {!!newData?.timeImpactDetail?.length &&
										newData?.timeImpactDetail?.map((item, index) => {
											return (
												<input
													type="text"
													key={item?.impact}
													className="form-control text-center "
                          disabled={true}
													style={{
														background:
                            assessInfo?.data[0]?.timeScore ===
                            (item?.impact) &&
															"#d9d9d9",
														cursor: "pointer",
													}}
													value={item?.impact}
													// onClick={() =>
													// 	setTimeVal({
													// 		value: item?.impact,
													// 		position: item?.position,
													// 		description: item?.description,
													// 	})
													// }
												/>
											);
										})}
                  {/* <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  />
                  <input
                    type="text"
                    style={{
                      background: "#f5f8fb",
                      cursor: "pointer",
                    }}
                    className="form-control text-center"
                    value="2"
                  /> */}
                </div>
              </div>
            </div>
            {newData?.isCustomeImpact && (
              <div className="mb-4">
                <div>
                  <label className="cf_label" htmlFor="">
                    Value for Level of impact{" "}
                    <strong className="text-uppercase">
                      {newData?.customImpactTextDetail}
                    </strong>{" "}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </label>
                  <div
                    class="btn-group  border-0 overflow-hidden mt-1 w-100 justify-content-between"
                    role="group"
                    aria-label="Basic outlined example"
                    style={{ padding: "0px" }}
                  >
                    {!!newData?.customImpactDetail?.length &&
											newData?.customImpactDetail?.map((item, index) => {
												return (
													<input
														type="text"
														key={item}
                            disabled={true}
														// style={{
														// 	background:
														// 		customVal?.position ===
														// 		(customVal?.position === 0
														// 			? index
														// 			: index + 1) && "#d9d9d9",
														// 	cursor: "pointer",
														// }}
														className="form-control text-center"
														value={item?.impact}
														// onClick={() =>
														// 	setCustomVal({
														// 		value: item?.impact,
														// 		position: item?.position,
														// 		description: item?.description,
														// 	})
														// }
													/>
												);
											})}
                    {/* <input
                      type="text"
                      style={{
                        background: "#f5f8fb",
                        cursor: "pointer",
                      }}
                      className="form-control text-center"
                      value="2"
                    />
                    <input
                      type="text"
                      style={{
                        background: "#f5f8fb",
                        cursor: "pointer",
                      }}
                      className="form-control text-center"
                      value="2"
                    />
                    <input
                      type="text"
                      style={{
                        background: "#f5f8fb",
                        cursor: "pointer",
                      }}
                      className="form-control text-center"
                      value="2"
                    />
                    <input
                      type="text"
                      style={{
                        background: "#f5f8fb",
                        cursor: "pointer",
                      }}
                      className="form-control text-center"
                      value="2"
                    />
                    <input
                      type="text"
                      style={{
                        background: "#f5f8fb",
                        cursor: "pointer",
                      }}
                      className="form-control text-center"
                      value="2"
                    /> */}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex gap-2 justify-content-end">
            <button
              className="btn btn-outline-primary rounded-pill fs-14-normal px-3 h-48 col-lg-4 col-6"
              onClick={() => navigate(`/view-project/${projectId}`)}
            >
              Cancel
            </button>
            <button
              className="btn btn-trinary rounded-pill fs-14-normal px-3 h-48 col-lg-4 col-6"
              onClick={handleRisk}
            >
              {isLoading ? <Loader /> : "Save"}
            </button>
          </div>
        </div>
        {/* <div className="col-xl-3 col-lg-3">
          {assessInfo?.data?.length > 0 &&
            assessInfo?.data?.map((item) => {
              return (
                <>
                  <div>

                    <div className="d-flex">
                      <div
                        className="d-flex align-items-center"
                      >
                      </div>
                      <div className="w-100">
                        <h6
                          className="fs-14-normal text-end mb-0 lh-1"
                          style={{ textAlign: "left" }}
                        >
                          {item?.assessmentType} Risk:{" "}
                          {item?.riskMatrixValue?.yAxis *
                            item?.riskMatrixValue?.xAxis}
                        </h6>
                        <div>
                          <div className="position-relative w-100 h-100 p-30 flex1">
                            <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center pt-5">
                                <div className="d-flex me-2 align-items-center justify-content-between">
                                  {projectData?.data[0]?.riskMatrixId?.timeImpactDetail
                                    ?.slice()
                                    .reverse()
                                    .map((item, index) => (
                                      <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-disabled">{item?.description}</Tooltip>}>
                                      <p
                                        key={index}
                                        className="m-0 hoverShow"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "8px",
                                        }}
                                      >
                                        &nbsp;  

                                      </p>
                                      </OverlayTrigger>
                                    ))}
                                </div>
                                <p
                                  className="m-0 top-arrow" style={{ fontSize: '12px' }} role="button"
                                >
                                  Impact
                                </p>

                            </div>
                            <ShowRiskMatrix
                              data={newData?.matrix}
                              column={item?.riskMatrixValue?.xAxis}
                              row={item?.riskMatrixValue?.yAxis}
                              width="230px"
                            />
                            <div className="position-absolute start-0 end-0 m-0 ps-5 flex1">
                              <p
                                className="text-center m-0 right-arrow" style={{ fontSize: '12px' }} role="button"
                              >
                                Likelihood
                              </p>
                              <div className="d-flex align-items-end justify-content-between">
                                {projectData?.data[0]?.riskMatrixId?.likelihoodDetail?.map(
                                  (item) => {
                                    return (
                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">{item?.description}</Tooltip>}>
                                      <p className="m-0 hoverShow"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "8px",

                                        }}
                                      >
                                        &nbsp;
                                      </p>
                                      </OverlayTrigger>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="p-30 py-0">
                            <p
                              className="text-center d-flex justify-content-center align-items-center"
                              style={{
                                marginTop: "1em",
                                borderRadius: "6px",
                                padding: "0.5em 1em",
                                fontSize: "12px",
                                backgroundColor: '#f5f8fb',
                                height: '46px',
                              }}
                            >
                              Risk Level:{" "}
                              {item?.riskMatrixValue?.riskValue}

                            </p>
                          </div>
                        </div>
                        <div>

                        

                        </div>

                      </div>
                    </div>

                  </div>

                  <div style={{ height: "1em" }}></div>
                </>
              );
            })}
        </div> */}
      </div>
      <MitigationList
        dataMiti={riskDetail?.data?.riskAction}
        rollData={handleRollData}
      />
      <CreateRiskMatrix show={riskMatrix} close={closeRiskMatrix} />
      <CreateProjectManagerModal show={project} close={showProject} />
      <MitigationModal
        show={mitiShow}
        close={() => setMitiShow(false)}
        id={id}
        heading ={riskDetail?.data?.riskName}
        riskData={riskDetail?.data}
      />
      <CreateRiskOwner
        show={userShow}
        close={() => setUserShow(false)}
        data={{}}
      />
      {conShow && <Update title="risk" />}

      {/* <EvmModal show={evm} close={closeEvm} /> */}
    </>
  );
};

export default EditRisk;
