import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditContractModal from "../components/modals/EditContractModal";

const RisksEvmCalculation = () => {
  const [edit, setEdit] = useState(false);
  const closeEdit = () => setEdit(false);
  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="seachBarWIthButton d-flex justify-content-between align-items-center">
            <Link
              className="btn btn-outline-primary rounded-pill fs-14-normal px-5 h-48 align-items-center d-flex"
              to="/add-contract"
            >
              Back
            </Link>
          </div>
          <div className="mt-3">
            <h5 className="table-heading">Unmitigated</h5>
            <div className="table-responsive br-10 mt-3">
              <table class="table table-style-1 mb-0">
                <thead>
                  <tr>
                    <th>
                      Impact Score
                    </th>
                    <th>Min.</th>
                    <th>Max.</th>
                    <th>
                      EMV Input Value
                    </th>
                    <th>Min.</th>
                    <th>Max.</th>
                    <th>
                      EMV Input Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>1%</td>
                    <td>1%</td>
                    <td>1%</td>
                    <td>133.000 mio. kr.</td>
                    <td>133.000 mio. kr.</td>
                    <td>133.000 mio. kr.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-5">
            <h5 className="table-heading">Current</h5>
            <div className="table-responsive br-10 mt-3">
              <table class="table table-style-1 mb-0">
                <thead>
                  <tr>
                    <th>
                      Impact Score
                    </th>
                    <th>Min.</th>
                    <th>Max.</th>
                    <th>
                      EMV Input Value
                    </th>
                    <th>Min.</th>
                    <th>Max.</th>
                    <th>
                      EMV Input Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>1%</td>
                    <td>1%</td>
                    <td>1%</td>
                    <td>133.000 mio. kr.</td>
                    <td>133.000 mio. kr.</td>
                    <td>133.000 mio. kr.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-5">
            <h5 className="table-heading">Mitigated</h5>
            <div className="table-responsive br-10 mt-3">
              <table class="table table-style-1 mb-0">
                <thead>
                  <tr>
                    <th>
                      Impact Score
                    </th>
                    <th>Min.</th>
                    <th>Max.</th>
                    <th>
                      EMV Input Value
                    </th>
                    <th>Min.</th>
                    <th>Max.</th>
                    <th>
                      EMV Input Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>1%</td>
                    <td>1%</td>
                    <td>1%</td>
                    <td>133.000 mio. kr.</td>
                    <td>133.000 mio. kr.</td>
                    <td>133.000 mio. kr.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <EditContractModal show={edit} close={closeEdit} />
    </>
  );
};

export default RisksEvmCalculation;
