import React, { useState, useEffect } from "react";
import { useSixMonthReportMutation } from "../services/apis";
import { usePDF } from "react-to-pdf";



import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useParams } from "react-router-dom";
import NoData from "./NoData";
import LineChartExpoTwo from "./LineChartExpoTwo";
import HeaderForPDF from "./HeaderForPDF";
import FooterForPDF from "./FooterForPDF";


const SixMonthChart = ({ list }) => {
  const { toPDF, targetRef } = usePDF({
    filename: `sixMonthReport.pdf`,
  });
  const { id } = useParams();
  const [sixMonthChart, { data: info }] = useSixMonthReportMutation();
  const [data, setData] = useState([]);
  const [expoStatus, setExpoStatus] = useState(1);
  const [status, setStatus] = useState("UnMitigated");
  const [refreshPage, setRefreshPage] = useState(false)

  
  useEffect(() => {
    sixMonthChart({
      id: id,
      assessmentType: status,
    });
  }, [status]);

  useEffect(() => {
    console.log(info?.data?.length);
    const newArray =
      info?.data?.length > 0 &&
      info?.data.map((item) => {
        const dynamicData = {};
        item.riskData.forEach((riskItem) => {
          // dynamicData[riskItem.riskMatrixValue.riskValue] = riskItem.count;
          // dynamicData[riskItem.riskMatrixValue.riskValue] = (dynamicData[riskItem.riskMatrixValue.riskValue] || 0) + riskItem.count;

          // Accumulate total counts
          dynamicData[riskItem.riskMatrixValue.riskValue] =
            (dynamicData[riskItem.riskMatrixValue.riskValue] || 0) +
            riskItem.count;
        });

        return {
          name: item.MonthName,
          ...dynamicData,
        };
      });

    setData(newArray);
  }, [info]);

  

  const handleExportToPDF = () => {
    setRefreshPage(true); // Set refreshPage to true
  };
  useEffect(() => {
    // Call toPDF when refreshPage is true
    if (refreshPage) {
      toPDF();
      setRefreshPage(false); // Reset refreshPage to false after calling toPDF
    }
  }, [refreshPage, toPDF]);
  console.log('gfhfdh',data);
  return (
    <>
      <div className="mb-4" style={{ display: "flex", gap: "1em" }}>
        <select
          name=""
          id=""
          onChange={(e) => setStatus(e.target.value)}
          style={{ padding: ".3em 1em", border: "none", focus: "none" }}
        >
          <option value="UnMitigated">Unmitigated</option>
          <option value="Current">Current</option>

          <option value="Mitigated">Mitigated</option>
        </select>

        <div style={{ display: "flex", gap: "1em" }}>
          <button
            className={`${
              expoStatus === 1 ? "btn btn-trinary" : "btn btn-secondary"
            }`}
            onClick={() => setExpoStatus(1)}
          >
            Chart
          </button>
          <button
            className={`${
              expoStatus === 2 ? "btn btn-trinary" : "btn btn-secondary"
            }`}
            onClick={() => setExpoStatus(2)}
          >
            Exposure
          </button>
          <button
            className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex"
            onClick={() => handleExportToPDF()}
          >
            PDF Download
          </button>
        </div>
      </div>
      {expoStatus === 1 && (
        <>
          {" "}
          {data === false ? (
            <NoData />
          ) : (
            <>
            
              <div className="px-5 mt-3" ref={targetRef} style={{marginTop:"10%"}}>
               {refreshPage ?  <HeaderForPDF /> :""}  
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {list?.length > 0 &&
                      list?.map((item) => {
                        return (
                          <Bar
                            dataKey={item?.description}
                            barSize={50}
                            stackId="q"
                            fill={
                              item?.color === "light-green"
                                ? "lightGreen"
                                : item?.color === "amber"
                                ? "orange"
                                : item?.color === "yellow"
                                ? "gold"
                                : item?.color
                            }
                          />
                        );
                      })}
                  </BarChart>
                </ResponsiveContainer>
                {refreshPage ?  <FooterForPDF margin="96%"/> :"" }

              </div>
            </>
          )}
        </>
      )}
      {expoStatus === 2 && <LineChartExpoTwo status={status} />}
    </>
  );
};

export default SixMonthChart;
