import { initializeApp } from "firebase/app";
import { getMessaging, getToken as getFcmToken } from "firebase/messaging";
const {
	REACT_APP_VAPID_KEY,
	REACT_APP_FIREBASE_KEY,
	REACT_APP_FIREAPP_KEY,
	REACT_APP_MSG_ID,
	REACT_APP_MEASURE_ID,
} = process.env;

const firebaseConfig = {
	apiKey: "AIzaSyCf0Mxx7-Dl10ucGReBiL-92mUYpuMfi6U",
	authDomain: "eprisk-9c3b8.firebaseapp.com",
	projectId: "eprisk-9c3b8",
	storageBucket: "eprisk-9c3b8.appspot.com",
	messagingSenderId: "405281876748",
	appId: "1:405281876748:web:27969a020c9d697f743729",
	measurementId: "G-X7HJ3X5D0Z",
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const publicKey = REACT_APP_VAPID_KEY;
export const getToken = async (setTokenFound) => {
	let currentToken = "";
	try {
		const permission = await Notification.requestPermission();
		if (permission === "granted") {
			const token = await getFcmToken(messaging, {
				vapidKey:
					"BHUeBwa14mPj7tD_PA5Z20nYQ92sayY3xhri_fJSZ0DbmO8HyzpwM9YNPyGdJDBlLWbU-5HxOgDM4CYdmud4Xn0",
			});
			if (token) {
				setTokenFound(true);
				currentToken = token;
			} else {
				setTokenFound(false);
			}
		} else {
			setTokenFound(false);
		}
	} catch (error) {
	}
	return currentToken;
};
export const onMessageListener = () =>
	new Promise((resolve) => {
		messaging.onMessage((payload) => {
			resolve(payload);
		});
	});
