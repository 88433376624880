import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseQuery = fetchBaseQuery({
  baseUrl: "https://epriskbackend.quytech.co/",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    headers.set("Authorization", `Bearer ${token}`);
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result && result?.error?.status === 404) {
    window.location.replace(window.location.origin);
    localStorage.clear();
  }
  return result;
};
export const epRiskApis = createApi({
  reducerPath: "epRiskApis",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "user/login",
        method: "POST",
        body: data,
      }),
    }),

    addContract: builder.mutation({
      query: (data) => ({
        url: "contract/addContract",
        method: "POST",
        body: data,
      }),
    }),

    getContractUsers: builder.mutation({
      query: (data) => ({
        url: "user/getUserListByRole",
        method: "POST",
        body: data,
      }),
    }),

    addMatrix: builder.mutation({
      query: (data) => ({
        url: "project/addMatrix",
        method: "POST",
        body: data,
      }),
    }),

    addAssessment: builder.mutation({
      query: (data) => ({
        url: "risk/addRiskAssessment",
        method: "POST",
        body: data,
      }),
    }),

    addContractUser: builder.mutation({
      query: (data) => ({
        url: "user/addUser",
        method: "POST",
        body: data,
      }),
    }),

    addProject: builder.mutation({
      query: (data) => ({
        url: "project/addProject",
        method: "POST",
        body: data,
      }),
    }),

    resetPassword: builder.mutation({
      query: (data) => ({
        url: "user/userRestPassword",
        method: "POST",
        body: data,
      }),
    }),
    setPassword: builder.mutation({
      query: (data) => ({
        url: "admin/setPassword",
        method: "POST",
        body: data,
      }),
    }),

    addUserProject: builder.mutation({
      query: (data) => ({
        url: "user/addUserToProject",
        method: "POST",
        body: data,
      }),
    }),

    addRisk: builder.mutation({
      query: (data) => ({
        url: "risk/addRisk",
        method: "POST",
        body: data,
      }),
    }),

    addMitigation: builder.mutation({
      query: (data) => ({
        url: "risk/addRiskAction",
        method: "POST",
        body: data,
      }),
    }),

    sendInvite: builder.mutation({
      query: (data) => ({
        url: "user/userInvite",
        method: "POST",
        body: data,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "user/forgotPassword",
        method: "POST",
        body: data,
      }),
    }),

    otpVerify: builder.mutation({
      query: (data) => ({
        url: "user/userVerifyOtp",
        method: "POST",
        body: data,
      }),
    }),

    platformList: builder.mutation({
      query: (data) => ({
        url: "adminPlatform/getPlatformByUserId",
        method: "POST",
        body: data,
      }),
    }),

    addReply: builder.mutation({
      query: (data) => ({
        url: "adminPlatform/addReply",
        method: "POST",
        body: data,
      }),
    }),

    addPlatform: builder.mutation({
      query: (data) => ({
        url: "adminPlatform/addPlatform",
        method: "POST",
        body: data,
      }),
    }),

    updateEmail: builder.mutation({
      query: (data) => ({
        url: "user/userChangeEmail",
        method: "PUT",
        body: data,
      }),
    }),

    changeNotification: builder.mutation({
      query: (data) => ({
        url: "user/userChangeNotification",
        method: "PUT",
        body: data,
      }),
    }),

    changePassword: builder.mutation({
      query: (data) => ({
        url: "user/userChangePassword",
        method: "PUT",
        body: data,
      }),
    }),

    getRiskById: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/getRiskByProjectId/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getRiskByIdExport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/getRiskByProjectId/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    updateMitigation: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/updateRiskAction/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    updateContract: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `contract/updateContract/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    getProjectList: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `project/getProjectList/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getProjectListExport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `project/getProjectList/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getContractList: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `contract/getContractList`,
          method: "POST",
          body,
        };
      },
    }),

    updateRisk: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/updateRisk/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    updateProject: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `project/updateProject/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    updateFCM: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `user/updateFcmToken/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    updateAssessment: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/updateRiskAsmt/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    deleteNotification: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin-notification/deleteUserNotification/${id}`,
          method: "DELETE",
          body,
        };
      },
    }),

    getProjectUsers: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `project/getUsers/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    updateContractStatus: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `contract/updateContractStatus/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getAllRiskActions: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/getAllRiskAction/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getAllRiskActionsExport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/getAllRiskAction/${id}`,
          method: "POST",
          body,
        };
      },
    }),
    getNotification: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin-notification/getUserNotificationList`,
          method: "POST",
          body,
        };
      },
    }),

    getAllInvites: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `user/getAllInviteList/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    inviteAccept: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `user/acceptRejectInvite/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    riskOwnerChart: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `reports/riskOwnerStatusReport/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    sixMonthReport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `reports/riskSixMonthPositionReport/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    riskAssessmentStatusReport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `reports/riskAssessmentStatusReport/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    riskTypeStatusReport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `reports/riskTypeStatusReport/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    updateRiskStatus: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/updateRiskStatus/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    riskTopNReport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `reports/riskTopNReport/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    actionLog: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/getAllRiskActionLog/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    actionLogExport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/getAllRiskActionLog/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    updateProjectStatus: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `project/updateProjectStatus/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getAllRiskLog: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/getAllRiskLog/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getAllRiskLogExport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `risk/getAllRiskLog/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getContract: builder.query({
      query: (data) => ({
        url: `contract/getContractList?page=${data?.page}&limit=${data?.limit}`,
        method: "GET",
      }),
    }),

    getContractById: builder.query({
      query: (data) => ({
        url: `/contract/getContractById/${data}`,
        method: "GET",
      }),
    }),

    getProjectUser: builder.query({
      query: (data) => ({
        url: `user/getAllUser`,
        method: "GET",
      }),
    }),

    getCms: builder.query({
      query: (data) => ({
        url: `admin-cms/getDeatilsCmsManagement/${data}`,
        method: "GET",
      }),
    }),

    getProjectDetail: builder.query({
      query: (data) => ({
        url: `project/getProject/${data}`,
        method: "GET",
      }),
    }),

    getAllRiskList: builder.query({
      query: (data) => ({
        url: `risk/getAllRiskMaster`,
        method: "GET",
      }),
    }),

    riskIdGenerator: builder.query({
      query: (data) => ({
        url: `risk/getRiskByRiskNumber/${data}`,
        method: "GET",
      }),
    }),

    getRiskByDetail: builder.query({
      query: (data) => ({
        url: `risk/getRiskById/${data}`,
        method: "GET",
      }),
    }),

    mitigationById: builder.query({
      query: (data) => ({
        url: `risk/getRiskActionById/${data}`,
        method: "GET",
      }),
    }),

    queryById: builder.query({
      query: (data) => ({
        url: `adminPlatform/getPlatformById/${data}`,
        method: "GET",
      }),
    }),

    getReportBar: builder.query({
      query: (data) => ({
        url: `reports/riskStatusReport/${data}`,
        method: "GET",
      }),
    }),

    getActionReport: builder.query({
      query: (data) => ({
        url: `reports/actionStatusReport/${data}`,
        method: "GET",
      }),
    }),

    getRiskOwnerReport: builder.query({
      query: (data) => ({
        url: `reports/riskOwnerStatusReport/${data}`,
        method: "GET",
      }),
    }),

    actionProximityReport: builder.query({
      query: (data) => ({
        url: `reports/actionProximityReport/${data}`,
        method: "GET",
      }),
    }),

    riskProximityReport: builder.query({
      query: (data) => ({
        url: `reports/riskProximityReport/${data}`,
        method: "GET",
      }),
    }),

    riskMatrixStatusReport: builder.query({
      query: (data) => ({
        url: `reports/riskMatrixStatusReport/${data}`,
        method: "GET",
      }),
    }),

    getAssessment: builder.query({
      query: (data) => ({
        url: `risk/getRiskAsmtByRiskId/${data}`,
        method: "GET",
      }),
    }),
    typeReport: builder.query({
      query: (data) => ({
        url: `reports/riskTypeStatusReport/${data}`,
        method: "GET",
      }),
    }),

    projectNumber: builder.query({
      query: (data) => ({
        url: `project/getProjectByNo/${data}`,
        method: "GET",
      }),
    }),

    sportRegisterReport: builder.query({
      query: (data) => ({
        url: `reports/sportRegisterReport/${data}`,
        method: "GET",
      }),
    }),

    riskProfileReport: builder.query({
      query: (data) => ({
        url: `reports/riskProfileReport/${data}`,
        method: "GET",
      }),
    }),

    getRiskLogById: builder.query({
      query: (data) => ({
        url: `risk/getRiskLogById/${data}`,
        method: "GET",
      }),
    }),

    getRiskActionLogById: builder.query({
      query: (data) => ({
        url: `risk/getRiskActionLogById/${data}`,
        method: "GET",
      }),
    }),
    // getLastUpdateData: builder.query({
    //   query: (data) => ({
    //     url: `project/getLastUpdateData`,
    //     method: "GET",
    //   }),
    // }),
    getLastUpdateData: builder.mutation({
      query(data) {
        return {
          url: `project/getLastUpdateData`,
          method: "POST",
          body:data,
        };
      },
    }),
    getUserInfo: builder.query({
      query: (data) => ({
        url: `user/getUserById/${data}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetUserInfoQuery,
  useGetRiskActionLogByIdQuery,
  useGetRiskLogByIdQuery,
  useGetAllRiskLogMutation,
  useGetAllRiskLogExportMutation,
  useUpdateEmailMutation,
  useRiskProfileReportQuery,
  useSportRegisterReportQuery,
  useUpdateRiskStatusMutation,
  useProjectNumberQuery,
  useRiskTopNReportMutation,
  useTypeReportQuery,
  useGetAssessmentQuery,
  useRiskOwnerChartMutation,
  useRiskProximityReportQuery,
  useActionProximityReportQuery,
  useRiskAssessmentStatusReportMutation,
  useGetUsersMutation,
  useChangeNotificationMutation,
  useGetContractListMutation,
  useGetContractUsersMutation,
  useAddContractMutation,
  useGetContractQuery,
  useGetProjectUsersMutation,
  useAddMatrixMutation,
  useAddProjectMutation,
  useAddContractUserMutation,
  useSixMonthReportMutation,
  useGetProjectListMutation,
  useGetProjectDetailQuery,
  useGetContractByIdQuery,
  useGetProjectUserQuery,
  useGetRiskByDetailQuery,
  useAddUserProjectMutation,
  useGetAllRiskListQuery,
  useRiskIdGeneratorQuery,
  useAddRiskMutation,
  useGetRiskByIdMutation,
  useGetRiskByIdExportMutation,
  useQueryByIdQuery,
  useAddReplyMutation,
  useUpdateRiskMutation,
  useAddMitigationMutation,
  useUpdateMitigationMutation,
  useMitigationByIdQuery,
  useUpdateContractMutation,
  useGetAllRiskActionsMutation,
  useGetAllRiskActionsExportMutation,

  useUpdateProjectMutation,
  useSendInviteMutation,
  useGetAllInvitesMutation,
  useInviteAcceptMutation,
  useForgotPasswordMutation,
  useOtpVerifyMutation,
  useResetPasswordMutation,
  useSetPasswordMutation,
  useAddPlatformMutation,
  usePlatformListMutation,
  useGetReportBarQuery,
  useUpdateFCMMutation,
  useGetNotificationMutation,
  useDeleteNotificationMutation,
  useGetActionReportQuery,
  useGetCmsQuery,
  useGetRiskOwnerReportQuery,
  useAddAssessmentMutation,
  useUpdateAssessmentMutation,
  useRiskMatrixStatusReportQuery,
  useRiskTypeStatusReportMutation,
  useActionLogMutation,
  useActionLogExportMutation,
  useUpdateProjectStatusMutation,
  useUpdateContractStatusMutation,
  useGetProjectListExportMutation,
  // useGetLastUpdateDataQuery,
  useGetLastUpdateDataMutation,
  useChangePasswordMutation,
} = epRiskApis;
