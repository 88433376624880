import React from "react";
import { PAGE_LIMIT } from "../utils/constants";

const Pagination = ({ data, pageNo, pageVal }) => {
  const arrayToMap = new Array(data?.totalPages).fill(null);

  return (
    <div className="mt-1 d-flex justify-content-between gap-2 align-items-center">
      <span className="showResult">Showing results {data?.totalRecords}</span>
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center mb-0">
          <li class="page-item">
            <a
              class="page-link"
              aria-disabled="true"
              onClick={() => pageVal > 1 && pageNo(pageVal - 1)}
            >
              &#8249; Previous
            </a>
          </li>
          {arrayToMap?.map((item, index) => {
            return (
              <li
                className={`page-item ${pageVal === index + 1 ? "active" : ""}`}
                onClick={() => pageNo(index + 1)}
                style={{ cursor: "pointer" }}
              >
                <a className="page-link">{index + 1}</a>
              </li>
            );
          })}

          <li class="page-item">
            <a
              class="page-link"
              onClick={() => data?.totalPages > pageVal && pageNo(pageVal + 1)}
            >
              Next &#8250;
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
