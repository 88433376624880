import React, { useState, useEffect } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const ShowRiskMatrix = ({ data,matrixDesc, row, column, width = "230px" }) => {
  const [matrixData, setMatrixData] = useState();
  const [commonValue, setCommonValue] = useState(0);
  const [prevRow, setPrevRow] = useState(null);
  const [prevColumn, setPrevColumn] = useState(null);
  useEffect(() => {
    setMatrixData(data);
  }, [data]);
  const getColorDescription = (color) => {
    const foundColor = matrixDesc?.find(desc => desc?.color === color);
    return foundColor ? foundColor.description : color;
  }
  const matrix = [
    21, 22, 23, 24, 25, 16, 17, 18, 19, 20, 11, 12, 13, 14, 15, 6, 7, 8, 9, 10,
    1, 2, 3, 4, 5,
  ];
  useEffect(() => {
    const value = matrix2[(row || 1) - 1][(column || 1) - 1];
    setCommonValue(value);
    if (prevRow !== null && prevColumn !== null) {
      const prevBox = document.getElementById(`box-${prevRow}-${prevColumn}`);
      if (prevBox) {
        prevBox.style.border = "0.001em solid white";
      }
    }
    setPrevRow(row);
    setPrevColumn(column);
  }, [row, column, prevRow, prevColumn]);
  function createMatrixFromArray(arr) {
    const matrix = [];
    for (let i = 0; i < 5; i++) {
      const row = [];
      for (let j = 0; j < 5; j++) {
        const index = i * 5 + j;
        row.push(arr[index]);
      }
      matrix.push(row);
    }
    return matrix;
  }
  const matrix2 = createMatrixFromArray(matrix);

  return (
    <div className="overflow-auto" style={{width:"130px"}}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
          width: "100%",
          height: "100%",
          margin: "auto",
          aspectRatio:2 / 2
        }}
      >
        {matrixData?.length > 0 &&
          matrixData?.map((box, rowIndex) => (
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{getColorDescription(box.color).toUpperCase().replace('-',' ')}</Tooltip>}>
            <div
              key={box.cellId}
              className={` bg-${box.color} box `}
              style={{
                border: "1px solid white",
                width: "100%",
                height: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              {rowIndex + 1 === commonValue && (
                <div id="burst-12"></div>
              )}
            </div>
          </OverlayTrigger>
          ))}
      </div>
    </div>
  );
};
export default ShowRiskMatrix;
