import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

const RiskDrop = ({
  list,
  listTitle,
  state,
  numId,
  stateName = () => {},
  updateValue,
  add = false,
}) => {
  const [listData, setListData] = useState({
    title: "",
    value: "",
  });

  useEffect(() => {
    state(listData?.value, numId);
    stateName(listData?.title);
  }, [listData]);

  useEffect(() => {
    setListData({
      title: updateValue?.name,
      value: updateValue?._id,
    });
  }, [updateValue,list ]);

  useEffect(() => {
    if (listTitle === "Risk Status" && updateValue?.name === undefined) {
      if(list && list.length > 0){
        setListData({
          title: list[0]?.name,
          value: list[0]?._id,
        });
      }
    }
  }, [listTitle, list]);



  return (
    <Dropdown className="w-100">
      <Dropdown.Toggle
        className="input-1 w-100 dropWithArrow2 px-3"
        id="dropdown-basic"
      >
        {listData?.title || listTitle}
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 br-14 py-2 px-3">
        {!!list?.length &&
          list?.map((item) => {
            return (
              <Dropdown.Item key={item?._id}>
                <label
                  htmlFor={item?._id}
                  className="customRadio d-flex align-items-center gap-2 mb-2"
                  onClick={() =>
                    setListData({
                      title: item?.name,
                      value: item?._id,
                    })
                  }
                  style={{
                    color: item?._id === listData?.value ? "orange" : "black",
                    textAlign: "left",
                  }}
                >
                  <input
                    type="radio"
                    name="demoradio"
                    id={item?._id}
                    checked={item?._id === listData?.value}
                    onChange={() =>
                      setListData({
                        title: item?.name,
                        value: item?._id,
                      })
                    }
                  />
                  <span className={`${item?.value} mr-2 wh-12`}></span>
                  <h6 className="mb-0">{item?.name}</h6>
                </label>
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RiskDrop;
