import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";

const AssessmentDropDown = ({
  title,
  data,
  projectDetail,
  itemValue,
  keyValue,
  dataInfo,
  mitigatedIndex,
}) => {
  const [value, setValue] = useState({});
  const [indx, setIndx] = useState("");

  useEffect(() => {
    itemValue(value, keyValue, indx);
  }, [value, keyValue, indx]);

  useEffect(() => {
    const keyIndx = title.includes("Mitigated") ? mitigatedIndex : 0;
    const propertyMap = {
      1: "probabilityName",
      2: "costName",
      3: "timeName",
      4: "customName",
    };
    const property = propertyMap[keyValue];
    if (property) {
      setValue({
        description: !!dataInfo?.length && dataInfo[keyIndx]?.[property],
      });
    }
  }, [dataInfo, title, keyValue]);

  return (
    <div className="form-group col-12 mb-3">
      <Dropdown className="w-100">
        <Dropdown.Toggle
          className="justify-content-start input-1 w-100 dropWithArrow2 px-3"
          id="dropdown-basic"
        >
          {(value?.description?.length > 20 ? value?.description.slice(0, 20) + '...' : value?.description) || (title?.length>20 ? title.slice(0,20) + '...' : title)}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100 br-14 py-2 px-3">
          <div className="scroll-120 thin-scroll mt-2">
            {!!data?.length &&
              data?.map((item, index) => {
                return (
                  <label
                    htmlFor="radio1"
                    className="customRadio d-flex align-items-center gap-2"
                    onClick={() => {
                      setValue(item);
                      setIndx(index + 1);
                    }}
                  >
                    <input
                      type="radio"
                      name="demoradio"
                      id={
                        title.includes("Probabality")
                          ? item?.likelihood 
                          : item?.impact
                      }
                      value={
                        title.includes("Probabality")
                          ? item?.likelihood
                          : item?.impact
                      }
                      checked={item?.position === value?.position}
                    />
                    <h6
                      className="mb-0"
                      style={{
                        color: item?.position === value?.position && "#e75d0f",
                      }}
                    >
                      {item?.description} - {item?.impact} {item?.likelihood} {projectDetail}
                    </h6>
                  </label>
                );
              })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AssessmentDropDown;
