import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import AssesmentSection from "../components/AssesmentSection";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ShowRiskMatrix from "../components/ShowRiskMatrix";
import {
  useAddAssessmentMutation,
  useGetAssessmentQuery,
  useGetProjectDetailQuery,
  useGetRiskByDetailQuery,
  useUpdateAssessmentMutation,
} from "../services/apis";
import { PopUp } from "../utils/alert";
import Loader from "../utils/loader";
import { handleErrors } from "../utils/error";

const MitigatedAssessment = ({ scoreData }) => {
  const { id, riskId } = useParams();
  const navigate = useNavigate();
  const [addAssessment, { data: assessData, isLoading, error }] =
    useAddAssessmentMutation();

  const [
    updateAssessment,
    { data: updatedata, isLoading: updateLoading, error: updateErr },
  ] = useUpdateAssessmentMutation();

  const [unmitData, setUnmitData] = useState({
    impact: "",
    liklihood: "",
  });

  const [unmitValues, setUnmitValues] = useState({
    impact: "",
    cost: "",
    time: "",
    quality: "",
  });

  const [color, setColor] = useState("");

  const { data, isFetching, refetch } = useGetRiskByDetailQuery(riskId);
  const { data: assessInfo, refetch: assessRefetch } =
    useGetAssessmentQuery(riskId);
  console.log("assessInfo", assessInfo);
  const { data: projectDetail, refetch: projectFetch } =
    useGetProjectDetailQuery(id);

  const mitigatedData =
    assessInfo?.data?.length > 0 &&
    assessInfo?.data?.filter((item) => item?.assessmentType === "Mitigated");

  useEffect(() => {
    refetch();
    projectFetch();
    assessRefetch();
  }, [id]);

  useEffect(() => {
    refetch();
  }, [riskId]);

  const handleUnmitData = (data) => {
    setUnmitData(data);
  };

  const handleUnmitValues = (data) => {
    setUnmitValues(data);
  };

  useEffect(() => {
    const matrix = [
      [21, 22, 23, 24, 25],
      [16, 17, 18, 19, 20],
      [11, 12, 13, 14, 15],
      [6, 7, 8, 9, 10],
      [1, 2, 3, 4, 5],
    ];

    function getValueAt(matrix, rowIndex, columnIndex) {
      if (
        rowIndex >= 0 &&
        rowIndex < matrix?.length &&
        columnIndex >= 0 &&
        columnIndex < matrix[rowIndex]?.length
      ) {
        return matrix[rowIndex][columnIndex];
      } else {
        return "Invalid indices";
      }
    }

    const value = getValueAt(
      matrix,
      unmitData?.liklihood - 1,
      unmitData?.impact
    );

    const updatedColor =
      projectDetail?.data?.result[0]?.riskMatrixId?.matrix?.filter(
        (item) => item?.cellId === value
      );

    const colors =
      projectDetail?.data?.result[0]?.riskMatrixId?.colorDescription?.filter(
        (item) => item.color === updatedColor[0]?.color
      );

    setColor(colors[0]);
  }, [unmitData]);

  const handleAssessment = (e) => {
    e.preventDefault();
    const maxObject = Object.keys(unmitValues).reduce((maxKey, currentKey) => {
      const currentImpact = parseFloat(unmitValues[currentKey].impact);
      const maxImpact = parseFloat(unmitValues[maxKey].impact);

      return currentImpact > maxImpact ? currentKey : maxKey;
    }, Object.keys(unmitValues)[1]);

    if (!unmitValues?.impact?.description) {
      PopUp("Probabilty score is mandatory", "", "error");
      return;
    }

    const obj = {
      riskId,
      assessmentType: "Mitigated",
      probabilityName: unmitValues?.impact?.description,
      probabilityValue: unmitValues?.impact?.likelihood,
      consequenceName: unmitValues[maxObject]?.description,
      consequenceValue: unmitValues[maxObject]?.impact,
      costName: unmitValues?.cost?.description,
      costScore: unmitValues?.cost?.impact,
      costPosition: unmitValues?.cost?.position,
      timeName: unmitValues?.time?.description,
      timeScore: unmitValues?.time?.impact,
      timePosition: unmitValues?.time?.position,

      riskMatrixValue: {
        xAxis: unmitData?.impact,
        yAxis: unmitData?.liklihood,
        riskValue: color?.description,
        color: color?.color,
      },
    };

    const updateObj = {
      id: mitigatedData[0]?._id,
      riskAssessmentVersion: mitigatedData[0]?.riskAssessmentVersion,
      probabilityName: unmitValues?.impact?.description,
      probabilityValue: unmitValues?.impact?.likelihood,
      consequenceName: unmitValues[maxObject]?.description,
      consequenceValue: unmitValues[maxObject]?.impact,
      costName: unmitValues?.cost?.description,
      costScore: unmitValues?.cost?.impact,
      costPosition: unmitValues?.cost?.position,
      timeName: unmitValues?.time?.description,
      timeScore: unmitValues?.time?.impact,
      timePosition: unmitValues?.time?.position,
      riskMatrixValue: {
        xAxis: unmitData?.impact || assessInfo?.data[2]?.riskMatrixValue.xAxis,
        yAxis:
          unmitData?.liklihood || assessInfo?.data[2]?.riskMatrixValue.yAxis,
        riskValue:
          color?.description || assessInfo?.data[2]?.riskMatrixValue?.riskValue,
        color: color?.color,
      },
    };

    if (projectDetail?.data?.result[0]?.riskMatrixId?.isCustomeImpact) {
      obj.customName = unmitValues?.quality?.description;
      obj.customPosition = unmitValues?.quality?.position;
      updateObj.customName = unmitValues?.quality?.description;
      updateObj.customPosition = unmitValues?.quality?.position;
    }

    mitigatedData[0]?.riskMatrixValue.yAxis
      ? updateAssessment(updateObj)
      : addAssessment(obj);
  };

  useEffect(() => {
    if (assessData?.success) {
      PopUp("Value added successfully", "", "success");
      navigate(-1);
    }

    if (updatedata?.success) {
      PopUp("Value added successfully", "", "success");
      navigate(-1);
    }
    handleErrors(updateErr);
  }, [assessData, updatedata, updateErr]);

  console.log("assessInfo", assessInfo);
  console.log("unmitData", unmitData);
  console.log("unmitValues", unmitValues);
  console.log(
    "projectDetail?.data[0]?.riskMatrixId?.costImpactType",
    projectDetail?.data?.result[0]?.riskMatrixId?.customImpactDetail.length
  );

  return (
    <div className="row">
      <div className="col-xl-6">
        <AssesmentSection
          title="Mitigated"
          info={handleUnmitData}
          unmitValue={handleUnmitValues}
          dataValue={assessInfo?.data}
          scoreData={projectDetail?.data?.result[0]?.riskMatrixId?.impactScore}
        />
      </div>
      <div className="col-xl-6">
        <div>
          <div>
            <div className="d-flex justify-content-between mb-1 gap-2">
              <span className="fs-16 text-black">
                Cost Risk Score (mitigated)
              </span>
              <span className="fs-16 text-black">
                {unmitValues?.cost?.impact ? (
                  <p>
                    {unmitValues?.cost?.impact ? unmitValues?.cost?.impact : ""}
                    {unmitValues?.cost?.impact
                      ? projectDetail?.data?.result[0]?.riskMatrixId
                          ?.costImpactType
                        ? projectDetail?.data?.result[0]?.riskMatrixId
                            ?.costImpactType == "dollar"
                          ? "$"
                          : projectDetail?.data?.result[0]?.riskMatrixId
                          ?.costImpactType
                        : ""
                      : ""}{" "}
                  </p>
                ) : assessInfo?.data ? (
                  assessInfo?.data
                    .filter((item) => item.assessmentType === "Mitigated")
                    .map((item) => (
                      <p key={item._id}>
                        {item.costScore}
                        {projectDetail?.data?.result[0]?.riskMatrixId
                          ?.costImpactType
                          ? projectDetail?.data?.result[0]?.riskMatrixId
                              ?.costImpactType == "dollar"
                            ? "$"
                            : projectDetail?.data?.result[0]?.riskMatrixId
                                ?.costImpactType
                          : ""}
                      </p>
                    ))
                ) : (
                  ""
                )}
                {/* {assessInfo?.data[1]?.consequenceValue} */}
              </span>
            </div>

            <div className="d-flex justify-content-between mb-1 gap-2">
              <span className="fs-16 text-black">
                Schedule Risk Score (mitigated)
              </span>
              <span className="fs-16 text-black">
                {unmitValues?.time?.impact ? (
                  <p>
                    {unmitValues?.time?.impact}
                    {unmitValues?.time?.impact
                      ? projectDetail?.data?.result[0]?.riskMatrixId
                          ?.timeImpactType
                      : ""}{" "}
                  </p>
                ) : assessInfo?.data ? (
                  assessInfo?.data
                    .filter((item) => item.assessmentType === "Mitigated")
                    .map((item) => (
                      <p key={item._id}>
                        {item.timeScore}
                        {
                          projectDetail?.data?.result[0]?.riskMatrixId
                            ?.timeImpactType
                        }
                      </p>
                    ))
                ) : (
                  ""
                )}
                {/* {assessInfo?.data[1]?.timeScore} */}
              </span>
            </div>
            {projectDetail?.data?.result[0]?.riskMatrixId?.customImpactDetail
              ?.length > 0 && (
              <div className="d-flex justify-content-between mb-1 gap-2">
                <span className="fs-16 text-black">
                  {"{"}Health{"}"} Risk Score (mitigated)
                </span>
                <span className="fs-16 text-black">
                  {unmitValues?.quality?.impact ? (
                    <p>
                      {unmitValues?.quality?.impact}
                      {unmitValues?.quality?.impact
                        ? projectDetail?.data?.result[0]?.riskMatrixId
                            ?.costImpactType
                          ? projectDetail?.data?.result[0]?.riskMatrixId
                              ?.costImpactType == "dollar"
                            ? "$"
                            : "%"
                          : ""
                        : ""}{" "}
                    </p>
                  ) : assessInfo?.data ? (
                    assessInfo?.data
                      .filter((item) => item.assessmentType === "Mitigated")
                      .map((item) => (
                        <p key={item._id}>{item.customPosition}</p>
                      ))
                  ) : (
                    ""
                  )}
                  {/* {assessInfo?.data[1]?.costPosition} */}
                </span>
              </div>
            )}
          </div>
          <div className="mt-5">
            <div className="w-100">
              <h6
                className="fs-14-normal text-end mb-0 lh-1"
                style={{ textAlign: "left" }}
              ></h6>
              <div>
                <div className="wh-17 ms-auto">
                  <div className="position-relative w-100 h-100 p-30 flex1">
                    <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center pt-5">
                      <div className="d-flex me-2 align-items-center justify-content-between">
                        {projectDetail?.data?.result[0]?.riskMatrixId?.timeImpactDetail
                          ?.slice()
                          .reverse()
                          .map((item, index) => (
                            <OverlayTrigger
                              placement="left"
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  {item?.description}
                                </Tooltip>
                              }
                            >
                              <p
                                key={index}
                                className="m-0 hoverShow"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "8px",
                                }}
                              >
                                &nbsp;
                              </p>
                            </OverlayTrigger>
                          ))}
                      </div>
                      <p
                        className="m-0 top-arrow"
                        style={{ fontSize: "12px" }}
                        role="button"
                      >
                        Impact
                      </p>
                    </div>
                    <ShowRiskMatrix
                      data={
                        projectDetail?.data?.result[0]?.riskMatrixId?.matrix
                      }
                      matrixDesc={
                        projectDetail?.data?.result[0]?.riskMatrixId
                          ?.colorDescription
                      }
                      column={
                        unmitData?.impact ||
                        mitigatedData[0]?.riskMatrixValue.xAxis
                      }
                      row={
                        unmitData?.liklihood ||
                        mitigatedData[0]?.riskMatrixValue.yAxis
                      }
                    />
                    <div className="position-absolute start-0 end-0 m-0 ps-5 flex1">
                      <p
                        className="text-center m-0 right-arrow"
                        style={{ fontSize: "12px" }}
                        role="button"
                      >
                        Likelihood
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        {projectDetail?.data?.result[0]?.riskMatrixId?.likelihoodDetail?.map(
                          (item) => {
                            return (
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {item?.description}
                                  </Tooltip>
                                }
                              >
                                <p
                                  className="m-0 hoverShow"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "8px",
                                  }}
                                >
                                  &nbsp;
                                </p>
                              </OverlayTrigger>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-17 ms-auto py-0">
                  {/* <p
                    className="text-center d-flex justify-content-center align-items-center"
                    style={{
                      marginTop: "1em",
                      borderRadius: "6px",
                      padding: "0.5em 1em",
                      fontSize: "12px",
                      backgroundColor: '#d9d9d9',
                      height: '46px',
                    }}
                  >
                    Risk Level:{" "}
                    {color?.description || mitigatedData[0]?.riskMatrixValue?.riskValue}

                  </p> */}
                  {data?.data?.isRiskUnMitigated && (
                    <Link
                      className="btn btn-trinary rounded-pill fs-14-normal px-5 h-48 align-items-center d-flex"
                      onClick={handleAssessment}
                    >
                      {updateLoading || isLoading ? <Loader /> : "Save"}
                    </Link>
                  )}
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MitigatedAssessment;
