import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import ProjectManager from "../Contracts/ProjectManager";
import RiskManager from "../Contracts/RiskManager";
import ClientRiskManager from "../Contracts/ClientRiskManager";
import ClientProjectManager from "../Contracts/ClientProjectManaget";
import { useSelector } from "react-redux";
import { PopUp } from "../../utils/alert";
import { useNavigate } from "react-router-dom";
import { CONTRACTS } from "../../utils/routes";
import { useLocation } from "react-router-dom";
import {
  useGetContractByIdQuery,
  useUpdateContractMutation,
  useUpdateContractStatusMutation,
} from "../../services/apis";
import {
  setPmName,
  setRmName,
  setClientPmName,
  setClientRmName,
  setPmId,
  setRmId,
  setClientPmId,
  setClientRmId,
} from "../../services/slices/constants";
import { useDispatch } from "react-redux";
import { handleErrors } from "../../utils/error";
import Loader from "../../utils/loader";
import Update from "../Update";

function EditContractModal({
  show,
  close,
  editId,
  editContract = false,
  edit,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const profileInfo = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );

  const { data: getContract, refetch } = useGetContractByIdQuery(editId, {
    skip: !editId,
  });

  const [updateContractStatus, { data: statusData }] =
    useUpdateContractStatusMutation();
  const pmId = useSelector((state) => state?.constants?.pmId);
  const rmId = useSelector((state) => state?.constants?.rmId);
  const clientPmId = useSelector((state) => state?.constants?.clientPmId);
  const clientRmId = useSelector((state) => state?.constants?.clientRmId);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [conShow, setConShow] = useState(false);

  const [updateRisk, { data, error, isLoading }] = useUpdateContractMutation();

  useEffect(() => {
    editId && refetch();
  }, [show]);

  const handleContract = () => {
    const requiredFields = [
      { field: title, message: "Title is required" },
      { field: description, message: "Description is required" },
      { field: pmId, message: "Project manager is required" },
      { field: rmId, message: "Risk manager is required" },
      { field: clientPmId, message: "Client project manager is required" },
      { field: clientRmId, message: "Client risk manager is required" },
    ];
    for (const field of requiredFields) {
      if (!field.field) {
        PopUp(field.message, "", "error");
        return;
      }
    }
    updateRisk({
      id: editId,
      contractName: title,
      contractVersion: getContract?.data?.contractVersion,
      description,
      epPM: pmId,
      epRM: rmId,
      clientPM: clientPmId,
      clientRM: clientRmId,
    });
  };

  useEffect(() => {
    setTitle(getContract?.data?.contractName);
    setDescription(getContract?.data?.description);
    dispatch(setPmName(getContract?.data.epPM?.name));
    dispatch(setRmName(getContract?.data?.epRM?.name));
    dispatch(setClientPmName(getContract?.data?.clientPM?.name));
    dispatch(setClientRmName(getContract?.data?.clientRM?.name));
    dispatch(setPmId(getContract?.data?.epPM?._id));
    dispatch(setRmId(getContract?.data?.epRM?._id));
    dispatch(setClientPmId(getContract?.data?.clientPM?._id));
    dispatch(setClientRmId(getContract?.data?.clientRM?._id));
  }, [getContract, show]);

  useEffect(() => {
    if (data?.success) {
      PopUp("Contract updated successfully", "", "success");
      close();
      editContract ? navigate(-1) : navigate(CONTRACTS);
    }
    handleErrors(error);
  }, [data, error]);

  useEffect(() => {
    if (statusData?.code === 200) {
      refetch();
      return;
    }
  }, [statusData]);

  useEffect(() => {
    if (getContract?.success) {
      if (
        getContract?.data?.isUpdatingBy !== profileInfo?._id &&
        getContract?.data?.isUpdating &&
        editId === getContract?.data?._id
      ) {
        setConShow(true);
        return;
      } else {
        setConShow(false);
      }
      if (getContract?.data && getContract?.data?.isUpdating === false) {
        updateContractStatus({ id: editId, isUpdating: true });
      }
    }
  }, [getContract, show]);

  useEffect(() => {
    if (!edit) {
      if (
        getContract?.data?.isUpdatingBy === profileInfo?._id &&
        getContract?.data?.isUpdating
      ) {
        updateContractStatus({ id: editId, isUpdating: false });
        setConShow(false);
      }
    }
  }, [edit, close]);

  return (
    <>
      <Modal
        show={show}
        size="md"
        dialogClassName="modal-rounded modal-600"
        centered
        onHide={close}
      >
        <Modal.Header
          className="text-center border-0 justify-content-center position-relative abolute-close"
          closeButton
        >
          {/* <Modal.Title className="fs-21-600">Edit Contract</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="row mx-0 pt-3">
          <div className="col-md-6">
            <div className="input-group mb-2">
              <label htmlFor="" className="text-dark fs-16 mb-1">Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="input-2 w-100 px-3"
                placeholder="Contract Title"
              />
            </div>
            <div className="input-group">
              <label htmlFor="" className="text-dark fs-16 mb-1">Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="11"
                className="textarea-2 w-100"
                placeholder="write description here..."
              ></textarea>
            </div>
          </div>
          <div className="col-md-6 mt-md-0 mt-2 d-flex flex-column">
            <div className="mb-2"><ProjectManager
              edit={true}
              epPMData={getContract?.data?.epPM?.name}
            /></div>
            <div className="mb-2">
            <RiskManager />
            </div>
            <div className="mb-2">
            <ClientProjectManager />
            </div>
            <div className="mb-2">
            <ClientRiskManager />
            </div>
            <div className="mt-auto">
            <button
              className="btn btn-trinary rounded-pill h-55 px-5 fs-16 w-100 rounded-3px"
              onClick={handleContract}
            >
              {isLoading ? <Loader /> : "Update"}
            </button>
          </div>
          </div>
          
          {conShow && (
            <div
              style={{
                position: "absolute",
                top: "0%",
                background: "white",
                width: "95%",
                height: "100%",
                zIndex: "100",
              }}
            >
              <div
                style={{
                  background: "white",
                  width: "100%",
                  height: "100%",
                  padding: "7em 2em 2em 2em",
                  display: "grid",
                  placeItems: "center",
                  textAlign: "center",
                }}
              >
                <div>
                  <h5>Someone is updating this contract right now</h5>
                  <p>You can wait for a while</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "2em",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={() => close()}
                      className="btn btn-primary btn-sm mt-4 "
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditContractModal;
