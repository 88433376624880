import { Route, Routes } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";
import AuthOutlet from "./components/AuthOutlet";
import { useSelector } from "react-redux";
import * as ROUTE from "./utils/routes";
import Login from "./pages/Login";
import RequestInvite from "./pages/RequestInvite";
import ForgetPassword from "./pages/ForgetPassword";
import OtpVerification from "./pages/OtpVerification";
import ResetPassword from "./pages/ResetPassword";
import InnerOutlet from "./components/InnerOutlet";
import Contracts from "./pages/Contracts";
import ViewContract from "./pages/ViewContract";
import NewContract from "./pages/NewContract";
import ViewProject from "./pages/ViewProject";
import AddNewRisk from "./pages/AddNewRisk";
import EditRisk from "./pages/EditRisk";
import RisksEvmCalculation from "./pages/RisksEvmCalculation";
import RiskManagers from "./pages/RiskManagers";
import Engineers from "./pages/Engineers";
import SavedReports from "./pages/SavedReports";
import ChangePassword from "./pages/ChangePassword";
import Cms from "./pages/Cms";
import AddCms from "./pages/AddCms";
import EditCms from "./pages/EditCms";
import PlatformSupport from "./pages/PlatformSupport";
import ViewTicket from "./pages/ViewTicket";
import Assessment from "./pages/Assessment";
import EditProject from "./pages/EditProject";
import Invitation from "./pages/Invitations";
import Notifications from "./pages/Notifications";
import RiskReport from "./pages/RiskReport";
import ActionReport from "./pages/ActionReport";
import RiskDetail from "./pages/RiskDetail";
import ChangeEmail from "./pages/ChangeEmail";
import Profile from "./pages/Profile";
import SetPassword from "./pages/SetPassword"

function App() {
  const profileInfo = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );
  const PrivateOutlet = () => {
    const token = profileInfo?.token === localStorage.getItem("token");
    return token ? <Outlet /> : <Navigate to="/" />;
  };

  return (
    <Routes>
      <Route path="/" element={<AuthOutlet />}>
        <Route index element={<Login />} />
        <Route path={ROUTE.INVITE} element={<RequestInvite />} />
        <Route path={ROUTE.FORGOT} element={<ForgetPassword />} />
        <Route path={ROUTE.OTP} element={<OtpVerification />} />
        <Route path={ROUTE.RESET} element={<ResetPassword />} />
        <Route path={ROUTE.SET} element={<SetPassword />} />

      </Route>
      <Route element={<PrivateOutlet />}>
        <Route element={<InnerOutlet />}>
          <Route path={ROUTE.CONTRACTS} element={<Contracts />} />
          <Route path={ROUTE.VIEW_CONTRACT} element={<ViewContract />} />
          <Route path={ROUTE.VIEW_PROJECT} element={<ViewProject />} />


          <Route path={ROUTE.RISK_CALC} element={<RisksEvmCalculation />} />
          <Route path={ROUTE.RISK_MANAGER} element={<RiskManagers />} />
          <Route path={ROUTE.ENGINNEERS} element={<Engineers />} />
          <Route path={ROUTE.SAVED_REPORT} element={<SavedReports />} />
          <Route path={ROUTE.CHANGE_PASS} element={<ChangePassword />} />
          <Route path={ROUTE.CMS} element={<Cms />} />
          <Route path={ROUTE.ADD_CMS} element={<AddCms />} />
          <Route path={ROUTE.EDIT_CMS} element={<EditCms />} />
          <Route path={ROUTE.PLATFORM_SUPPORT} element={<PlatformSupport />} />
          <Route path={ROUTE.VIEW_TICKETS} element={<ViewTicket />} />
          <Route path={ROUTE.ADD_RISK} element={<AddNewRisk />} />
          <Route path={ROUTE.EDIT_RISK} element={<EditRisk />} />
          <Route path={ROUTE.ADD_CONTRACT} element={<NewContract />} />
          <Route path={ROUTE.ASSESSMENT} element={<Assessment />} />
          <Route path={ROUTE.EDIT_PROJECT} element={<EditProject />} />

          <Route path="/invitations" element={<Invitation />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/risk-report/:id" element={<RiskReport />} />
          <Route path="/action-report/:id" element={<ActionReport />} />
          <Route path="/risk-detail/:id" element={<RiskDetail />} />
          <Route path="/change-email" element={<ChangeEmail />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
