import Modal from 'react-bootstrap/Modal';
function CreateUserModal({ show, close }) {
    return (
        <>
            <Modal show={show} size="lg" dialogClassName="modal-rounded modal-350" centered onHide={close}>
                <Modal.Header className='text-center border-0 justify-content-center position-relative abolute-close modal-header flex-column' closeButton>
                    <Modal.Title className='fs-21-600'>Create user</Modal.Title>
                    <h6 className='fs-13 mt-2 px-md-4'>An invitation link will be sent to the user and they can login using the credential</h6>
                </Modal.Header>
                <Modal.Body className='text-center pt-1'>
                        <div className="input-group mb-3">
                            <input type="text" name="" id="" placeholder='Enter name' className='input-1 w-100' />
                        </div>
                        <div className="input-group mb-4">
                            <input type="text" name="" id="" placeholder='Enter Email' className='input-1 w-100' />
                        </div>
                        <div className="input-group d-flex flex-wrap mb-4 row-2 px-2">
                            <label htmlFor="radio1" className='w-50 customRadio d-flex align-items-center gap-2 mb-2'>
                                    <input type="radio" name="demoradio" id="radio1" />
                                    <h6 className='mb-0'>Project Manager</h6>
                                </label>
                                <label htmlFor="radio1" className='w-50 customRadio d-flex align-items-center gap-2 mb-2'>
                                    <input type="radio" name="demoradio" id="radio1" />
                                    <h6 className='mb-0'>Risk Manager</h6>
                                </label>
                                <label htmlFor="radio1" className='w-50 customRadio d-flex align-items-center gap-2'>
                                    <input type="radio" name="demoradio" id="radio1" />
                                    <h6 className='mb-0'>Engineer</h6>
                                </label>
                        </div>
                        <div className="input-group">
                            <button className='btn btn-primary rounded-pill h-55 px-5 fs-16 w-100'>Send Invite</button>
                        </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CreateUserModal;