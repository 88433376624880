import React, { useEffect, useState } from "react";
import { useSportRegisterReportQuery } from "../services/apis";
import { useParams } from "react-router-dom";
import NoData from "./NoData";
import { ExportToExcel } from "./ExportToExcel";

const RegisterTable = () => {
  const { id } = useParams();
  const [exportData, setExportData] = useState([]);

  const { data, refetch } = useSportRegisterReportQuery(id);
  const [list, setList] = useState([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const newData = data?.data?.map((item) => ({
      "Risk ID": item?.riskDetail?.riskNumber,
      "Risk Status": item?.projectName,
      "Risk Title": item?.riskDetail?.riskName,
      "Risk Cause": item?.riskDetail?.casuse,
      "Risk Event": item?.riskDetail?.riskEvent,
      "Risk Consequence": item?.riskDetail?.riskImpact,
      Comments: item?.riskDetail?.comment,
      "Risk Owner": item?.userDetail?.name,
      "Initial Evaluation": item?.data[0]?.riskMatrixValue?.riskValue,
      "Current Evaluation": item?.data[1]?.riskMatrixValue?.riskValue,
      "Target Evaluation": item?.data[2]?.riskMatrixValue?.riskValue,
      "Initial Cost Exposure": item?.data[0]?.finalEvaluation,
      "Current Cost Exposure": item?.data[1]?.finalEvaluation,
      "Target Cost Exposure": item?.data[2]?.finalEvaluation,
      "Action Progress": item?.actionProgressData,
    }));
    setExportData(newData);
  }, [data]);

  console.log("datadata", data?.data);

  return (
    <>
      <ExportToExcel apiData={exportData} fileName={"sportRegisterReport"} />
      {list === false ? (
        <NoData />
      ) : (
        <div className="table-responsive">
          <table class="table table-style-1 mb-0 mt-4">
            <thead>
              <tr>
                <th style={{ width: "50px" }}>SN</th>
                <th style={{ width: "80%" }}>
                  Risk ID
                </th>
                <th>Status
                </th>
                <th style={{ width: "100px"}}>
                  Title
                </th>
                <th>
                  Cause
                </th>
                <th>
                  Event
                </th>
                <th>
                  Consequence
                </th>
                <th>Comments</th>
                <th>Owner
                </th>
                <th>
                  Initial Evaluation
                </th>
                <th>
                  Current Evaluation
                </th>
                <th>
                  Target Evaluation
                </th>
                <th>
                  Initial Cost Exposure
                </th>
                <th>
                  Current Cost Exposure
                </th>
                <th>
                  Target Cost Exposure
                </th>
                
                <th>
                  Action Progress
                </th>
              </tr>
            </thead>

            <tbody>
              {!!data?.data?.length &&
                data?.data?.map((item, index) => {
                  return (
                    <tr key={item?._id}>
                      <td style={{ width: "2em" }}>{index + 1}</td>
                      <td style={{ textAlign: "left" }}>
                        {item?.riskDetail?.riskNumber}
                      </td>
                      <td>{item?.riskDetail?.riskStatusValue?.slice(0, 15)}</td>
                      <td><div className="short-para mx-auto text-center" title={item?.riskDetail?.riskName}>{item?.riskDetail?.riskName?.slice(0, 32)+'...'}</div></td>
                      <td><div className="short-para mx-auto text-center" title={item?.riskDetail?.casuse}>{item?.riskDetail?.casuse?.slice(0, 32)+'...'}</div></td>
                      <td>{item?.riskDetail?.riskEvent?.slice(0, 15)+'...'}</td>
                      <td>{item?.riskDetail?.riskImpact?.slice(0, 15)+'...'}</td>
                      <td>{item?.riskDetail?.comment?.slice(0, 15)+'...'}</td>
                      <td>{item?.userDetail?.name || "N/A"}</td>
                      <td>
                        {item?.data[0]?.riskMatrixValue?.riskValue || "N/A"}
                      </td>
                      <td>
                        {item?.data[1]?.riskMatrixValue?.riskValue || "N/A"}
                      </td>
                      <td>
                        {item?.data[2]?.riskMatrixValue?.riskValue || "N/A"}
                      </td>
                      <td>{item?.data[0]?.finalEvaluation || "N/A"}</td>
                      <td>{item?.data[1]?.finalEvaluation || "N/A"}</td>
                      <td>{item?.data[2]?.finalEvaluation || "N/A"}</td>
                      <td>{item?.actionProgressData}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default RegisterTable;
