import Modal from "react-bootstrap/Modal";
import { useAddContractUserMutation } from "../../services/apis";
import { useState, useEffect } from "react";
import { PopUp } from "../../utils/alert";
import Loader from "../../utils/loader";
import { handleErrors } from "../../utils/error";
import { validateEmail, validateFullName } from "../../utils/constants";

function CreateRiskOwner({ show, close, data }) {
  const [addContractUser, { data: userData, error, isLoading }] =
    useAddContractUserMutation();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    if (userData?.success) {
      PopUp("User Added Successfully", "", "success");
      close();
    }
    handleErrors(error);
  }, [userData, error]);

  const handleUser = () => {
    if (!userName) {
      PopUp("User name is required", "", "error");
      return;
    }

    if (!validateFullName(userName)) {
      PopUp("Please enter full name", "", "error");
      return;
    }

    if (!userEmail) {
      PopUp("User email is required", "", "error");
      return;
    }

    if (!userRole) {
      PopUp("Please select user role", "", "error");
      return;
    }

    if (!userType) {
      PopUp("User email is required", "", "error");
      return;
    }

    if (!validateEmail(userEmail)) {
      PopUp("Please select user type", "", "error");
      return;
    }
    addContractUser({
      name: userName,
      email: userEmail,
      userRole: userRole,
      userType: userType,
    });
  };

  return (
    <>
      <Modal
        show={show}
        size="lg"
        dialogClassName="modal-rounded modal-350"
        centered
        onHide={close}
      >
        <Modal.Header
          className="text-center border-0 justify-content-center position-relative abolute-close modal-header flex-column"
          closeButton
        >
          <Modal.Title className="fs-21-600">Create user</Modal.Title>
          <h6 className="fs-13 mt-2 px-md-4">
            An invitation link will be sent to the user and they can login using
            the credential
          </h6>
        </Modal.Header>
        <Modal.Body className="text-center pt-1">
          <div className="input-group mb-3">
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Enter full name"
              className="input-1 w-100"
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder="Enter Email"
              className="input-1 w-100"
            />
          </div>

          <div className="input-group mb-3">
            <select
              placeholder="User Role"
              class="form-control rounded-pill border"
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
            >
              <option value="">Select user role</option>
              <option value={1}>Risk Manager</option>
              <option value={2}>Engineer</option>
            </select>
          </div>

          <div className="input-group mb-3">
            <select
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              placeholder="User Type"
              class="form-control rounded-pill border"
            >
              <option value="">Select user type</option>
              <option value={0}>Internal</option>
              <option value={1}>External</option>
            </select>
          </div>
          <div className="input-group">
            <button
              className="btn btn-primary rounded-pill h-55 px-5 fs-16 w-100 d-flex"
              style={{ alignItems: "center", justifyContent: "center" }}
              onClick={handleUser}
            >
              {isLoading ? <Loader /> : "Send Invite"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateRiskOwner;
