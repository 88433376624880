import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Link } from "react-router-dom";
import Pencil from "../assets/icons/pencil.svg";
import NoData from "./NoData";
import MitigationModal from "./modals/MitigationModal";
import Pagination from "./Pagination";
import {
  useGetRiskByDetailQuery,
  useActionLogMutation,
  useActionLogExportMutation,
  useGetAllRiskLogMutation,
  useGetAllRiskLogExportMutation,
  useGetRiskLogByIdQuery,
  useGetRiskActionLogByIdQuery,
  useUpdateMitigationMutation,
} from "../services/apis";
import { useParams } from "react-router-dom";
import { PopUp } from "../utils/alert";
import { PAGE_LIMIT } from "../utils/constants";
import { ExportToExcel } from "./ExportToExcel";

const colOne = {
  fontSize: "15px",
  fontWeight: "bold",
  textAlign: "left",
  width: "30%",
};
const colTwo = {
  fontSize: "15px",
  width: "80%",
  textAlign: "left",
  overflowX: "auto",
  whiteSpace: "wrap",
};

const MitigationList = ({ rollData = () => console.log("") }) => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [riskId, setRiskId] = useState("");
  const [status, setStatus] = useState(1);
  const [showRiskRoll, setShowRiskRoll] = useState(false);
  const [riskShowId, setRiskShowId] = useState("");

  const [showActionRoll, setShowActionRoll] = useState(false);
  const [actionShowId, setActionShowId] = useState("");

  const [updatedName, setUpdatedName] = useState([]);
  const [updatedNameAction, setUpdatedNameAction] = useState([]);

  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState("");
  const [pageAction, setPageAction] = useState(1);
  const [pageDataAction, setPageDataAction] = useState("");
  const [exportData, setExportData] = useState([]);
  const [exportDataRisk, setExportDataRisk] = useState([]);
  const [exportDataAction, setExportDataAction] = useState([]);
  const [values, setValues] = useState({
    actionId: "",
    id: "",
    status: "",
    riskActionVersion: ""
  });
  const {
    data: riskDetail,

    refetch: riskDetailRefetch,
  } = useGetRiskByDetailQuery(id);

  console.log('riskDetail',riskDetail)
  const [
    updateMitigation,
    { data: updateData, isLoading: updateLoading, error: updateErr },
  ] = useUpdateMitigationMutation();
  const { data: riskShowData, refetch: riskShowRefetch } =
    useGetRiskLogByIdQuery(riskShowId);

    const { data: actionLogId, refetch: actionLogIdRefetch } =
    useGetRiskActionLogByIdQuery(actionShowId);

  const [actionLog, { data: actiondata }] = useActionLogMutation();
  const [actionLogExport, { data: actiondataExport }] =
    useActionLogExportMutation();

  const [getAllRiskLog, { data: logData }] = useGetAllRiskLogMutation();

  const [getAllRiskLogExport, { data: logDataExport }] =
    useGetAllRiskLogExportMutation();

    useEffect(() => {
      console.log('values',values)
      values?.actionId &&
        updateMitigation({ id: values?.actionId, riskActionVersion: values?.riskActionVersion, status: values?.id });
    }, [values]);
  useEffect(() => {
    riskDetailRefetch();
  }, [show,updateData]);
  // useEffect(() => {
  //   if (riskData?.success) {
  //     PopUp("Status updated successfully", "", "success");
  //     handleRiskId();
  //   }
  //   handleErrors(error);
  // }, [riskData, error]);
  useEffect(() => {
    getAllRiskLog({ id, page, limit: PAGE_LIMIT });
  }, [show, page]);

  useEffect(() => {
    getAllRiskLogExport({
      id,
      page: 1,
      limit: 100000,
    });
  }, [show]);

  useEffect(() => {
    actionLog({ id, page: pageAction, limit: PAGE_LIMIT });
  }, [show, pageAction]);

  useEffect(() => {
    actionLogExport({ id, page: 1, limit: 100000 });
  }, [show]);

  useEffect(() => {
    riskShowId !== "" && riskShowRefetch();
  }, [riskShowId]);

  useEffect(() => {
    actionShowId !== "" && actionLogIdRefetch();
  }, [actionShowId]);

  useEffect(() => {
    setPageData(logData?.data[0]);
  }, [logData]);

  useEffect(() => {
    setPageDataAction(actiondata?.data[0]);
  }, [actiondata]);

  useEffect(() => {
    const nameData = [
      "riskStatus",
      "startDate",
      "endDate",
      "riskDate",
      "riskName",
      "riskOwner",
      "riskType",
      "pmRisk",
      "orgRisk",
      "externalRisk",
      "techRisk",
      "riskImpact",
      "casuse",
      "riskEvent",
      "projectPhase",
      "comment",
    ];

    const resultArray = nameData.map((item) =>
      riskShowData?.data?.updateFields.includes(item)
    );

    setUpdatedName(resultArray);
  }, [riskShowData, showRiskRoll]);

  useEffect(() => {
    const nameData = [
      "riskStatus",
      "startDate",
      "endDate",
      "riskDate",
      "riskName",
      "riskOwner",
      "riskType",
      "pmRisk",
      "orgRisk",
      "externalRisk",
      "techRisk",
      "riskImpact",
      "casuse",
      "riskEvent",
      "projectPhase",
      "comment",
    ];

    const resultArray = nameData.map((item) =>
      riskShowData?.data?.updateFields.includes(item)
    );

    setUpdatedName(resultArray);
  }, [riskShowData, showRiskRoll]);

  useEffect(() => {
    const nameData = [
      "description",
      "summary",
      "comments",
      "actionOwner",
      "status",
      "progress",
      "startDate",
      "completeDate",
    ];

    const resultArray = nameData.map((item) =>
      actionLogId?.data?.updateFileds.includes(item)
    );

    setUpdatedNameAction(resultArray);
  }, [actionLogId, showActionRoll]);


  const handleConfirm = () => {
    PopUp("Data populate successfully", "Please save your risk", "success");
    rollData(riskShowData?.data);
    setShowRiskRoll(false);
    window.scrollTo(0, 0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageChangeAction = (newPage) => {
    setPageAction(newPage);
  };


  useEffect(() => {
    const newData = riskDetail?.data?.riskAction?.map((item) => ({
      "Action ID": item?.actionId,
      Description: item?.description,
      "Action Owner": item?.actionOwner?.name,
      Status: item?.status,
      "Action Summary": item?.summary,
      "Action Progress": item?.progress,
      Comments: item?.comments,
      "Start Date": moment(item?.startDate).format("DD MMM YYYY"),
      "End Date": moment(item?.endDate).format("DD MMM YYYY"),
      "Last Updated": moment(item?.modifiedAt).format("DD MMM YYYY"),
    }));
    setExportData(newData);
  }, [riskDetail]);

  useEffect(() => {
    const newData = logDataExport?.data[0]?.data?.map((item) => ({
      Date: moment(item?.updateAt).format("DD MMM YYYY"),
      Time: moment(item?.updateAt).format("hh:mm"),
      Username: item?.updatedBy[0]?.username,
      "Updated Fields": item?.updateFields?.join(", "),
    }));
    setExportDataRisk(newData);
  }, [logDataExport]);

  useEffect(() => {
    const newData = actiondataExport?.data[0]?.data?.map((item) => ({
      Date: moment(item?.updateAt).format("DD MMM YYYY"),
      Time: moment(item?.updateAt).format("hh:mm"),
      Username: item?.updatedBy[0]?.username,
      "Updated Fields": item?.updateFileds.join(", "),
    }));
    setExportDataAction(newData);
  }, [actiondataExport]);


  return (
    <>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="fs-16">Mitigations</h6>
            <ExportToExcel apiData={exportData} fileName={"actionData"} />
          </div>
          <div className="table-responsive mt-3 border-bottom">
            {riskDetail?.data?.riskAction?.length === 0 ? (
              <NoData />
            ) : (
              <table className="table table-style-1 mb-0" width="100%">
                <thead>
                  <tr>
                    <th className="py-4" width="50">
                      Action ID
                    </th>
                    <th className="py-4">
                      Description
                    </th>
                    <th className="py-4">
                      Action Owner
                    </th>
                    <th className="py-4">Status</th>
                    <th className="py-4">
                      Progress Summary
                    </th>
                    <th className="py-4">
                    Progression
                    </th>
                    <th className="py-4">
                     Comments
                    </th>
                    <th className="py-4">
                      Action Start
                    </th>
                    <th className="py-4">
                      Completion
                    </th>
                    <th className="py-4">
                      Updated
                    </th>
                    {/* <th
                      className="py-4"
                      width="125"
                      style={{
                        position: "sticky",
                        top: "0",
                        right: "0",
                      }}
                    >
                      Action
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {!!riskDetail?.data?.riskAction?.length &&
                    riskDetail?.data?.riskAction?.map((item) => {
                      return (
                        <tr key={item?._id}>
                          <td  className="text-primary"  style={{ cursor: "pointer"}}  onClick={() => {
                                  setShow(true);
                                  setRiskId(item?._id);
                                }}>{item?.actionId}</td>
                          <td title={item?.description}>
                          <div className="short-para">
                            {item?.description &&
                              item?.description?.slice(0, 70)}
                              </div>
                          </td>
                          <td>{item?.actionOwner?.name}</td>
                          <td>
                            <Dropdown>
                              {/* <Dropdown.Toggle
                                variant="transparent"
                                className="border-0 p-0 text-dark dropWithArrow right-0 position-relative"
                                id="dropdown-basic"
                              >
                                {item?.status}
                              </Dropdown.Toggle> */}
                               <Dropdown.Toggle
                                  variant="transparent"
                                  className="border-0 p-0 text-dark dropWithArrow right-0 position-relative"
                                  id="dropdown-basic"
                                >
                                  {updateLoading &&
                                  values?.actionId === item?._id
                                    ? "Updating..."
                                    : item?.status}
                                </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  href="#/action-1"
                                  className="gap-2 d-flex align-items-center"
                                  onClick={() =>
                                    setValues({
                                      actionId: item?._id,
                                      id: "Draft",
                                      name: "Draft",
                                      riskActionVersion: 1
                                    })
                                  }
                                >
                                  <span className="bg-secondary opacity-50 mr-2 wh-12"></span>{" "}
                                  Draft
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#/action-2"
                                  className="gap-2 d-flex align-items-center"
                                  onClick={() =>
                                    setValues({
                                      actionId: item?._id,
                                      id: "InProgress",
                                      name: "In Progress",
                                      riskActionVersion: 1
                                    })
                                  }
                                >
                                  <span className="bg-yellow mr-2 wh-12"></span>{" "}
                                  In progress
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#/action-2"
                                  className="gap-2 d-flex align-items-center"
                                  onClick={() =>
                                    setValues({
                                      actionId: item?._id,
                                      id: "Completed",
                                      name: "Completed",
                                      riskActionVersion: 1
                                    })
                                  }
                                >
                                  <span className="bg-green mr-2 wh-12"></span>{" "}
                                  Completed
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td title={item?.summary}>
                          <div className="short-para">
                            {item?.summary?.slice(0, 70)}
                            </div>
                          </td>
                          <td title={item?.progress}>
                            {item?.progress?.slice(0, 10)}
                          </td>
                          <td title={item?.comments}>
                          <div className="short-para">
                            {item?.comments?.slice(0, 70)}
                            </div>
                          </td>

                          <td>
                            {moment(item?.startDate).format("DD MMM YYYY")}
                          </td>
                          <td>
                            {moment(item?.completeDate).format("DD MMM YYYY")}
                          </td>
                          <td>16 Nov 2023</td>
                          {/* <td
                            style={{
                              position: "sticky",
                              top: "0",
                              right: "0",
                            }}
                          >
                            <div className="action">
                              <Link
                                className="mr-4"
                                onClick={() => {
                                  setShow(true);
                                  setRiskId(item?._id);
                                }}
                              >
                                <img src={Pencil} alt="" />
                              </Link>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div className="col-md-12 mt-5">
          <div className="flex-wrap"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "2em",
            }}
          >
            <h6 className="fs-16">{status === 1 ? "Risk Logs" : "Mitigation Logs"}</h6>
            <div className="flex-wrap"
              style={{
                display: "flex",
                gap: ".5em",
                justifyContent: "center",
              }}
            >
              <button
                className={`btn px-5 flex-fill btn-${status === 1 ? "primary" : "secondary"}`}
                onClick={() => setStatus(1)}
              >
                Risk
              </button>
              <button
                className={`btn px-5 flex-fill btn-${status === 2 ? "primary" : "secondary"}`}
                onClick={() => setStatus(2)}
              >
                Mitigation
              </button>
              {status === 1 ? (
                <ExportToExcel
                  apiData={exportDataRisk}
                  fileName={"riskLogData"}
                />
              ) : (
                <ExportToExcel
                  apiData={exportDataAction}
                  fileName={"actionLogData"}
                />
              )}
            </div>
          </div>
          {status === 1 && (
            <div className="table-responsive">
              {logData?.data?.length === 0 ? (
                <NoData />
              ) : (
                <>
                  <table
                    className="table table-style-1 mb-0"
                    style={{ overflowX: "auto" }}
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Username</th>
                        <th>Updated Fields</th>
                        <th
                          style={{
                            position: "sticky",
                            right: "0",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!logData?.data[0]?.data.length &&
                        logData?.data[0]?.data?.map((item) => {
                          return (
                            <tr key={item?._id}>
                              <td>
                                {moment(item?.updateAt).format("DD MMM YYYY")}
                              </td>
                              <td>
                                {moment(item?.updateAt).format("hh:mm A")}
                              </td>
                              <td>{item?.updatedBy[0]?.username}</td>
                              <td
                                style={{
                                  overflowX: "auto",
                                  whiteSpace: "wrap",
                                }}
                              >
                                {item?.updateFields.join(", ")}
                              </td>
                              <td
                                style={{
                                  position: "sticky",
                                  right: "0",
                                }}
                              >
                                <i
                                  class="fa-solid fa-clock-rotate-left"
                                  title="Version Restore"
                                  style={{
                                    color: "#e75d0f",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowRiskRoll(true);
                                    setRiskShowId(item?._id);
                                  }}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {pageData?.totalRecords > PAGE_LIMIT && (
                    <Pagination
                      data={pageData}
                      pageNo={handlePageChange}
                      pageVal={page}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {status === 2 && (
            <div className="table-responsive mt-3">
              {riskDetail?.data?.riskAction?.length === 0 ? (
                <NoData />
              ) : (
                <>
                  <table
                    className="table table-style-1 mb-0"
                    style={{ overflowX: "auto" }}
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Username</th>
                        <th>Updated Fields</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!actiondata?.data[0]?.data.length &&
                        actiondata?.data[0]?.data?.map((item) => {
                          return (
                            <tr key={item?._id}>
                              <td>
                                {moment(item?.modifiedAt).format("DD MMM YYYY")}
                              </td>
                              <td>
                                {moment(item?.modifiedAt).format("hh:mm A")}
                              </td>
                              <td>{item?.updatedBy[0]?.username}</td>
                              <td
                                style={{
                                  overflowX: "auto",
                                  whiteSpace: "wrap",
                                }}
                              >
                                {item?.updateFileds?.join(", ")}
                              </td>
                              <td
                                style={{
                                  position: "sticky",
                                  right: "0",
                                }}
                              >
                                <i
                                  class="fa-solid fa-clock-rotate-left"
                                  title="Version Restore"
                                  style={{
                                    color: "#e75d0f",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowActionRoll(true);
                                    setActionShowId(item?._id);
                                  }}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {pageDataAction?.totalRecords > PAGE_LIMIT && (
                    <Pagination
                      data={pageDataAction}
                      pageNo={handlePageChangeAction}
                      pageVal={pageAction}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        show={showRiskRoll}
        size="lg"
        dialogClassName="modal-rounded modal-650"
        centered
        onHide={() => setShowRiskRoll(false)}
      >
        <Modal.Header
          className="text-center border-0 justify-content-center position-relative abolute-close modal-header flex-column"
          closeButton
        >
          <Modal.Title className="fs-21-600">Preview Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center row mx-0 pt-1">
          <table className="table table-style-1 mb-0" id="table-to-xls">
            <thead></thead>
            <tbody>
              <tr>
                <td style={colOne}>Risk Status:</td>
                <td style={colTwo}>
                  <span style={{ color: updatedName[0] ? "red" : "" }}>
                    {riskShowData?.data?.riskStatus?.name}
                  </span>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Registration Date:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedName[1] ? "red" : "" }}>
                    {moment(riskShowData?.data?.startDate).format(
                      "DD MMM YYYY"
                    )}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Completion Date:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedName[2] ? "red" : "" }}>
                    {moment(riskShowData?.data?.endDate).format("DD MMM YYYY")}
                  </p>{" "}
                </td>
              </tr>
              <tr>
                <td style={colOne}>Reminder Date:</td>
                <td style={colTwo}>
                  {" "}
                  <p style={{ color: updatedName[3] ? "red" : "" }}>
                    {moment(riskShowData?.data?.riskDate).format("DD MMM YYYY")}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Risk Title:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedName[4] ? "red" : "" }}>
                    {" "}
                    {riskShowData?.data?.riskName}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Risk Owner:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedName[5] ? "red" : "" }}>
                    {riskShowData?.data?.riskOwner?.name}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Risk Type:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedName[6] ? "red" : "" }}>
                    {riskShowData?.data?.riskType?.name}
                  </p>
                </td>
              </tr>
              {riskShowData?.data?.riskType?.name === "Project Management" && (
                <tr>
                  <td style={colOne}>Risk Subtype (Project Mgmt):</td>
                  <td style={colTwo}>
                    <p style={{ color: updatedName[7] ? "red" : "" }}>
                      {riskShowData?.data?.pmRisk?.name || "N/A"}
                    </p>
                  </td>
                </tr>
              )}
              {riskShowData?.data?.riskType?.name === "Organizational" && (
                <tr>
                  <td style={colOne}>Risk Subtype (Organizational):</td>
                  <td style={colTwo}>
                    <p style={{ color: updatedName[8] ? "red" : "" }}>
                      {riskShowData?.data?.orgRisk?.name || "N/A"}
                    </p>
                  </td>
                </tr>
              )}
              {riskShowData?.data?.riskType?.name === "External" && (
                <tr>
                  <td style={colOne}>Risk Subtype (External):</td>
                  <td style={colTwo}>
                    <p style={{ color: updatedName[9] ? "red" : "" }}>
                      {riskShowData?.data?.externalRisk?.name || "N/A"}
                    </p>
                  </td>
                </tr>
              )}
              {riskShowData?.data?.riskType?.name === "Technical" && (
                <tr>
                  <td style={colOne}>Risk Subtype (Technical):</td>
                  <td style={colTwo}>
                    <p style={{ color: updatedName[10] ? "red" : "" }}>
                      {riskShowData?.data?.techRisk?.name || "N/A"}
                    </p>
                  </td>
                </tr>
              )}
              <tr>
                <td style={colOne}>Risk Impact:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedName[11] ? "red" : "" }}>
                    {riskShowData?.data?.riskImpact}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Cause:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedName[12] ? "red" : "" }}>
                    {riskShowData?.data?.casuse}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Event:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedName[13] ? "red" : "" }}>
                    {riskShowData?.data?.riskEvent}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Project Phase:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedName[14] ? "red" : "" }}>
                    {riskShowData?.data?.projectPhase?.name}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Comments:</td>
                <td style={colTwo}>
                  <p
                    style={{
                      wordWrap: "break-word",
                      color: updatedName[15] ? "red" : "",
                    }}
                  >
                    {riskShowData?.data?.comment}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="mt-4">After confirm preview, Data must be save</p>
          <button className="btn btn-sm btn-primary " onClick={handleConfirm}>
            Confirm Preview
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showActionRoll}
        size="lg"
        dialogClassName="modal-rounded modal-650"
        centered
        onHide={() => setShowActionRoll(false)}
      >
        <Modal.Header
          className="text-center border-0 justify-content-center position-relative abolute-close modal-header flex-column"
          closeButton
        >
          <Modal.Title className="fs-21-600">Preview Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center row mx-0 pt-1">
          <table className="table table-style-1 mb-0" id="table-to-xls">
            <thead></thead>
            <tbody>
              <tr>
                <td style={colOne}>Action Description:</td>
                <td style={colTwo}>
                  <span style={{ color: updatedNameAction[0] ? "red" : "" }}>
                    {actionLogId?.data?.description}
                  </span>
                </td>
              </tr>
              {actionLogId?.data?.summary && (
                <tr>
                  <td style={colOne}>Action Summary:</td>
                  <td style={colTwo}>
                    <span style={{ color: updatedNameAction[1] ? "red" : "" }}>
                      {actionLogId?.data?.summary}
                    </span>
                  </td>
                </tr>
              )}
              {actionLogId?.data?.comments && (
                <tr>
                  <td style={colOne}>Action Comment:</td>
                  <td style={colTwo}>
                    <span style={{ color: updatedNameAction[2] ? "red" : "" }}>
                      {actionLogId?.data?.comments}
                    </span>
                  </td>
                </tr>
              )}
              <tr>
                <td style={colOne}>Action Owner:</td>
                <td style={colTwo}>
                  <span style={{ color: updatedNameAction[3] ? "red" : "" }}>
                    {actionLogId?.data?.actionOwner?.name}
                  </span>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Action Status:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedNameAction[4] ? "red" : "" }}>
                    {" "}
                    {actionLogId?.data?.status}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Action Progress:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedNameAction[5] ? "red" : "" }}>
                    {actionLogId?.data?.progress}
                  </p>
                </td>
              </tr>
              <tr>
                <td style={colOne}>Start Date:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedNameAction[6] ? "red" : "" }}>
                    {moment(actionLogId?.data?.startDate).format("DD MMM YYYY")}
                  </p>{" "}
                </td>
              </tr>
              <tr>
                <td style={colOne}>End Date:</td>
                <td style={colTwo}>
                  <p style={{ color: updatedNameAction[7] ? "red" : "" }}>
                    {moment(actionLogId?.data?.completeDate).format(
                      "DD MMM YYYY"
                    )}
                  </p>{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <p className="mt-4">After confirm preview, Data must be save</p>
          <button
            className="btn btn-sm btn-primary "
            onClick={() => {
              setShowActionRoll(false);
              setShow(true);
            }}
          >
            Confirm Preview
          </button>
        </Modal.Body>
      </Modal>

      <MitigationModal
        show={show}
        close={() => setShow(false)}
        id={riskId}
        edit={true}
        rollData={actionLogId?.data}
      />
    </>
  );
};

export default MitigationList;
