import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function Breadcrumbs() {
  const location = useLocation();
  const contractData = useSelector((state) => state.constants.contractLink);
  const projectData = useSelector((state) => state.constants.projectLink);
  const riskData = useSelector((state) => state.constants.riskLink);

  return (
    <div className="title-head">
      
      
      {(location.pathname.includes("contracts") || 
      location.pathname.includes("platform-support") || 
      location.pathname.includes("invitations") || 
      location.pathname.includes("cms") ||
      location.pathname.includes("view-ticket"))  ? '' : (
        <Link
        to={`/view-contract/${contractData?.id}/true`}
        style={{
          color: "#414141",
        }}
      >
        {contractData?.name}
      </Link>
      )}
      
      {(location.pathname.includes("view-project") ||
        location.pathname.includes("edit-risk") ||
        location.pathname.includes("assessment") ||
        location.pathname.includes("risk-detail") ||
        location.pathname.includes("add-risk")) && (
        <Link
          to={`/view-project/${projectData.id}`}
          style={{
            color: "#414141",
          }}
        >
          {" "}
          {/* &gt; {projectData?.name} */}
          &gt; {projectData?.name ? 
    (projectData.name.length > 15 ? projectData.name.slice(0, 15) + '...' : projectData.name) 
    : ''}
        </Link>
      )}

      {(location.pathname.includes("edit-risk") ||
        location.pathname.includes("assessment") ||
        location.pathname.includes("risk-detail")) && (
        <Link
          style={{
            color: "#414141",
          }}
        >
          {" "}
          &gt; {riskData?.name}
        </Link>
      )}
    </div>
  );
}

export default Breadcrumbs;
