import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import MitigationModal from "./modals/MitigationModal";
import {
  useGetAllRiskActionsMutation,
  useGetAllRiskActionsExportMutation,
} from "../services/apis";
import { useParams } from "react-router-dom";
import moment from "moment";
import NoData from "./NoData";
import Spinner from "./Spinner";
import { useUpdateMitigationMutation } from "../services/apis";
import { PopUp } from "../utils/alert";
import { handleErrors } from "../utils/error";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import { PAGE_LIMIT } from "../utils/constants";
import { ExportToExcel } from "./ExportToExcel";

const Actions = () => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [riskId, setRiskId] = useState("");
  const [mitigation, setMitigation] = useState(false);
  const [values, setValues] = useState({
    actionId: "",
    id: "",
    name: "",
    riskActionVersion: ""
  });
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState("");
  const [exportData, setExportData] = useState([]);

  const [getAllRiskActions, { data, isLoading, error }] =
  useGetAllRiskActionsMutation();
  const [updateMitigation, { data: updateData, error: updateErr ,isLoading: updating}] =
    useUpdateMitigationMutation();

  const [getAllRiskActionsExport, { data: actionExport }] =
    useGetAllRiskActionsExportMutation();

  const handleACtions = () => {
    getAllRiskActions({
      id,
      page,
      limit: PAGE_LIMIT,
    });
  };
  useEffect(() => {
    handleACtions();
  }, [page]);

  useEffect(() => {
    getAllRiskActionsExport({
      id,
      page,
      limit: 100000,
    });
  }, []);

  useEffect(() => {
    console.log('values',values)
    values?.actionId &&
      updateMitigation({ id: values?.actionId, riskActionVersion: values?.riskActionVersion, status: values?.id });
  }, [values]);
  useEffect(() => {
    if (updateData?.success) {
      PopUp("Status updated successfully", "", "success");
      handleACtions();
    }
    handleErrors(updateErr);
  }, [error, updateData, updateErr]);

  useEffect(() => {
    setPageData(data?.data);
  }, [data]);

  useEffect(() => {
    const newData = actionExport?.data[0]?.data?.map((item) => ({
      "Risk ID": item?.risk?.riskNumber,
      "Risk Title": item?.risk?.riskName,
      "Action ID": item?.actionId,
      Description: item?.description,
      "Action Owner": item?.actionOwner?.username,
      Status: item?.status === undefined ? "" : item?.status,
      "Action summary": item?.summary,
      "Action Progress": item?.progress,
      "Action Comments": item?.comments,
      "Start Date": moment(item?.startDate).format("DD MMM YYYY"),
      "End Date": moment(item?.endDate).format("DD MMM YYYY"),
    }));
    setExportData(newData);
  }, [actionExport]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const closeMitigation = () => setMitigation(false);
  return (
    <>
      <div className="row mt-1">
        <div className="col-md-12">
          <div className="seachBarWIthButton d-flex justify-content-end gap-3 align-items-center">
            <input
              type="search"
              placeholder="Search User"
              className="rounded-pill searchBar searchOnLeft"
            />
            <div className="d-flex gap-3">
              {/* <Link
                to={`/action-report/${id}`}
                className="btn btn-outline-primary rounded-pill fs-14-normal px-3 h-48 align-items-center d-flex"
              >
                Save report
              </Link> */}
              {data?.data[0]?.data?.length > 0 && (
                <ExportToExcel apiData={exportData} fileName={"actionData"} />
              )}
            </div>
          </div>
          <div className="table-responsive mt-1">
            {isLoading ? (
              <Spinner />
            ) : (
              <table className="table table-style-1 mb-0">
                <thead>
                  <tr>
                    <th className="py-4" width="50">
                      Risk ID
                    </th>
                    <th className="py-4 text-dark">
                      Risk Title
                    </th>
                    <th className="py-4">
                    Mitigation ID 
                    </th>
                    <th className="py-4">
                      Description
                    </th>
                    <th className="py-4">
                      Action Owner
                    </th>
                    <th className="py-4">Status</th>
                    <th className="py-4">
                      Progress Summary
                    </th>
                    <th className="py-4">
                    Progression
                    </th>
                    <th className="py-4">
                     Comments
                    </th>
                    <th className="py-4">
                      Action Start
                    </th>
                    <th className="py-4">
                      Completion
                    </th>
                    <th className="py-4">
                      Updated
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {!!data?.data[0]?.data?.length &&
                    data?.data[0]?.data?.map((item) => {
                      return (
                        <tr key={item?._id}>
                          <td style={{textAlign:'left' }}>{item?.risk?.riskNumber}</td>
                          <td className="fw-bold" style={{ color: "#e75d0f",textAlign:'left' }}>
                          <Link
    className="fw-bold"
      to={
        // item?.risk?.isVarified 
          // ? 
          `/edit-risk/${item?.risk?._id}/${id}/${item?.risk?._id}/false`
          // : ""
      }style={{ color: "#e75d0f" }}
      >
        {item?.risk?.riskName && item?.risk?.riskName?.length > 32 ?
   `${item?.risk?.riskName?.slice(0, 32)}...` :
    item?.risk?.riskName} </Link>
    </td>
                          <td style={{textAlign:'left' }}>{item?.actionId}</td>
                          <td  className="fw-bold"
                            title={item?.description}
                            style={{ cursor: "pointer",color: "#e75d0f",textAlign:'left' }}
                            onClick={() => {
                              setShow(true);
                              setRiskId(item?._id);
                            }}
                          >
                            <div className="short-para">
                            {item?.description?.length > 70 ? item?.description?.slice(0, 70) + "..." : item?.description}
                            </div>
                          </td>
                          <td style={{textAlign:'left' }}>{item?.actionOwner?.username}</td>
                          <td style={{textAlign:'left' }}>
                            <Dropdown>
                              {/* <Dropdown.Toggle
                                variant="transparent"
                                className="border-0 p-0 text-dark dropWithArrow right-0 position-relative"
                                id="dropdown-basic"
                              >
                                {item?.status}
                              </Dropdown.Toggle> */}
                               <Dropdown.Toggle
                                  variant="transparent"
                                  className="border-0 p-0 text-dark dropWithArrow3 w-100 me-3 right-0 position-relative"
                                  id="dropdown-basic"
                                >
                                  {updating &&
                                  values?.actionId === item?._id
                                    ? "Updating..."
                                    : item?.status}
                                </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  as="button"
                                  className="gap-2 d-flex align-items-center"
                                  onClick={() =>
                                    setValues({
                                      actionId: item?._id,
                                      id: "Draft",
                                      name: "Draft",
                                      riskActionVersion: 1
                                    })
                                  }
                                >
                                  <span className="bg-secondary opacity-50 mr-2 wh-12"></span>{" "}
                                  Draft
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="gap-2 d-flex align-items-center"
                                  onClick={() =>
                                    setValues({
                                      actionId: item?._id,
                                      id: "InProgress",
                                      name: "In Progress",
                                      riskActionVersion: item?.riskActionVersion + 1
                                    })
                                  }
                                >
                                  <span className="bg-yellow mr-2 wh-12"></span>{" "}
                                  In progress
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  className="gap-2 d-flex align-items-center"
                                  onClick={() =>
                                    setValues({
                                      actionId: item?._id,
                                      id: "Completed",
                                      name: "Completed",
                                      riskActionVersion: 1
                                    })
                                  }
                                >
                                  <span className="bg-green mr-2 wh-12"></span>{" "}
                                  Completed
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td
                            title={item?.summary}
                            style={{ cursor: "pointer",textAlign:'left' }}
                          >
                             <div className="short-para">
                            {item?.summary?.length > 70 ?  item?.summary?.slice(0, 70) + "..." : item?.summary}
                            </div>
                          </td>{" "}
                          <td
                            title={item?.progress}
                            style={{ cursor: "pointer",textAlign:'left' }}
                          >
                            {item?.progress?.slice(0, 12)}
                          </td>{" "}
                          <td
                            title={item?.comments}
                            style={{ cursor: "pointer",textAlign:'left' }}
                          >
                            <div className="short-para">
                            {item?.comments?.length > 70 ? item?.comments?.slice(0, 70) + "..." : item?.description}
                            </div>
                          </td>
                          <td style={{textAlign:'left' }}>
                            {moment(item?.startDate).format("DD MMM YYYY")}
                          </td>
                          <td style={{textAlign:'left' }}>
                            {moment(item?.completeDate).format("DD MMM YYYY")}
                          </td>
                          <td>12/12/23</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
          {data?.data?.length === 0 && <NoData />}
          {pageData && pageData[0]?.totalRecords > PAGE_LIMIT && (
            <Pagination
              data={pageData}
              pageNo={handlePageChange}
              pageVal={page}
            />
           )} 
        </div>
      </div>
      {/* <MitigationModal show={mitigation} close={closeMitigation} /> */}
      <MitigationModal
        show={show}
        close={() => setShow(false)}
        id={riskId}
        edit={true}
        // rollData={actionLogId?.data}
      />
    </>
  );
};

export default Actions;
