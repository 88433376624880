import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Edit from "../assets/icons/edit.svg";
import File from "../assets/icons/file-edit.svg";
import AddContractModal from "../components/modals/AddContractModal";
import EditContractModal from "../components/modals/EditContractModal";
import {
  useGetContractListMutation,
  useGetLastUpdateDataMutation,
  useGetLastUpdateDataQuery,
  useGetRiskByDetailQuery,
} from "../services/apis";
import NoData from "../components/NoData";
import Spinner from "../components/Spinner";
import { handleErrors } from "../utils/error";
import Pagination from "../components/Pagination";
import { PAGE_LIMIT } from "../utils/constants";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MitigationModal from "../components/modals/MitigationModal";

const Contracts = () => {
  const navigate = useNavigate();
  const { profileInfo } = useSelector((state) => state?.constants);
  const [search, setSearch] = useState("");
  const [riskId, setRiskId] = useState("");
  const { data:riskData, isFetching, refetch } = useGetRiskByDetailQuery(riskId);
  if(riskData?.data?.projectId?._id){
    navigate(`/edit-risk/${riskId}/${riskData?.data?.projectId?._id}/${riskId}/false`)
  }
  const [getContractList, { data, isLoading, error }] =
    useGetContractListMutation();

    const [getLastUpdateData, { data:last, isLoading:lastLoad, error:lastError }] =
    useGetLastUpdateDataMutation();
      // const getLastUpdateData = useGetLastUpdateDataQuery();
      const [mitiShow, setMitiShow] = useState(false);
      
      const [add, setAdd] = useState(false);
      const [editId, setEditId] = useState("");
      const [edit, setEdit] = useState(false);
      const [id, setId] = useState("");
      
      const [page, setPage] = useState(1);
      const [pageData, setPageData] = useState({});
      const [pageAction, setPageAction] = useState(1);
      const [pageActionData, setPageActionData] = useState({});
      const handleACtions = () => {
        console.log('pageAction',pageAction)
        getLastUpdateData({
          page :1,
          limit: 2,
        });
      };
      useEffect(() => {
        handleACtions();
      }, [pageAction]);
      const [pageProject, setPageProject] = useState(1);
      const [pageProjectData, setPageProjectData] = useState({});
      const handleProject = () => {
        console.log('pageProject',pageProject)
        getLastUpdateData({
          page :1,
          limit: 2,
        });
      };
      useEffect(() => {
        handleProject();
      }, [pageProject]);

  const showAdd = () => setAdd(true);
  const closeAdd = () => setAdd(false);

  useEffect(() => {
    const obj = {
      limit: 10,
      page,
    };
    if (search !== "") {
      obj.search = search;
    }
    !add && !edit && getContractList(obj);
    handleErrors(error);
  }, [add, search, edit, page]);

  useEffect(() => {
    setPageData(data?.data);
  }, [data]);
  useEffect(() => {
    setPageActionData(last?.data?.action);
    setPageProjectData(last?.data?.project)
  }, [last]);

  const handlePageProjectChange = (newPage) => {
    setPageProject(newPage);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const riskPage = async (riskId) => {
    setRiskId(riskId)
  };
  useEffect(  () => {
    if(riskId){

      refetch(riskId)
    }
  }, [riskId]);

  const showEdit = () => setEdit(true);
  const closeEdit = () => setEdit(false);

  console.log("getLastUpdateData", profileInfo);

  return (
    <>
      <div className="mt-2">
        <input
          type="text"
          placeholder="Search"
          className="rounded-pill searchBar"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/* {(profileInfo?.data?.userRole === 0 ||
          profileInfo?.data?.role?.role === "0") && (
            <button
              className="btn btn-primary rounded-pill fs-14-normal px-3 h-48 d-inline-flex gap-2"
              onClick={showAdd}
            >
              <i className="fa-solid fa-plus" style={{ fontWeight: "bold" }}></i>{" "}
              <span>Create Contract</span>
            </button>
          )} */}
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {data?.data?.data?.length === 0 ? (
         <button
         style={{width: '147px'}}
         className="mt-3 h-147 custom-border-4 text-414141 text-center text-uppercase d-flex justify-content-center align-items-center"
         onClick={showAdd}
       >
         +<br />
         ADD
         <br />
         CONTRACT
       </button>
          ) : (
            <div className="custom-grid-col-8-count mt-3 mx-0">
              {!!data?.data?.data?.length &&
                data?.data?.data?.map((item) => {
                  const ids = [
                    item?.epPM,
                    item?.epRM,
                    item?.clientPM,
                    item?.clientRM,
                  ];

                  const isManager = ids.includes(profileInfo?.data?._id);

                  return (
                    <div
                      className=""
                      key={item?._id}
                      title={item?.contractName}
                    >
                      <div className="custom-border-3 text-center position-relative">
                        {/* {(profileInfo?.data?.userRole === 0 || isManager) && (
                          <Link className="contractEdit">
                            <img
                              src={Edit}
                              alt="edit"
                              onClick={() => {
                                setEditId(item?._id);
                                showEdit();
                              }}
                            />
                          </Link>
                        )} */}

                        <div>
                          {/* <span className="Short-Alphabate">
                            {item?.contractName?.slice(0, 2).toUpperCase()}
                          </span> */}
                          {(profileInfo?.data?.userRole === 0 || isManager) && (
                            <Link className="contractFile position-relative">
                              <img
                                src={File}
                                alt="edit"
                                onClick={() => {
                                  setEditId(item?._id);
                                  showEdit();
                                }}
                              />
                            </Link>
                          )}

                          <h6 className="fs-16 my-2 text-light-dark line-clamp fw-bold">
                            {item?.contractName?.length > 10
                              ? item?.contractName?.slice(0, 10) + "..."
                              : item?.contractName}
                          </h6>
                          <div className="fs-11 fw-bold text-light-dark">
                            <span>{item?.totalProjectsCount} Projects</span>
                          </div>
                          <div className="mt-1 fs-11 fw-bold text-light-dark">
                            <span>{item?.totalProjectManager} PMs</span> |{" "}
                            <span>{item?.totalRiskManager} RMs</span>
                          </div>
                        </div>
                        <Link
                          to={`/view-contract/${item?._id}/${isManager}`}
                          className="stretched-link"
                        ></Link>
                      </div>
                    </div>
                  );
                })}

              {(profileInfo?.data?.userRole === 0 ||
                profileInfo?.data?.role?.role === "0") && (
                <button
                  className="custom-border-4 text-414141 text-center text-uppercase d-flex justify-content-center align-items-center h-147"
                  onClick={showAdd}
                >
                  +<br />
                  ADD
                  <br />
                  CONTRACT
                </button>
              )}
            </div>
          )}
          {pageData && pageData?.totalRecords > PAGE_LIMIT && (
            <Pagination
              data={pageData}
              pageNo={handlePageChange}
              pageVal={page}
            />
          )}
        </>
      )}

      <div className="mt-4">
        <div>
          <h6 className="fs-16 fw-bold mb-0">Your recent projects</h6>
        </div>
        <div className="table-responsive mt-1">
          <table className="table table-style-1 mb-2 table-bg-2">
            <thead>
              <tr>
                <th className="fw-bold text-dark">EP Code</th>
                <th>Project Title</th>
                <th>Description</th>
                <th className="text-dark">EP Project Manager</th>
                <th className="text-dark">EP Risk Manager</th>
                <th>Client Project manager </th>
                <th>Client Risk manager</th>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>
            <tbody>
              {last?.data?.project?.data?.map((data) => {
                return (
                  <>
                    <tr className="fs-11">
                      <td className="fw-bold">
                        <a
                          className="text-primary fs-16 fw-bold"
                          onClick={() => navigate(`/view-project/${data?._id}`)}
                          role="button"
                        >
                          {data.projectNumber}
                        </a>
                      </td>
                      <td>
                        {data?.projectName && data?.projectName?.length > 32
                          ? `${data?.projectName.slice(0, 32)}...`
                          : data?.projectName}
                      </td>
                      <td>
                        <div className="short-para">
                          {data?.description && data?.description.length > 70
                            ? `${data.description.slice(0, 70)}...`
                            : data.description}
                        </div>
                      </td>
                      <td> {data?.epPM?.name} </td>
                      <td>{data?.epRM?.name}</td>
                      <td>{data?.clientPM?.name} </td>
                      <td>{data?.clientRM?.name} </td>
                      <td>{moment(data?.startDate).format("DD MMM YYYY")}</td>
                      <td>{moment(data?.endDate).format("DD MMM YYYY")}</td>
                    </tr>
                  </>
                );
              })}
               {/* {pageProjectData && pageProjectData?.totalRecords > PAGE_LIMIT && (
                 <Pagination
                 data={pageProjectData}
                 pageNo={handlePageProjectChange}
                 pageVal={pageProject}
                 />
                )} */}
            </tbody>
          </table>
                {last?.data?.project.data?.length == 0 &&
                <NoData />}
        </div>
      </div>
      <div className="mt-4">
        <div>
          <h6 className="fs-16 fw-bold mb-0">Your recent mitigations</h6>
        </div>
        <div className="table-responsive mt-1">
          <table className="table table-style-1 mb-2">
            <thead>
              <tr>
                <th className="fw-bold text-dark">Risk Title</th>
                <th>Mitigation ID </th>
                <th>Description</th>
                <th>Action Owner</th>
                <th>Status</th>
                <th>Progress Summary</th>
                <th>Progression</th>
                <th>Comments</th>
                <th>Action Start</th>
                <th>Completion</th>
                <th>Updated</th>
              </tr>
            </thead>
            <tbody>
              {last?.data?.action?.data?.map((data) => {
                return (
                  <>
                    <tr className="fs-11">
                      <td className="fw-bold" >
                        <a
                          className="text-primary"
                          onClick={() => riskPage(data?.riskId?._id)}
                          role="button"
                        >
                          {data?.riskId?.riskName &&
                          data?.riskId?.riskName?.length > 32
                            ? `${data?.riskId?.riskName?.slice(0, 32)}...`
                            : data?.riskId?.riskName}
                        </a>
                      </td>
                      <td>{data?.actionId}</td>
                      <td onClick={() => setId(data?._id)} >
                        <div onClick={() => setMitiShow(true)} className="short-para text-primary fw-bold">
                          {data?.description && data.description.length > 70
                            ? `${data.description.slice(0, 70)}...`
                            : data?.description}
                        </div>
                      </td>
                      <td>{data?.actionOwner?.name} </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="transparent"
                            className="w-100 me-3 pe-3 border-0 p-0 text-dark dropWithArrow3 right-0 position-relative"
                            id="dropdown-basic"
                          >
                            Draft
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#/action-1"
                              className="gap-2 d-flex align-items-center"
                            >
                              <span className="bg-secondary opacity-50 mr-2 wh-12"></span>{" "}
                              Draft
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="gap-2 d-flex align-items-center"
                            >
                              <span className="bg-yellow mr-2 wh-12"></span> In
                              progress
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              className="gap-2 d-flex align-items-center"
                            >
                              <span className="bg-green mr-2 wh-12"></span>{" "}
                              Completed
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        <div className="short-para">
                          {data?.summary && data?.summary.length > 70
                            ? `${data.summary.slice(0, 70)}...`
                            : data.summary}
                        </div>
                      </td>
                      <td>{data?.progress}</td>
                      <td>
                        <div className="short-para">
                          {data?.comments && data?.comments.length > 70
                            ? `${data.comments.slice(0, 70)}...`
                            : data.comments}
                        </div>
                      </td>
                      <td>{moment(data?.startDate).format("DD MMM YYYY")}</td>
                      <td>
                        {moment(data?.completeDate).format("DD MMM YYYY")}
                      </td>
                      <td>{moment(data?.endDate).format("DD MMM YYYY")}</td>
                    </tr>
                  </>
                );
              })}
              {/* {pageActionData && pageActionData?.totalRecords > PAGE_LIMIT && (
                <Pagination
                data={pageActionData}
                pageNo={handlePageChange}
                pageVal={pageAction}
                />
              )} */}
            </tbody>
          </table>
              {last?.data?.action.data?.length == 0 &&
              <NoData />}
        </div>
      </div>
      <AddContractModal show={add} close={closeAdd} />
      <EditContractModal
        show={edit}
        close={closeEdit}
        editId={editId}
        edit={edit}
      />
      <MitigationModal
        show={mitiShow}
        edit={true}
        close={() => setMitiShow(false)}
        id={id}
        // id="659f7ab9dc136ed7609bfa07"
      />
    </>
  );
};

export default Contracts;
