import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import UserList from "./UserList";
import { useSelector } from "react-redux";

const ProjectManager = ({ edit = false, epPMData = "" }) => {
  const profileDate = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );

  const pmName = useSelector((state) => state?.constants?.pmName);
  const data = {
    userRole: 0,
    userType: 0,
  };


  return (
    <div className="input-group">
      <label className="cf_label mb-1" htmlFor="">EP Project Manager</label>
      <Dropdown className="w-100">
        {edit ? (
          <Dropdown.Toggle
            className="input-2 w-100 dropWithArrow2 px-3"
            id="dropdown-basic"
          >
            {epPMData?.length > 20
              ? epPMData?.slice(0, 20) + "..."
              : epPMData}
          </Dropdown.Toggle>
        ) : (
          <Dropdown.Toggle
            className="input-2 w-100 dropWithArrow2 px-3"
            id="dropdown-basic"
          >
            {profileDate?.name?.length > 20
              ? profileDate?.name?.slice(0, 20) + "..."
              : profileDate?.name}
          </Dropdown.Toggle>
        )}

        {/* <UserList data={data} dropValue={1} /> */}
      </Dropdown>
    </div>
  );
};

export default ProjectManager;
