import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import UserList from "./UserList";
import { useSelector } from "react-redux";

const ClientRiskManager = () => {
  const clientRmName = useSelector((state) => state?.constants?.clientRmName);

  const data = {
    userRole: 1,
    userType: 1,
  };
  return (
    <div className="input-group">
      <label className="cf_label mb-1" htmlFor="">Client Risk Manager</label>
      <Dropdown className="w-100">
        <Dropdown.Toggle
          className="input-2 w-100 dropWithArrow2 px-3"
          id="dropdown-basic"
        >
          {(clientRmName?.length > 20
            ? clientRmName?.slice(0, 20) + "..."
            : clientRmName) || ""}
        </Dropdown.Toggle>

        <UserList data={data} dropValue={4} />
      </Dropdown>
    </div>
  );
};

export default ClientRiskManager;
