import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.png";
import Eye from "../assets/icons/eye.svg";
import EyeClose from "../assets/icons/eye-close.svg";
import { useResetPasswordMutation } from "../services/apis";
import { useNavigate, useLocation } from "react-router-dom";
import { handleErrors } from "../utils/error";
import { PopUp } from "../utils/alert";
import Loader from "../utils/loader";

const ResetPassword = () => {
  const { state } = useLocation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passToggle, setPassToggle] = useState(false);
  const [confirmToggle, setConfirmToggle] = useState("");

  const navigate = useNavigate();
  const [resetPassword, { data, isLoading, error }] =
    useResetPasswordMutation();

  const handleForgot = () => {
    if (!password) {
      PopUp("Password is mandatory", "", "error");
      return;
    }
    if (password?.length < 6) {
      PopUp("Password must have 6 characters", "", "error");
      return;
    }
    resetPassword({
      password,
      confirm_password: confirmPassword,
      email: state?.email,
    });
  };

  useEffect(() => {
    if (data?.success) {
      PopUp("Password reset successfully", "", "success");
      navigate("/");
    }
    handleErrors(error);
  }, [data, error]);

  console.log("state data", state)

  return (
    <div className="mw-480">
      <div className="authBox bg-white rounded d-flex align-items-center flex-column">
        <div className="authHead w-100">
          <div className="wh-114 mx-auto">
            <img src={Logo} alt="Logo" className="img-contain" />
          </div>
        </div>
        <div className="authBody w-100">
          <h6 class="authTitle text-center mb-3">Reset Password</h6>

          <div class="input-group mb-3 position-relative">
            <span class="absolute-center-icon">
              {passToggle ? (
                <img
                  src={EyeClose}
                  alt="password"
                  onClick={() => setPassToggle(!passToggle)}
                />
              ) : (
                <img
                  src={Eye}
                  alt="password"
                  onClick={() => setPassToggle(!passToggle)}
                />
              )}
            </span>
            <input
              type={passToggle ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              class="form-control rounded-pill  border"
              placeholder="Enter New Password*"
            />
          </div>

          <div class="input-group mb-3 position-relative">
            <span class="absolute-center-icon">
              {confirmToggle ? (
                <img
                  src={EyeClose}
                  alt="password"
                  onClick={() => setConfirmToggle(!confirmToggle)}
                />
              ) : (
                <img
                  src={Eye}
                  alt="password"
                  onClick={() => setConfirmToggle(!confirmToggle)}
                />
              )}
            </span>
            <input
              type={confirmToggle ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              class="form-control rounded-pill  border"
              placeholder="Confirm Password*"
            />
          </div>
          <div className="input-group mt-4">
            <button
              className="btn btn-primary rounded-pill w-100 justify-content-center"
              onClick={handleForgot}
            >
              {isLoading ? <Loader /> : "Reset"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
