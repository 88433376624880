import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import UserList from "./UserList";
import { useSelector } from "react-redux";

const ClientProjectManager = () => {
  const clientPmName = useSelector((state) => state?.constants?.clientPmName);
  const data = {
    userRole: 0,
    userType: 1,
  };
  return (
    <div className="input-group">
      <label className="cf_label mb-1" htmlFor="">Client Project Manager</label>
      <Dropdown className="w-100">
        <Dropdown.Toggle
          className="input-2 w-100 dropWithArrow2 px-3"
          id="dropdown-basic"
        >
          {(clientPmName?.length > 20
            ? clientPmName?.slice(0, 20) + "..."
            : clientPmName) || ""}
        </Dropdown.Toggle>
        <UserList data={data} dropValue={3} />
      </Dropdown>
    </div>
  );
};

export default ClientProjectManager;
