import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Edit from "../assets/icons/edit.svg";
import User from "../assets/icons/dummy.svg";
import EditContractModal from "../components/modals/EditContractModal";
import { useParams } from "react-router-dom";
import ProjectList from "./ProjectList";
import {
  useAddUserProjectMutation,
  useGetContractByIdQuery,
  useGetProjectUserQuery,
} from "../services/apis";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setContractLinks } from "../services/slices/constants";
import Dropdown from "react-bootstrap/Dropdown";
import NoData from "../components/NoData";

const ViewContract = () => {
  const { id, isManager } = useParams();
  const dispatch = useDispatch();

  const { profileInfo } = useSelector((state) => state?.constants);
  const { data, refetch } = useGetContractByIdQuery(id);
  const [list, setList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [search, setSearch] = useState("");

  const [addUserProject, { data: addData, isLoading, error }] =
    useAddUserProjectMutation();

  const { data: projectList } = useGetProjectUserQuery();

  useEffect(() => {
    setList(projectList?.data);
  }, [projectList]);

  const handleCheckboxChange = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  useEffect(() => {
    refetch();
  }, []);
  const [edit, setEdit] = useState(false);
  const showEdit = () => setEdit(true);
  const closeEdit = () => setEdit(false);

  useEffect(() => {
    dispatch(
      setContractLinks({
        id: id,
        name: data?.data?.contractName,
      })
    );
  }, [data]);

  return (
    <>
      <div className="custom-border-5 row mx-0">
        <div className="col-xl-4 col-lg-12 px-0 vr-line mb-xl-0 mb-4">
          <div class="d-flex gap-2 justify-content-between flex-column">
            {/* <span class="sm-Alphabate">
              {data?.data?.contractName?.slice(0, 2).toUpperCase()}
            </span> */}
            <h6 class="fs-32 mb-3 fw-bold"  title={data?.data?.contractName}>{data?.data?.contractName && data?.data?.contractName?.length > 32 ?
              `${data?.data?.contractName?.slice(0, 32)?.toUpperCase()}...` :
              data?.data?.contractName?.toUpperCase()}</h6>
            <div class="contract-para pe-4 pt-3" title={data?.data?.description}>
              {data?.data?.description.length > 250
                ? data?.data?.description.slice(250) + "..."
                : data?.data?.description}
            </div>
            {/* <div>

              <div class="fs-12-medium-08">
                <span>{data?.data?.totalProjects} Projects</span> |{" "}
                <span>{data?.data?.totalRisks} Risks</span>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-xl col-lg-4 col-md-4 col-sm-6 px-0 d-flex flex-column justify-content-between vr-line mb-xl-0 mb-4">
          <div className="position-relative mb-xl-0 mb-md-4 mb-3">
            <div className="d-flex gap-2 align-items-center position-relative">
              {/* <img src={User} alt="User" className="userContractImage" /> */}
              <span className="userContent">
                <span className="fs-16 text-primary fw-bold">
                  EP Project Manager
                </span>
                <h6 className="fs-11 fw-bold mb-0">{data?.data?.epPM?.name}</h6>
                <h6 className="fs-11 mb-0">{data?.data?.epPM?.email}</h6>
              </span>
            </div>
          </div>

          <div className="position-relative">
            <div className="d-flex gap-2 align-items-center position-relative">
              {/* <img src={User} alt="User" className="userContractImage" /> */}
              <span className="userContent">
                <span className="fs-16 text-primary fw-bold">
                  EP Risk Manager
                </span>
                <h6 className="fs-11 fw-bold mb-0">{data?.data?.epRM?.name}</h6>
                <h6 className="fs-11 mb-0">{data?.data?.epRM?.email}</h6>
              </span>
            </div>
          </div>
        </div>
        <div className="col-xl col-lg-4 col-md-4 col-sm-6 px-0 d-flex flex-column justify-content-between mb-xl-0 mb-md-4 mb-3">
          <div className="position-relative mb-xl-0 mb-md-4 mb-3">
            <div className="d-flex gap-2 align-items-center position-relative">
              {/* <img src={User} alt="User" className="userContractImage" /> */}
              <span className="userContent">
                <span className="fs-16 text-dark fw-bold">
                  Client Project Manager
                </span>
                <h6 className="fs-11 fw-bold mb-0">
                  {data?.data?.clientPM?.name}
                </h6>
                <h6 className="fs-11 mb-0">{data?.data?.clientPM?.email}</h6>
              </span>
            </div>
          </div>

          <div className="position-relative">
            <div className="d-flex gap-2 align-items-center position-relative">
              {/* <img src={User} alt="User" className="userContractImage" /> */}
              <span className="userContent">
                <span className="fs-16 text-dark fw-bold">
                  Client Risk Manager
                </span>
                <h6 className="fs-11 fw-bold mb-0">
                  {data?.data?.clientRM?.name}
                </h6>
                <h6 className="fs-11 mb-0">{data?.data?.clientRM?.email}</h6>
              </span>
            </div>
          </div>
        </div>
        <div className="col-xl col-md-4 px-0 d-flex justify-content-end gap-2 div-text-light-dark">
          {/* <div className="d-flex gap-2 align-items-center justify-content-end position-relative"> */}
          {/* <img src={User} alt="User" className="userContractImage" /> */}
          <Dropdown className="w-11 ms-auto">
            <Dropdown.Toggle
              className="input-1 dropWithArrow3 px-0 accessGranted bg-transparent py-0 h-auto w-100"
              id="dropdown-basic"
            >
              Access granted
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100 rounded-0 p-0 shadow3 my-3 top-arrow1">
              {/* <input
                        type="search"
                        placeholder="Search user"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      /> */}
              {/* {list?.length === 0 ? (
                        <NoData />
                      ) : (
                        <div className="scroll-120 thin-scroll mt-2">
                          {!!list?.length &&
                            list?.map((item) => {
                              return ( */}
              <label
                // htmlFor={item?._id}
                className="customRadio d-flex align-items-center gap-2 py-1 scroll-label-hover"
              // key={item?._id}
              >
                <input
                  type="checkbox"
                  name="demoradio"
                  className="opacity-0"
                // id={item?._id}
                // checked={selectedItems.includes(item?._id)}
                // onChange={() =>
                //   handleCheckboxChange(item?._id)
                // }
                />
                {/* <img src={User} alt="user" /> */}
                <h6 className="mb-0 fs-11">
                  {data?.data?.epPM?.name}

                  {/* <small className="text-secondary opacity-75">
                                      {item?.userRole === 0
                                        ? "Project Manager"
                                        : item?.userRole === 1
                                        ? "Risk Manager"
                                        : "Engineer"}
                                    </small> */}
                </h6>
              </label>
              <label
                // htmlFor={item?._id}
                className="customRadio d-flex align-items-center gap-2 py-1 scroll-label-hover"
              // key={item?._id}
              >
                <input
                  type="checkbox"
                  name="demoradio"
                  className="opacity-0"
                // id={item?._id}
                // checked={selectedItems.includes(item?._id)}
                // onChange={() =>
                //   handleCheckboxChange(item?._id)
                // }
                />
                {/* <img src={User} alt="user" /> */}
                <h6 className="mb-0 fs-11">
                  {data?.data?.epRM?.name}

                  {/* <small className="text-secondary opacity-75">
                                      {item?.userRole === 0
                                        ? "Project Manager"
                                        : item?.userRole === 1
                                        ? "Risk Manager"
                                        : "Engineer"}
                                    </small> */}
                </h6>
              </label>  <label
                // htmlFor={item?._id}
                className="customRadio d-flex align-items-center gap-2 py-1 scroll-label-hover"
              // key={item?._id}
              >
                <input
                  type="checkbox"
                  name="demoradio"
                  className="opacity-0"
                // id={item?._id}
                // checked={selectedItems.includes(item?._id)}
                // onChange={() =>
                //   handleCheckboxChange(item?._id)
                // }

                />
                {/* <img src={User} alt="user" /> */}
                <h6 className="mb-0 fs-11">
                  {data?.data?.clientPM?.name}

                  {/* <small className="text-secondary opacity-75">
                                      {item?.userRole === 0
                                        ? "Project Manager"
                                        : item?.userRole === 1
                                        ? "Risk Manager"
                                        : "Engineer"}
                                    </small> */}
                </h6>
              </label>  <label
                // htmlFor={item?._id}
                className="customRadio d-flex align-items-center gap-2 py-1 scroll-label-hover"
              // key={item?._id}
              >
                <input
                  type="checkbox"
                  name="demoradio"
                  className="opacity-0"
                // id={item?._id}
                // checked={selectedItems.includes(item?._id)}
                // onChange={() =>
                //   handleCheckboxChange(item?._id)
                // }
                />
                {/* <img src={User} alt="user" /> */}
                <h6 className="mb-0 fs-11">
                  {data?.data?.clientRM?.name}

                  {/* <small className="text-secondary opacity-75">
                                      {item?.userRole === 0
                                        ? "Project Manager"
                                        : item?.userRole === 1
                                        ? "Risk Manager"
                                        : "Engineer"}
                                    </small> */}
                </h6>
              </label>
              {/* );
                            })}
                        </div>
                      )} */}
              {/* <Link className="fs-12-600-link mt-2 text-center d-block">
									+ Create new user
								</Link> */}
            </Dropdown.Menu>
          </Dropdown>
          {/* </div> */}

        </div>

        {/* {(profileInfo?.data?.userRole === 0 || JSON.parse(isManager)) && (
            <Link className="smEdit" onClick={showEdit}>
              <img src={Edit} alt="" />
            </Link>
          )} */}
      </div>

      <ProjectList isManager={isManager} />

      <EditContractModal
        show={edit}
        close={closeEdit}
        editId={data?.data?._id}
        editContract={true}
        edit={edit}
      />
    </>
  );
};

export default ViewContract;
