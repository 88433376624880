import { PopUp } from "./alert";

export const handleErrors = (error) => {
  if (error !== undefined && error?.status === 401) {
    PopUp(error?.data?.errorMessage, "", "error");
    return;
  }
  if (error !== undefined && error?.status === 402) {
    PopUp(error?.data?.errorMessage, "", "error");
    return;
  }
  if (error !== undefined && error?.status === 409) {
    PopUp(error?.data?.errorMessage, "", "error");
    return;
  }
  if (error !== undefined && error?.status === 404) {
    PopUp("Something went wrong!", error?.data?.errorMessage, "error");
    return;
  }
  if (error !== undefined && error?.status === 500) {
    PopUp("Something went wrong!", error?.data?.errorMessage, "error");
    return;
  }
  if (error !== undefined && error?.status === 400) {
    PopUp("Something went wrong", error?.data?.errorMessage, "error");
    return;
  }
};
