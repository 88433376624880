import React, { useState, useEffect } from "react";

const alphaChar = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const alphaNum = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const IdGen = ({ label, handleCall, status }) => {
  const [selectValues, setSelectValues] = useState(Array(5).fill(""));
  const [values, setValues] = useState("");
  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    // Set the initial values of the select elements to their respective data's first items
    // const newValues = [...selectValues];
    // newValues[0] = alphaChar[0];
    // newValues[1] = alphaChar[0];
    // newValues[2] = alphaChar[0];
    // newValues[3] = alphaNum[0];
    // newValues[4] = alphaNum[0];
    setSelectValues(projectId);
    setValues(projectId);
  }, [projectId]);

  // function Select({ data, index }) {
  //   return (
  //     <select
  //       style={{ border: "none", borderRadius: "2px" }}
  //       value={selectValues[index]}
  //       onChange={(e) => {
  //         const newValues = [...selectValues];
  //         newValues[index] = e.target.value;
  //         setSelectValues(newValues);
  //       }}
  //     >
  //       {data?.map((item) => (
  //         <option key={item} value={item}>
  //           {item}
  //         </option>
  //       ))}
  //     </select>
  //   );
  // }

  // const combinedString = selectValues.join("");

  useEffect(() => {
    handleCall(projectId.toUpperCase());
  }, [selectValues]);

  const handleChange = (value) => {
    // Regular expression pattern to match the desired format
    const regex = /^[A-Za-z]{0,3}[0-9]{0,2}$/; // Updated regex to disallow digits at the beginning
    if (regex.test(value) || value === '') {
        setProjectId(value.toUpperCase());
    }
};

  return (
    <div className="mb-4 col-md-4">
      {/* <label className="cf_label" htmlFor="">{label}</label> */}
      <div>
        <label className="cf_label" htmlFor="">
          EP code
        </label>{" "}
        {/* <span style={{ color: "red" }}>*</span> */}
        <input
          type="text"
          value={projectId}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          placeholder="Project ID (3 letters 2 numbers)"
          className="input-1 w-100"
          maxLength={5}
        />
      </div>

      {/* <div
        style={{
          border: "1px solid #f5f8fb",
          backgroundColor:'#f5f8fb',
          padding: ".3em 1em",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Select data={alphaChar} index={0} />
          <Select data={alphaChar} index={1} />
          <Select data={alphaChar} index={2} />
          <Select data={alphaNum} index={3} />
          <Select data={alphaNum} index={4} />
        </div>
        <p
        className="mb-0"
          style={{
            margin: "0px",
            fontSize: "12px",
            color: "#555",
            textAlign: "center",
            marginTop: "4px",
          }}
        >
          {combinedString}
        </p>
      </div> */}
      {status && (
        <p
          className="mb-0"
          style={{
            fontSize: "11px",
            marginTop: "3px 0 0 0",
            textAlign: "center",
            color: "red",
          }}
        >
          Project number is already exist
        </p>
      )}
    </div>
  );
};

export default IdGen;
