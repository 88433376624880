import React, { useEffect, useState, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  useGetRiskByIdMutation,
  useGetAllRiskListQuery,
  useUpdateRiskMutation,
  useGetRiskByIdExportMutation,
} from "../services/apis";
import Spinner from "../components/Spinner";
import moment from "moment";
import NoData from "./NoData";
import { handleErrors } from "../utils/error";
import Pencil from "../assets/icons/pencil.svg";
import { PopUp } from "../utils/alert";
import { useSelector } from "react-redux";
import { ExportToExcel } from "./ExportToExcel";
import Pagination from "./Pagination";
import { PAGE_LIMIT } from "../utils/constants";

const Risks = ({ riskOwnerIds }) => {
  const { id } = useParams();
  const tableRef = useRef(null);
  const profileId = useSelector(
    (state) => state?.constants?.profileInfo?.data?._id
  );
  const [getRiskById, { data, isLoading }] = useGetRiskByIdMutation(id);
  const [getRiskByIdExport, { data: exportRisk }] =
    useGetRiskByIdExportMutation(id);

  const { data: statusData, refetch } = useGetAllRiskListQuery();
  const [updateRisk, { data: riskData, error, isLoading: updating }] =
    useUpdateRiskMutation();
  const [statusValues, setStatusValues] = useState({
    riskId: "",
    id: "",
    riskVersion: "",
  });
  const [statusList, setStatusList] = useState([]);
  const [search, setSearch] = useState("");
  const [exportData, setExportData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({});

  const [managerId, setManagerId] = useState(false);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    statusValues?.id &&
      updateRisk({
        id: statusValues?.riskId,
        riskStatus: statusValues?.id,
        riskVersion: statusValues?.riskVersion,
      });
  }, [statusValues]);

  useEffect(() => {
    const updatedList = statusData?.data?.filter(
      (item) => item?.optionType === "riskStatus"
    );
    setStatusList(updatedList);
  }, [statusData]);

  const handleRiskId = () => {
    const objectRisk = {
      id,
      page,
      limit: PAGE_LIMIT,
    };
    if (search !== "") {
      objectRisk.search = search;
    }
    getRiskById(objectRisk);
  };

  useEffect(() => {
    getRiskByIdExport({ id, limit: 100000, page: 1 });
  }, []);

  useEffect(() => {
    handleRiskId();
  }, [id, search, page]);

  useEffect(() => {
    setPageData(data?.data);
  }, [data]);

  useEffect(() => {
    if (riskData?.success) {
      PopUp("Status updated successfully", "", "success");
      handleRiskId();
    }
    handleErrors(error);
  }, [riskData, error]);

  useEffect(() => {
    const ids = [
      riskOwnerIds?.epPM?._id,
      riskOwnerIds?.epRM?._id,
      riskOwnerIds?.clientPM?._id,
      riskOwnerIds?.clientRM?._id,
    ];

    const isManager = ids.includes(profileId);
    setManagerId(isManager);
  }, [riskOwnerIds]);

  useEffect(() => {
    const newData = exportRisk?.data?.risk?.map((item) => ({
      "Risk ID": item?.riskNumber,
      "Risk Title": item?.riskName,
      "Risk Owner": item?.riskOwner?.name,
      Status: item?.riskStatus?.name,
      "Risk Raised Date": moment(item?.riskDate).format("DD MMM YYYY"),
      "Risk Type": item?.riskType?.name,
      "Risk Cause": item?.casuse,
      "Risk Impact": item?.riskImpact,
      "Start Date": moment(item?.startDate).format("DD MMM YYYY"),
      "End Date": moment(item?.endDate).format("DD MMM YYYY"),
      "Project Phase": item?.projectPhase?.name,
      "Contractual Allocation": item?.contAllocation?.name,
    }));
    setExportData(newData);
  }, [exportRisk]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="row mt-1">
      <div className="col-md-12">
        <div className="seachBarWIthButton d-flex justify-content-end gap-3 align-items-center">
          <input
            type="search"
            placeholder="Search"
            className="rounded-pill searchBar searchOnLeft"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="d-flex gap-3">
            {/* <Link
              className="btn btn-outline-primary rounded-pill fs-14-normal px-3 h-48 align-items-center d-flex"
              to={`/risk-report/${id}`}
            >
              Save report
            </Link> */}
           
            {data?.data?.risk?.length > 0 && (
              <ExportToExcel apiData={exportData} fileName={"riskData"} />
            )}
          </div>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="table-responsive br-10 mt-1">
              {data?.data?.risk?.length === 0 ? (
                <NoData />
              ) : (
                <table
                  className="table table-style-1 mb-0"
                  id="table-to-xls"
                  ref={tableRef}
                >
                  <thead>
                    <tr>
                      <th className="py-4" width="50" style={{textAlign:"center"}}>
                        Risk ID
                      </th>
                      <th className="py-4">
                        Title
                      </th>
                      <th className="py-4">
                        Owner
                      </th>
                      <th className="py-4">Status</th>
                      <th className="py-4">
                      Recorded
                      </th>
                      <th className="py-4">Risk Type</th>
                      <th className="py-4">
                        Cause
                      </th>
                      <th className="py-4">
                        Effect
                      </th>
                      <th className="py-4">
                        Impact
                      </th>
                      <th className="py-4">
                        Start Date
                      </th>
                      <th className="py-4">
                        End Date
                      </th>
                      <th className="py-4">
                        Project Phase
                      </th>
                      <th className="py-4">Impact</th>
                      <th className="py-4">
                        Allocation
                      </th>
                      {/* <th
                        className="py-4"
                        style={{
                          position: "sticky",
                          top: "0",
                          right: "0",
                        }}
                      >
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {!!data?.data?.risk?.length &&
                      data?.data?.risk?.map((item) => {
                        return (
                          <tr key={item?._id}>
                            <td style={{textAlign:'left' }}>
                              <p style={{ margin: "0" }}> {item?.riskNumber}</p>
                            </td>

                            <td style={{textAlign:'center' }}>
                              <Link
                              className="fw-bold"
                                to={
                                  item?.isVarified || managerId
                                    ? `/edit-risk/${item?._id}/${id}/${item?._id}/false`
                                    : ""
                                }
                                style={{ color: "#e75d0f" }}
                              >
                             {item?.riskName && item?.riskName?.length > 32 ?
    `${item?.riskName?.slice(0, 32)}...` :
    item?.riskName}
                              </Link>
                            </td>

                            <td style={{textAlign:'center' }}>{item?.riskOwner?.name}</td>
                            <td style={{textAlign:'center' }}>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="transparent"
                                  className="border-0 p-0 text-dark dropWithArrow3 w-100 me-3 right-0 position-relative"
                                  id="dropdown-basic"
                                >
                                  {updating &&
                                  statusValues?.riskId === item?._id
                                    ? "Updating..."
                                    : item?.riskStatus?.name}
                                </Dropdown.Toggle>
                                {/* {(item?.isVarified || managerId) && ( */}
                                  <Dropdown.Menu>
                                    {statusList &&
                                      statusList[0]?.options?.length > 0 &&
                                      statusList[0]?.options?.map((data) => {
                                        return (
                                          <Dropdown.Item
                                            className="gap-2 d-flex align-items-center"
                                            onClick={() => {
                                              setStatusValues({
                                                riskId: item?._id,
                                                id: data?._id,
                                                riskVersion: item?.riskVersion,
                                              });
                                            }}
                                          >
                                            <span
                                              className={`${
                                                data?.name === "Open"
                                                  ? "bg-green"
                                                  : data?.name === "Draft"
                                                  ? "bg-secondary"
                                                  : data?.name === "Mitigate"
                                                  ? "bg-orange"
                                                  : data?.name === "Rejected"
                                                  ? "bg-dark-red"
                                                  : data?.name === "Transferred"
                                                  ? "bg-dark-blue"
                                                  : "bg-red"
                                              } mr-2 wh-12`}
                                            ></span>{" "}
                                            {data?.name}
                                          </Dropdown.Item>
                                        );
                                      })}
                                  </Dropdown.Menu>
                                 {/* )} */}
                              </Dropdown>
                            </td>
                            <td style={{textAlign:'center' }}>
                              {moment(item?.riskDate).format("DD MMM YYYY")}
                            </td>
                            <td style={{textAlign:'center' }}>{item?.riskType?.name}</td>
                            <td
                              title={item?.casuse}
                              style={{ cursor: "pointer",textAlign:'center' }}
                            >
                              <div className="short-para">
                              {item?.casuse?.length > 70
                                ? item?.casuse.slice(0, 70) + "..."
                                : item?.casuse}
                                </div>
                            </td>
                            <td
                              title={item?.riskEvent}
                              style={{ cursor: "pointer",textAlign:'center' }}
                            >
                              {item?.riskEvent?.length > 10
                                ? item?.riskEvent.slice(0, 10) + "..."
                                : item?.riskEvent}
                            </td>
                            <td
                              title={item?.riskImpact}
                              style={{ cursor: "pointer" }}
                            >
                              {item?.riskImpact?.length > 10
                                ? item?.riskImpact.slice(0, 10) + "..."
                                : item?.riskImpact}
                            </td>
                            <td style={{textAlign:'center' }}>
                              {moment(item?.startDate).format("DD MMM YYYY")}
                            </td>
                            <td style={{textAlign:'center' }}>
                              {moment(item?.endDate).format("DD MMM YYYY")}
                            </td>
                            <td style={{textAlign:'center' }}>{item?.projectPhase?.name}</td>
                            <td style={{textAlign:'center' }}>3</td>
                            <td style={{textAlign:'center' }}>{item?.contAllocation?.name}</td>
                            {/* <td
                              style={{
                                position: "sticky",
                                top: "0",
                                right: "0",
                              }}
                            >
                              <div>
                                {(item?.isVarified || managerId) && (
                                  <Link
                                    to={`/edit-risk/${item?._id}/${id}/${item?._id}/false`}
                                  >
                                    <img src={Pencil} alt="" />
                                  </Link>
                                )}
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
        {pageData?.totalRecords > PAGE_LIMIT && (
          <Pagination
            data={pageData}
            pageNo={handlePageChange}
            pageVal={page}
          />
        )}
    
                <Link
              className="mt-1 btn btn-dashed-dark text-uppercase fs-14-normal py-4 align-items-center d-flex flex-column gap-1"
              to={`/add-risk/${id}`}
            >
                <i
                  className="fa-solid fa-plus"
                  style={{ fontWeight: "bold" }}
                ></i>{" "}
                Add Risk

            </Link>
      </div>
    </div>
  );
};

export default Risks;
