import React from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const AddCms = () => {
  return (
    <>
      <div className="row mt-4">
        <div className="col-md-12">
          <Tabs
            defaultActiveKey="Rules"
            id="uncontrolled-tab-example"
            className="mb-3 pillsTabs"
          >
            <Tab eventKey="Rules" title="Rules">
              <div className="d-flex justify-content-end mb-2 gap-2">
                <Link className="btn btn-outline-primary rounded-pill fs-13 text-primary px-4 py-2">
                  Cancel
                </Link>
                <Link
                  className="btn btn-primary rounded-pill fs-13 text-white px-4 py-2"
                  to="/add-cms"
                >
                  Save
                </Link>
              </div>
              <div className="border-style-2">
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Heading
                  </label>
                  <input type="text" className="mt-2 input-1 w-100" value="" />
                </div>
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="mt-2 textarea-1 w-100"
                    rows="8"
                  ></textarea>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Faqs" title="Faq's">
              <div className="d-flex justify-content-end mb-2 gap-2">
                <Link className="btn btn-outline-primary rounded-pill fs-13 text-primary px-4 py-2">
                  Cancel
                </Link>
                <Link
                  className="btn btn-primary rounded-pill fs-13 text-white px-4 py-2"
                  to="/add-cms"
                >
                  Save
                </Link>
              </div>
              <div className="border-style-2">
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Heading
                  </label>
                  <input type="text" className="mt-2 input-1 w-100" value="" />
                </div>
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="mt-2 textarea-1 w-100"
                    rows="8"
                  ></textarea>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Terms" title="Term & Conditions">
              <div className="d-flex justify-content-end mb-2 gap-2">
                <Link className="btn btn-outline-primary rounded-pill fs-13 text-primary px-4 py-2">
                  Cancel
                </Link>
                <Link
                  className="btn btn-primary rounded-pill fs-13 text-white px-4 py-2"
                  to="/add-cms"
                >
                  Save
                </Link>
              </div>
              <div className="border-style-2">
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Heading
                  </label>
                  <input type="text" className="mt-2 input-1 w-100" value="" />
                </div>
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="mt-2 textarea-1 w-100"
                    rows="8"
                  ></textarea>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Legals" title="Legals">
              <div className="d-flex justify-content-end mb-2 gap-2">
                <Link className="btn btn-outline-primary rounded-pill fs-13 text-primary px-4 py-2">
                  Edit
                </Link>
                <Link
                  className="btn btn-primary rounded-pill fs-13 text-white px-4 py-2"
                  to="/add-cms"
                >
                  Add
                </Link>
              </div>
              <div className="border-style-2">
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Heading
                  </label>
                  <input type="text" className="mt-2 input-1 w-100" value="" />
                </div>
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="mt-2 textarea-1 w-100"
                    rows="8"
                  ></textarea>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Privacy" title="Privacy Policy">
              <div className="d-flex justify-content-end mb-2 gap-2">
                <Link className="btn btn-outline-primary rounded-pill fs-13 text-primary px-4 py-2">
                  Cancel
                </Link>
                <Link
                  className="btn btn-primary rounded-pill fs-13 text-white px-4 py-2"
                  to="/add-cms"
                >
                  Save
                </Link>
              </div>
              <div className="border-style-2">
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Heading
                  </label>
                  <input type="text" className="mt-2 input-1 w-100" value="" />
                </div>
                <div className="form-group mb-3">
                  <label className="w-100" htmlFor="">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="mt-2 textarea-1 w-100"
                    rows="8"
                  ></textarea>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default AddCms;
