import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useGetCmsQuery } from "../services/apis";

export const TabContent = ({ title, body }) => {
  return (
    <div className="">
      <div className="form-group mb-3">
        <h4 className="mt-5" style={{ fontWeight: "bold" }}>
          {title}
        </h4>
      </div>
      <div className="form-group mb-3">
        <p style={{ color: "#555", lineHeight: "2em" }}>{body}</p>
      </div>
    </div>
  );
};

const Cms = () => {
  const [state, setState] = useState(0);
  const { data, isFetching, refetch } = useGetCmsQuery(state);

  useEffect(() => {
    refetch();
  }, [state]);

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-12">
          <Tabs
            id="uncontrolled-tab-example"
            onSelect={(k) => setState(k)}
            activeKey={state}
            className="mb-3 pillsTabs cms-tabs"
          >
            <Tab eventKey={0} title="Faq's" default>
              {data?.data?.length > 0 &&
                data?.data?.map((item, index) => {
                  return (
                    <div className="border-style-2 mb-4 mt-4">
                      <div className="form-group">
                        <h5 style={{ fontWeight: "bold" }}>{item?.heading}</h5>
                      </div>
                      <div className="form-group">
                        <p style={{ color: "#555", lineHeight: "2em" }} class="mb-0">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </Tab>
            <Tab eventKey={1} title="Term & Conditions">
              <TabContent
                title={data?.data[0]?.heading}
                body={data?.data[0]?.description}
              />
            </Tab>
            <Tab eventKey={2} title="Legals">
              <TabContent
                title={data?.data[0]?.heading}
                body={data?.data[0]?.description}
              />
            </Tab>
            <Tab eventKey={3} title="Privacy Policy">
              <TabContent
                title={data?.data[0]?.heading}
                body={data?.data[0]?.description}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default Cms;
