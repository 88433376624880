import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EditContractModal from "../components/modals/EditContractModal";
import Risks from "../components/Risks";
import Actions from "../components/Actions";
import Users from "../components/Users";
import { useAddUserProjectMutation, useGetProjectDetailQuery, useGetProjectUserQuery, useGetProjectUsersMutation } from "../services/apis";
import { Link, useParams } from "react-router-dom";
import User from "../assets/icons/dummy.svg";
import { useDispatch } from "react-redux";
import { setProjectLinks } from "../services/slices/constants";
import Report from "../components/Report";
import Dropdown from "react-bootstrap/Dropdown";
import { PAGE_LIMIT } from "../utils/constants";
import NoData from "../components/NoData";
import { PopUp } from "../utils/alert";
import ShowMatrix from "../components/ShowMatrix";
import CreateRiskMatrix from "../components/modals/CreateRiskMatrix";
import EditIcon from "../assets/icons/Editicon.svg";

const ViewProject = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const { id } = useParams();
  const [newData, setNewData] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [riskMatrix, setRiskMatrix] = useState(false);


  const { data, refetch } = useGetProjectDetailQuery(id);
  const [getProjectUsers, { data: projectUsersData }] = useGetProjectUsersMutation();
  const { data: projectList, refetch: listRefetch } = useGetProjectUserQuery();
  const [refresh, setRefresh] = useState(false);
 console.log('dataaaasa',data)
  const [list, setList] = useState([]);
  const showRiskMatrix = () => setRiskMatrix(true);
  const closeRiskMatrix = () => setRiskMatrix(false);
  const [addUserProject, { data: addData, isLoading, error }] =
    useAddUserProjectMutation();

  useEffect(() => {
    listRefetch();
  }, []);

  useEffect(() => {
    const updatedData = projectList?.data?.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );

    setList(updatedData);
  }, [search]);

  useEffect(() => {
    setList(projectList?.data);
  }, [projectList]);



  useEffect(() => {
    const obj = {
      id,
      limit: PAGE_LIMIT,
      page,
    };
    if (search !== "") {
      obj.search = search;
    }
    getProjectUsers(obj);
  }, [page, refresh]);


  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    setNewData(data?.data?.result[0]);
  }, [data]);

  useEffect(() => {
    dispatch(
      setProjectLinks({
        id,
        name: newData?.projectName,
      })
    );
  }, [newData]);

  const closeEdit = () => setEdit(false);

  const handleCheckboxChange = (userId) => {
    addUserProject({
      userIds: [userId],
      projectId: id,
    });

    setRefresh(!refresh)


  };



  useEffect(() => {
    if (addData?.success) {
      PopUp("Users added successfuly", "", "success");

    }
  }, [addData]);

  console.log("getProjectUsers", list)



  return (
    <>
      <div className="custom-border-5 row mx-0">
        <div className="col-xxl-4 col-xl-12 col-lg-12 px-0 vr-line mb-xxl-0 mb-4">
          <div class="d-flex gap-2 align-items-center position-relative">
            {/* <span class="sm-Alphabate">
              {newData?.projectName?.slice(0, 2).toUpperCase()}
            </span> */}
            <div>
              <h6 class="fs-32 mb-3 fw-bold text-light-dark" title={newData?.projectName}>
                {newData?.projectName && newData?.projectName?.length > 32 ?
                  `${newData?.projectName?.slice(0, 32)?.toUpperCase()}...` :
                  newData?.projectName?.toUpperCase()}
              </h6>
              <div class="contract-para pe-4 pt-3" title={newData?.description}>
                {newData?.description && newData?.description?.length > 250
                  ? newData?.description?.slice(0, 250) + "..."
                  : newData?.description}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl col-lg-4 col-md-4 col-sm-6 px-0 d-flex flex-column justify-content-between vr-line mb-xl-0 mb-4">
          {renderUserInformation("EP Project Manager", newData?.epPM)}
          {renderUserInformation("EP Risk Manager", newData?.epRM)}
        </div>
        <div className="col-xxl col-lg-4 col-md-4 col-sm-6 px-0 d-flex flex-column justify-content-between mb-xl-0 mb-md-4 mb-3">
         <div className="outer-div-text-gray">
         {renderUserInformation("Client Project Manager", newData?.clientPM)}
         </div>
          <div className="outer-div-text-gray">
          {renderUserInformation("Client Risk Manager", newData?.clientRM)}
            </div>        
        </div>
        <div className="col-xl col-md-4 px-0 d-flex justify-content-end gap-2 div-text-light-dark flex-lg-row flex-column">
         <div className="d-flex gap-2 flex-column">
         <Dropdown className="w-11 ms-md-auto">
            <Dropdown.Toggle
              className="input-1 dropWithArrow3 px-0 accessGranted bg-transparent py-0 h-auto w-100"
              id="dropdown-basic"
            >
              Access granted
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100 rounded-0 p-0 shadow3 mt-3 top-arrow1">
              <div className="bg-light-gray p-2">
                <input
                  type="search"
                  placeholder="Search user"
                  className="searchRight"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {projectList?.data?.length === 0 ? (
                <NoData />
              ) : (
                <div className="scroll-120 p-0">
                  {!!list?.length &&
                    list?.map((item) => {
                      const isIdInUsersData = Array.isArray(projectUsersData?.data?.data) &&
                        projectUsersData.data.data.some(userData => userData._id === item._id);
                      return (
                        <label
                          htmlFor={item?._id}
                          className="customRadio d-flex align-items-center gap-2 scroll-label-hover mb-0 py-1"
                          key={item?._id}
                        >
                          <input
                            type="checkbox"
                            name="demoradio"
                            id={item?._id}
                            checked={isIdInUsersData}
                            onChange={() => handleCheckboxChange(item?._id)}
                            className="opacity-0"
                          />
                          {/* <img src={User} alt="user" /> */}
                          <h6 className="mb-0 fs-11">
                            {item.name}
                          </h6>
                        </label>

                      );
                    })}
                </div>
              )}

            </Dropdown.Menu>
          </Dropdown>

           <Link to={`/edit-project/${newData?._id}/${newData?.contractId?._id}/${newData?.projectVersion}`}
            className="fs-16 text-yellow-dark ms-md-auto mt-auto mb-2">Edit Project <img src={EditIcon} alt="" width="12" height="12" className="align-middle" /></Link>         
         </div>


          <div className="wh-small">


            <div onClick={showRiskMatrix} className="position-relative flex1 ps-4 sm-matrix-project">
              <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center">

                <p
                  className="m-0 top-arrow" style={{ fontSize: '12px' }} role="button"
                >
                  Impact
                </p>
              </div>
              <ShowMatrix data={newData?.riskMatrixId?.matrix} matrixDesc={newData?.riskMatrixId?.colorDescription} status="viewProject" />
              <div className="position-absolute start-0 end-0 m-0 ps-4 flex1">
                <p
                  className="text-center m-0 right-arrow" style={{ fontSize: '12px' }} role="button"
                >
                  Likelihood
                </p>

              </div>
            </div>


          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 position-relative">
          <Tabs
            defaultActiveKey="Risks"
            id="uncontrolled-tab-example"
            className="pillsTabs position-absolute-required"
          >
            <Tab eventKey="Risks" title="RISKS">
              <Risks riskOwnerIds={newData} />
            </Tab>
            <Tab eventKey="Actions" title="MITIGATIONS">
              <Actions />
            </Tab>
            <Tab eventKey="Reports" title="REPORTS">
              <Report />
            </Tab>
          </Tabs>
        </div>
      </div>

      <EditContractModal show={edit} close={closeEdit} />
      <CreateRiskMatrix
        show={riskMatrix}
        close={closeRiskMatrix}
        dataList={newData?.riskMatrixId}
        rangeData={''}
        disabled={true}
      />
    </>
  );
};

function renderUserInformation(role, user) {
  return (
    <div className="position-relative mb-xl-0 mb-md-4 mb-3">
      <div className="d-flex gap-2 align-items-center position-relative">
        {/* <img src={User} alt="User" className="userContractImage" /> */}
        <span className="userContent">
          <span className="fs-16 text-primary fw-bold role">{role}</span>
          <h6 className="fs-11 fw-bold mb-0">{user?.name}</h6>
          <h6 className="fs-11 mb-0">{user?.email}</h6>
        </span>
      </div>
    </div>
  );
}

export default ViewProject;
