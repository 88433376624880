import Modal from 'react-bootstrap/Modal';
import Send from '../../assets/icons/send.svg';

function InvitationModal({show,close}) {
  return (
    <>
      <Modal show={show} size="sm" dialogClassName="modal-rounded" centered onHide={close}>
        <Modal.Body className='text-center'>
            <div className="wh-48 rounded-circle mx-auto">
                <img src={Send} alt="" className="img-contain" />
            </div>
            <h6 className='authTitle text-center mt-4 mb-3'>Invitation Sent</h6>
            <p class="fs-13 mb-0">Dummy text dolor sit amet, consetetur sadipscing elitr, sed diam</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InvitationModal;