import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";
import { useRiskOwnerChartMutation } from "../services/apis";

const LineChartExpo = ({ status }) => {
  const chartOptions = {
    chart: {
      id: "line-chart",
    },
    xaxis: {
      categories: [],
    },
  };

  const chartSeries = [
    {
      name: "Value",
      data: [],
    },
  ];

  const { id } = useParams();

  const [chart, setChart] = useState(chartOptions);
  const [series, setSeries] = useState(chartSeries);

  const [riskOwnerChart, { data: info }] = useRiskOwnerChartMutation();
  const [data, setData] = useState([]);

  useEffect(() => {
    riskOwnerChart({
      id: id,
      assessmentType: status,
    });
  }, [status]);

  useEffect(() => {
    const newArray =
      info?.data?.length > 0 &&
      info?.data.map((item) => {
        return {
          name: item.userDetail[0]?.name,
          data: item?.totalCostScoreRiskOwner,
        };
      });

    if (newArray) {
      const names = [];
      const dataValues = [];

      newArray.forEach((item) => {
        names.push(item.name);
        dataValues.push(item.data);
      });

      const resultObject = {
        name: names,
        data: dataValues,
      };

   

      setData(resultObject);
    }
  }, [info]);

  useEffect(() => {
    setSeries([
      {
        name: "Value",
        data: data?.data,
      },
    ]);

    setChart({
      chart: {
        id: "line-chart",
      },
      xaxis: {
        categories: data?.name,
      },
    });
  }, [data]);


  return (
    <div className="line-chart">
      <ReactApexChart
        options={chart}
        series={series}
        type="line"
        height={450}
      />
    </div>
  );
};

export default LineChartExpo;
