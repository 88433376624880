import Modal from "react-bootstrap/Modal";
function CreateRiskManager({ show, close }) {
  return (
    <>
      <Modal
        show={show}
        size="lg"
        dialogClassName="modal-rounded modal-350"
        centered
        onHide={close}
      >
        <Modal.Header
          className="text-center border-0 justify-content-center position-relative abolute-close modal-header flex-column"
          closeButton
        >
          <Modal.Title className="fs-21-600">Create Risk Manager</Modal.Title>
          <h6 className="fs-13 mt-2 px-md-4">
            An invitation link will be sent to the user and they can login using
            the credential
          </h6>
        </Modal.Header>
        <Modal.Body className="text-center pt-1">
          <div className="input-group mb-3">
            <input
              type="text"
              name=""
              id=""
              placeholder="Enter full name"
              className="input-1 w-100"
            />
          </div>
          <div className="input-group mb-4">
            <input
              type="text"
              name=""
              id=""
              placeholder="Enter Email"
              className="input-1 w-100"
            />
          </div>
          <div className="input-group">
            <button className="btn btn-primary rounded-pill h-55 px-5 fs-16 w-100">
              Send Invite
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateRiskManager;
