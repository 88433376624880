import React, { useState, useEffect } from "react";
const ShowMatrixReport = ({ data }) => {
  const [matrixData, setMatrixData] = useState([]);
  useEffect(() => {
    setMatrixData(data);
  }, [data]);


  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        width: "100%",
        height: "100%",
        margin: "auto",
        aspectRatio:2 / 2
      }}
    >
      {matrixData?.length > 0 &&
        matrixData?.map((box) => (
          <div
            key={box.cellId}
            className={` bg-${box.color} box `}
            style={{
              border: "0.001em solid white",
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: "22px",
                margin: "0",
                color:
                  box.color !== "red" && box.color !== "green"
                    ? "black"
                    : "white",
              }}
            >
              {box?.count}
            </p>
          </div>
        ))}
    </div>
  );
};
export default ShowMatrixReport;
