import React, { useState, useEffect, useRef } from "react";
import { useQueryByIdQuery, useAddReplyMutation } from "../services/apis";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const ViewTicket = () => {
  const { id } = useParams();
  const profileId = useSelector((state) => state?.constants?.profileInfo?.data);
  const [reply, setReply] = useState("");

  const messageListRef = useRef(null);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const { data, refetch } = useQueryByIdQuery(id, {
    pollingInterval: 10000,
  });

  const [addReply, { data: replyData }] = useAddReplyMutation();

  const handleReply = () => {
    if (!reply.trim()) {
      return;
    }
    addReply({
      replyTo: data?.data[0]?.userId?._id,
      queryID: id,
      reply,
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  useEffect(() => {
    if (replyData?.success) {
      refetch();
      setReply("");
    }
  }, [replyData]);

  useEffect(() => {
    refetch();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleReply();
    }
  };

  return (
    <>
      <h4 className="mb-4" style={{ fontSize: "16px", fontWeight: "bold" }}>
        Query: {data?.data[0]?.query}
      </h4>
      <div className="main">
        {data?.data[0]?.replyIds?.length === 0 ? (
          <div
            style={{
              display: "grid",
              placeItems: "center",
              height: "72vh",
            }}
          >
            <p style={{ fontSize: "16px", color: "#e75d0f" }}>
              No Chats Available
            </p>
          </div>
        ) : (
          <div className="px-2 scroll" ref={messageListRef}>
            {data?.data[0]?.replyIds?.map((item) => (
              <div
                className={`d-flex align-items-center ${
                  item?.replyBy?._id === profileId?._id
                    ? "text-right justify-content-end"
                    : ""
                }`}
                key={item?._id}
              >
                <div
                  className={
                    item?.replyBy?._id === profileId?._id
                      ? "pr-2"
                      : "text-left pr-1"
                  }
                ></div>
                <div className="pr-2 pl-3 ml-5">
                  <span className="name">
                    {moment(item?.replyBy?.replyDate).format("DD MMM YYYY")} |{" "}
                    {moment(item?.replyBy?.replyDate).format("hh:mm")}
                  </span>
                  <p
                    className="msg"
                    style={{
                      background:
                        item?.replyBy?._id === profileId?._id ? "#e75d0f" : "",
                      color:
                        item?.replyBy?._id === profileId?._id ? "#fff" : "",
                    }}
                  >
                    {item?.reply}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        <nav className="bg-white navbar-expand-sm d-flex justify-content-between py-2">
          <input
            type="text"
            name="text"
            className="input-1 w-100 form-control"
            placeholder="Type a message..."
            value={reply}
            onKeyPress={handleKeyPress}
            onChange={(e) => setReply(e.target.value)}
          />
          <button className="btn btn-primary mx-3" onClick={handleReply}>
            <i className="fa-solid fa-paper-plane"></i>
          </button>
          <button className="btn btn-primary mr-1" onClick={refetch}>
            <i className="fa-solid fa-arrows-rotate"></i>
          </button>
        </nav>
      </div>
    </>
  );
};

export default ViewTicket;
