import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import User from "../../assets/icons/ProfileUser.svg";
import { Link } from "react-router-dom";
import { useGetContractUsersMutation } from "../../services/apis";
import NoData from "../NoData";
import { useDispatch } from "react-redux";
import {
  setClientPmId,
  setPmId,
  setRmId,
  setClientRmId,
  setPmName,
  setRmName,
  setClientRmName,
  setClientPmName,
} from "../../services/slices/constants";
import CreateProjectManagerModal from "../modals/CreateProjectManagerModal";
import { useSelector } from "react-redux";

const UserList = ({ data, dropValue }) => {
  const dispatch = useDispatch();

  const pmId = useSelector((state) => state?.constants?.pmId);
  const rmId = useSelector((state) => state?.constants?.rmId);
  const clientPmId = useSelector((state) => state?.constants?.clientPmId);
  const clientRmId = useSelector((state) => state?.constants?.clientRmId);

  const [userList, setUserList] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedName, setSelectedName] = useState("");

  const [showUser, setShowUser] = useState(false);

  const [getUsers, { data: userData }] =
    useGetContractUsersMutation();

  useEffect(() => {
    const obj = {
      userRole: data?.userRole,
      userType: data?.userType,
    };
    if (search !== "") {
      obj.search = search;
    }
    getUsers(obj);
  }, [showUser]);

  useEffect(() => {
    setUserList(userData?.data);
  }, [userData]);

  const searchList = () => {
    const filteredList =
      userData?.data &&
      userData?.data.filter((item) =>
        item?.name.toLowerCase().includes(search.toLowerCase())
      );
    setUserList(filteredList);
  };

  useEffect(() => {
    searchList();
  }, [search]);

  const handleChange = (e, name) => {
    setSelectedId(e);
    setSelectedName(name);
  };

  useEffect(() => {
    if (dropValue === 1) {
      dispatch(setPmId(selectedId));
      dispatch(setPmName(selectedName));
    }
    if (dropValue === 2) {
      dispatch(setRmId(selectedId));
      dispatch(setRmName(selectedName));
    }
    if (dropValue === 3) {
      dispatch(setClientPmId(selectedId));
      dispatch(setClientPmName(selectedName));
    }
    if (dropValue === 4) {
      dispatch(setClientRmId(selectedId));
      dispatch(setClientRmName(selectedName));
    }
  }, [selectedId]);

  return (
    <>
      <Dropdown.Menu className="w-100 br-14 py-2 px-3">
        <input
          type="search"
          placeholder="Search user"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {userList?.length === 0 ? (
          <NoData />
        ) : (
          <div className="scroll-120 thin-scroll mt-2">
            {!!userList?.length &&
              userList?.map((item) => (
                <Dropdown.Item
                  as="button"
                  key={item?._id}
                  onClick={(e) => handleChange(item?._id, item?.name)}
                >
                  <label
                    htmlFor={item?._id}
                    className="customRadio d-flex align-items-center gap-2"
                  >
                    <input
                      type="radio"
                      name="demoradio"
                      value={item?._id}
                      id={item?._id}
                    />
                    <img src={User} alt="user" />
                    <h6
                      className="mb-0"
                      style={{
                        color:
                          dropValue === 1
                            ? item?._id === pmId
                              ? "#E75D0F"
                              : ""
                            : dropValue === 2
                            ? item?._id === rmId
                              ? "#E75D0F"
                              : ""
                            : dropValue === 3
                            ? item?._id === clientPmId
                              ? "#E75D0F"
                              : ""
                            : dropValue === 4
                            ? item?._id === clientRmId
                              ? "#E75D0F"
                              : ""
                            : "", // Add more conditions if needed
                      }}
                    >
                      {item?.name}
                    </h6>
                  </label>
                </Dropdown.Item>
              ))}
          </div>
        )}
        <Link
          className="fs-12-600-link mt-2 text-center d-block"
          onClick={() => setShowUser(true)}
        >
          + Create new user
        </Link>
      </Dropdown.Menu>
      <CreateProjectManagerModal
        show={showUser}
        close={() => setShowUser(false)}
        data={data}
      />
    </>
  );
};

export default UserList;
