import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import ChartOne from "./ChartOne";
import { usePDF } from "react-to-pdf";
import ShowMatrixReport from "./ShowMatrixReport";
import { useRiskMatrixStatusReportQuery } from "../services/apis";
import { useParams } from "react-router-dom";
import SixMonthChart from "./SixMonthChart";
import ChartWithBarWidth from "./ChartThree";
import ChartTwo from "./ChartTwo";
import TimeLineRisk from "./TimeLineRisk";
import PiChart from "./PiChart";
import PiChartRiskType from "./PieChartRiskType";
import RegisterTable from "./RegisterTable";
import HeaderForPDF from "./HeaderForPDF";
import FooterForPDF from "./FooterForPDF";

const Report = () => {
  const { toPDF, targetRef } = usePDF({
    filename: `matrixReport.pdf`,
  });
  const { id } = useParams();
  const [unMitData, setUnMitData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [mitData, setMitData] = useState([]);
  const { data, refetch } = useRiskMatrixStatusReportQuery(id);
  const[refreshPage, setRefreshPage] = useState(false)

  const findCount = (arr) => {
    const firstArray = data?.data?.RiskMatrix?.matrix;
    const secondArray = arr?.map((item) => ({
      xAxis: item?.riskMatrixValue.yAxis,
      yAxis: item?.riskMatrixValue.xAxis,
      counts: item?.count,
    }));
    const secondArrayMap = {};
    secondArray?.forEach((item) => {
      const key = `${item.xAxis}-${item.yAxis}`;
      secondArrayMap[key] = item.counts;
    });
    const updatedFirstArray = firstArray?.map((item) => {
      const key = `${item.xAxis}-${item.yAxis}`;
      const count = secondArrayMap[key] || 0;
      return { ...item, count };
    });

    return updatedFirstArray;
  };

  useEffect(() => {
    const unmitInfo = findCount(data?.data?.UnMitigated);
    setUnMitData(unmitInfo);
    const currentInfo = findCount(data?.data?.Current);
    setCurrentData(currentInfo);
    const miInfo = findCount(data?.data?.Mitigated);
    setMitData(miInfo);
  }, [data]);

  const handleExportToPDF = () => {
    setRefreshPage(true); // Set refreshPage to true
  };
  useEffect(() => {
    // Call toPDF when refreshPage is true
    if (refreshPage) {
      toPDF();
      setRefreshPage(false); // Reset refreshPage to false after calling toPDF
    }
  }, [refreshPage, toPDF]);

  return (
    <div className="row mt-1 pt-2">
      <div className="col-md-12">
        <div className="seachBarWIthButton d-flex justify-content-end gap-3 align-items-center">
          {/* <input
            type="search"
            placeholder="Search"
            className="rounded-pill searchBar searchOnLeft"
            // value={search}
            // onChange={(e) => setSearch(e.target.value)}
          /> */}
        </div>
        <div className="col-md-12">
          <Tab.Container id="left-tabs-example" defaultActiveKey="">
            <Row>
              <Col sm={12}>
                <div className="bg-dark-orange py-4 br-6">&nbsp;</div>
              </Col>
              <Col sm={12}>
                <Nav variant="pills" className="flex-5-fill bg-even-odd">
                  <Nav.Item>
                    <Nav.Link className="bg-tab-1" eventKey="first">
                      Top-10 Risks Analysis
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-tab-2" eventKey="second">
                      Risk Positioning Analysis
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-tab-2" eventKey="third">
                      Six-Month Rolling Trend Analysis
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-tab-1" eventKey="fourth">
                      Risk Status Analysis
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-tab-1" eventKey="fifth">
                      Risk Analysis by Owner, Risk Level, and Cost Exposure{" "}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-tab-2" eventKey="sixth">
                      Risk Proximity Analysis
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-tab-2" eventKey="seventh">
                      Risk Level Analysis
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-tab-1" eventKey="eight">
                      Risk Category Analysis
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-tab-1 mt-md-2" eventKey="nine">
                      Summary
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content className="p-3">
                  <Tab.Pane eventKey="first">
                    {" "}
                    <ChartOne />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <button
                      className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex"
                      onClick={() => handleExportToPDF()}
                    >
                      PDF Download
                    </button>
                    <div
                      className=" px-5 mt-4"
                      ref={targetRef}
                    >
               {refreshPage ?  <HeaderForPDF /> :""}  
                      
                      <div
                        style={{
                          display: "flex",
                          gap: "2em",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                        className="mt-2 mb-3"
                      >
                        <div className="">
                          <h5
                            className="mb-4 px-3"
                            style={{ fontWeight: "bold" }}
                          >
                            UnMitigated
                          </h5>
                          <div
                            className="ms-auto mt-2"
                            style={{ width: "250px" }}
                          >
                            <div className="position-relative flex1 ps-4">
                              <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center">
                                <p
                                  className="m-0 top-arrow"
                                  style={{ fontSize: "12px" }}
                                  role="button"
                                >
                                  Impact
                                </p>
                              </div>
                              <ShowMatrixReport data={unMitData} />
                              <div className="position-absolute start-0 end-0 m-0 ps-4 flex1">
                                <p
                                  className="text-center m-0 right-arrow"
                                  style={{ fontSize: "12px" }}
                                  role="button"
                                >
                                  Likelihood
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <h5
                            className="mb-4 px-3"
                            style={{ fontWeight: "bold" }}
                          >
                            Current
                          </h5>
                          <div
                            className="ms-auto mt-2"
                            style={{ width: "250px" }}
                          >
                            <div className="position-relative flex1 ps-4">
                              <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center">
                                <p
                                  className="m-0 top-arrow"
                                  style={{ fontSize: "12px" }}
                                  role="button"
                                >
                                  Impact
                                </p>
                              </div>
                              <ShowMatrixReport data={currentData} />
                              <div className="position-absolute start-0 end-0 m-0 ps-4 flex1">
                                <p
                                  className="text-center m-0 right-arrow"
                                  style={{ fontSize: "12px" }}
                                  role="button"
                                >
                                  Likelihood
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <h5
                            className="mb-4 px-3"
                            style={{ fontWeight: "bold" }}
                          >
                            Mitigated
                          </h5>
                          <div
                            className="ms-auto mt-2"
                            style={{ width: "250px" }}
                          >
                            <div className="position-relative flex1 ps-4">
                              <div className="rotateText position-absolute start-0 m-0 top-0 bottom-0 text-center">
                                <p
                                  className="m-0 top-arrow"
                                  style={{ fontSize: "12px" }}
                                  role="button"
                                >
                                  Impact
                                </p>
                              </div>
                              <ShowMatrixReport data={mitData} />
                              <div className="position-absolute start-0 end-0 m-0 ps-4 flex1">
                                <p
                                  className="text-center m-0 right-arrow"
                                  style={{ fontSize: "12px" }}
                                  role="button"
                                >
                                  Likelihood
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                {refreshPage ?  <FooterForPDF/> :"" }

                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <SixMonthChart
                      list={data?.data?.RiskMatrix?.colorDescription}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    {" "}
                    <ChartWithBarWidth />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <ChartTwo list={data?.data?.RiskMatrix?.colorDescription} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth">
                    {" "}
                    <TimeLineRisk />
                  </Tab.Pane>
                  <Tab.Pane eventKey="seventh">
                    <PiChart list={data?.data?.RiskMatrix?.colorDescription} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="eight">
                    <PiChartRiskType
                      list={data?.data?.RiskMatrix?.colorDescription}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="nine">
                    {" "}
                    <RegisterTable />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default Report;
