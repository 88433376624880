import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useTypeReportQuery } from "../services/apis";
import { useParams } from "react-router-dom";
import NoData from "./NoData";
import { usePDF } from "react-to-pdf";
import HeaderForPDF from "./HeaderForPDF";
import FooterForPDF from "./FooterForPDF";

const PiChartRiskType = ({ list }) => {
  const { toPDF, targetRef } = usePDF({
    filename: `riskTypeStatus.pdf`,
  });
  const { id } = useParams();
  const { data, refetch } = useTypeReportQuery(id);

  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    labels: [],
    colors: [],
  });
  const [refreshPage, setRefreshPage] = useState(false)


  useEffect(() => {
    let labels = data?.data?.length > 0 && data?.data?.map((item) => item.name);

    setChartOptions({
      labels,
      colors: [],
    });
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const newValue =
      data?.data?.length > 0 && data?.data?.map((item) => item?.count);
    if (newValue) {
      setChartSeries(newValue);
    } else {
      setChartSeries([]);
    }
  }, [data, list]);

  const handleExportToPDF = () => {
    setRefreshPage(true); // Set refreshPage to true
  };
  useEffect(() => {
    // Call toPDF when refreshPage is true
    if (refreshPage) {
      toPDF();
      setRefreshPage(false); // Reset refreshPage to false after calling toPDF
    }
  }, [refreshPage, toPDF]);

  return (
    <div>
      <button className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex" onClick={() => handleExportToPDF()}>
      PDF Download
      </button>
      {chartSeries?.every((item) => item === 0) ? (
        <NoData />
      ) : (
        <div className="px-5 pt-4" ref={targetRef}>
               {refreshPage ?  <HeaderForPDF /> :""}  

          <ApexCharts
            options={chartOptions}
            series={chartSeries}
            type="pie"
            width="480"
          />
                {refreshPage ?  <FooterForPDF margin="96%"/> :"" }

        </div>
      )}
    </div>
  );
};

export default PiChartRiskType;
