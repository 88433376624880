import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useRiskAssessmentStatusReportMutation } from "../services/apis";
import { useParams } from "react-router-dom";
import NoData from "./NoData";
import { usePDF } from "react-to-pdf";
import HeaderForPDF from "./HeaderForPDF";
import FooterForPDF from "./FooterForPDF";

const PiChart = ({ list }) => {
  const { toPDF, targetRef } = usePDF({
    filename: `riskAssessment.pdf`,
  });
  const { id } = useParams();
  const [riskAssessmentStatusReport, { data }] =
    useRiskAssessmentStatusReportMutation();

  const [chartSeries, setChartSeries] = useState([]);
  const [status, setStatus] = useState("UnMitigated");
  const [chartOptions, setChartOptions] = useState({
    labels: [],
    colors: [],
  });
  const [refreshPage, setRefreshPage] = useState(false)



  useEffect(() => {
    let colors =
      list?.length > 0 &&
      list?.map((item) => {
        switch (item.color) {
          case "light-green":
            return "#00bf63";
          case "yellow":
            return "#f1cb02";
          case "amber":
            return "#e75d0f";
          case "red":
            return "#b00126";
          case "green":
            return "#3a7557";
          default:
            return item.color;
        }
      });

    let labels = list?.length > 0 && list?.map((item) => item.description);

    setChartOptions({
      labels,
      colors,
    });
  }, [list]);

  useEffect(() => {
    riskAssessmentStatusReport({
      id,
      assessmentType: status,
    });
  }, [status]);

  useEffect(() => {
    const newValue =
      data?.data?.length > 0 && data?.data?.map((item) => item?.colorCounts);
      console.log('gfxhfg',newValue);
    if (newValue) {
      setChartSeries(newValue);
    } else {
      setChartSeries([]);
    }
  }, [data]);

  const handleExportToPDF = () => {
    setRefreshPage(true); // Set refreshPage to true
  };
  useEffect(() => {
    // Call toPDF when refreshPage is true
    if (refreshPage) {
      toPDF();
      setRefreshPage(false); // Reset refreshPage to false after calling toPDF
    }
  }, [refreshPage, toPDF]);

  return (
    <div>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <select
          name=""
          id=""
          onChange={(e) => setStatus(e.target.value)}
          style={{ padding: "1em", border: "none", focus: "none" }}
        >
          <option value="UnMitigated">Unmitigated</option>
          <option value="Current">Current</option>

          <option value="Mitigated">Mitigated</option>
        </select>
        <button className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex" onClick={() => handleExportToPDF()}>
        PDF Download
        </button>
      </div>
      {chartSeries?.every((item) => item === 0) ? (
        <NoData />
      ) : (
        <div ref={targetRef} className="px-5 pt-4">
          {" "}
          {refreshPage ?  <HeaderForPDF /> :""}  

          <ApexCharts
            options={chartOptions}
            series={chartSeries}
            type="pie"
            width="480"
          />
                {refreshPage ?  <FooterForPDF margin="96%"/> :"" }

        </div>
      )}
    </div>
  );
};

export default PiChart;
