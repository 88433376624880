import React, { useRef, useEffect, useState } from "react";
import { usePDF, Resolution } from "react-to-pdf";
import { useRiskProfileReportQuery } from "../services/apis";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import ShowRiskMatrix from "../components/ShowRiskMatrix";
import HeaderForPDF from "../components/HeaderForPDF";
import FooterForPDF from "../components/FooterForPDF";
import LOGO from "../assets/images/EPRisk-logo.png";
import {
  ComposedChart,
  Bar,
  LabelList,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import NoData from "../components/NoData";
import Breadcrumbs from "../components/Breadcrumbs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
const colOne = {
  fontSize: "15px",
  fontWeight: "bold",
  fontFamily: "Arial"
};

const colTwo = {
  fontSize: "15px",
  width: "80%",
  textAlign: "left",
  overflowX: "auto",
  whiteSpace: "wrap",
  fontFamily: "Arial"
};

const fontStyle = {
  fontFamily: "Arial"
}

const RiskDetail = () => {
  const textRef = useRef(null);
  const { id } = useParams();
  const { data, refetch } = useRiskProfileReportQuery(id);
  const { toPDF, targetRef } = usePDF({
    filename: `risk-${data?.data?.riskNumber}.pdf`,
  });

  const [list, setList] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [rollingRechart, setRollingRechart] = useState("");
  const [pdfDownloadButton, setPdfDownloadButton] = useState(false);
  const currentDate = new Date().toLocaleDateString();
  const currentTime = new Date().toLocaleTimeString();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const newArray =
      data?.data?.riskAsmtCurrentAll?.length > 0 &&
      data?.data?.riskAsmtCurrentAll?.map((item) => {
        return {
          name: moment(item?.updatedAt).format("MMM YYYY"),
          "Likelihood Score": Number(item?.costScore),
          "Impact Score": Number(item?.probabilityValue),
          exposure: item?.costExpo,
        };
      });
    setList(newArray);

    // setTimeout(async () => {
    //   const getChart = document.getElementById("rollingRechart");
    //   if (getChart) {
    //     await html2canvas(getChart, {
    //       scale: 3,
    //       useCORS: true,
    //     }).then((canvas) => {
    //       const imgData = canvas.toDataURL("image/png");
    //       console.log(imgData, "imgData");
    //       setRollingRechart(imgData);
    //       getChart.style.display = "none";
    //       setPdfDownloadButton(false)
    //     });
    //   }
    // }, 1500);
  }, [data]);

  const handleExportToPDF = () => {
    setRefreshPage(true); // Set refreshPage to true
  };
  useEffect(() => {
    // Call toPDF when refreshPage is true
    if (refreshPage) {
      toPDF();
      setRefreshPage(false); // Reset refreshPage to false after calling toPDF
    }
  }, [refreshPage, toPDF]);
  const profileInfo = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );

  const generatePDF = async () => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: [300, 300],
      compress: true,
    });
    document.getElementById("footer").style.display = "block";
    var element = document.getElementById("header");
    element.classList.remove("headerForRiskDetails");

    const content = document.getElementById("content");
    html2canvas(content, {
      scale: 3,
      useCORS: true,
    })
      .then(async (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdfWidth = doc.internal.pageSize.getWidth();
        let pdfHeight = doc.internal.pageSize.getHeight();
        pdfHeight = pdfHeight - 20;
        if (window.outerWidth < 1280) {
          console.log(window.outerWidth, window.outerHeight, "Not 13 inch");
          doc.addImage(imgData, "PNG", 35, 10, 230, 270);
        } else {
          doc.addImage(imgData, "PNG", 0, 5, pdfWidth, pdfHeight);
        }

        //Second page
        doc.addPage();
        // const rollingTrend = document.getElementById('rollingTrend');
        // if(rollingTrend){
        //   rollingTrend.style.width = "80%"
        // }
        const context1 = document.getElementById("context1");
        await html2canvas(context1, {
          scale: 3,
          // windowWidth: context1.offsetWidth,
          // windowHeight: context1.offsetHeight,
          useCORS: true,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          let height =
            context1.offsetHeight * (pdfWidth / context1.offsetWidth);
          console.log("pdfWidth", pdfWidth, height);
          // doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, height);
          if (window.outerWidth < 1280) {
            console.log(window.outerWidth, window.outerHeight, "Not 13 inch");
            doc.addImage(imgData, "PNG", 35, 0, 230, 200);
          } else {
            doc.addImage(imgData, "PNG", 0, 0, pdfWidth, height);
          }
        });
        // if(rollingTrend){
        //   rollingTrend.style.width = "100%"
        // }

        //Third Page
        doc.addPage();
        const context2 = document.getElementById("context2");
        await html2canvas(context2, {
          scale: 3,
          // windowWidth: context2.offsetWidth,
          // windowHeight: context2.offsetHeight,
          useCORS: true,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const height =
            context2.offsetHeight * (pdfWidth / context2.offsetWidth);
          doc.addImage(imgData, "PNG", 0, 0, pdfWidth, height);
        });

        //Four action plan page
        if (data?.data?.riskAction[0]?.data?.length > 0) {
          doc.addPage();
          const actionPlan = document.getElementById("actionPlan");
          await html2canvas(actionPlan, {
            scale: 3,
            useCORS: true,
          }).then(async (canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const height =
              actionPlan.offsetHeight * (pdfWidth / actionPlan.offsetWidth);
            // doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, height);
            if (window.outerWidth < 1280) {
              console.log(window.outerWidth, window.outerHeight, "Not 13 inch");
              doc.addImage(imgData, "PNG", 25, 0, 250, 200);
            } else {
              doc.addImage(imgData, "PNG", 0, 0, pdfWidth, height);
            }
          });
        }

        //Footer and Header Added in all Pages
        const footer = document.getElementById("footer");
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          await html2canvas(footer, {
            scale: 3,
            useCORS: true,
          }).then(async (canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdfHeight = doc.internal.pageSize.getHeight();
            const imageHeight = canvas.height * (pdfWidth / canvas.width);
            const imageX = 0;
            const imageY = pdfHeight - imageHeight;
            doc.addImage(imgData, "PNG", imageX, imageY, pdfWidth, 0);
          });

          const header = document.getElementById("header");
          await html2canvas(header, {
            scale: 3,
            useCORS: true,
          }).then((canvas) => {
            const headerImgData = canvas.toDataURL("image/png");
            const headerX = 0;
            const headerY = 2;
            doc.addImage(headerImgData, "PNG", headerX, headerY, pdfWidth, 0);
          });
        }

        doc.save(`risk-${data?.data?.riskNumber}.pdf`);
        document.getElementById("footer").style.display = "none";
        element.classList.add("headerForRiskDetails");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  const generatePDFViaHTML = async () => {
    setPdfDownloadButton(true)
    document.getElementById("allSection").style.display = "block";
    setTimeout(async () => {      
      const getChart = document.getElementById("rollingRechart");
          if (getChart) {
            await html2canvas(getChart, {
              scale: 3,
              useCORS: true,
            }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
              setRollingRechart(imgData);
              getChart.style.display = "none";
            });
          }
    }, 1000);
    const waitFor1 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    await waitFor1(2500);
    let screen = 0
    console.log(window.outerWidth);
    if (window.outerWidth <= 1024) {
      document.getElementById("actionPlanMargin").style.marginTop = "180px"
      document.getElementById("sixMonthMargin").style.marginTop = "10px"
      document.getElementById("riskLogMargin").style.marginTop = "5px"
      screen = 1024
    }
    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: [300, 320],
      compress: true,
    });

    document.getElementById("footer").style.display = "block";
    document.getElementById("allSection").style.display = "block";
    var element = document.getElementById("header");
    element.classList.remove("headerForRiskDetails");

    const addHtmlToPdf = async (doc, elementId, x, y, width, height) => {
      const element = document.getElementById(elementId);
      await doc.html(element, {
        callback: async function (doc) {
          // Handle additional elements or adjustments here
        },
        x: x,
        y: y,
        width: width,
        windowWidth: width * 3.7795, // Convert mm to px for rendering width
      });
    };

    (async () => {
      await addHtmlToPdf(doc, "allSection", 15, -12, 260, 230);

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();

        //Header
        const header = document.getElementById("header");
        let headerBase64 = await html2canvas(header, {
          scale: 3,
          useCORS: true,
        }).then(async (canvas) => {
          return canvas.toDataURL("image/png");
        });

        if(screen == 1024){
          doc.addImage(headerBase64, "PNG", 0, 0, pageWidth, 15); //1024
        }else if(screen == 768){
          doc.addImage(headerBase64, "PNG", 0, 0, pageWidth, 17); //768
        }else{
          doc.addImage(headerBase64, "PNG", 0, 0, pageWidth, 12);
        }

        //Footer
        const footer = document.getElementById("footer");
        let footerbase64 = await html2canvas(footer, {
          scale: 3,
          useCORS: true,
        }).then(async (canvas) => {
          return canvas.toDataURL("image/png");
        });

        if(screen == 1024){
          console.log(screen);
          doc.addImage(footerbase64, "PNG", -7, pageHeight - 22, pageWidth+14, 22);
        }else if(screen == 768){
          console.log(screen);
          doc.addImage(footerbase64, "PNG", -7, pageHeight - 25, pageWidth+10, 25);
        }else{
          doc.addImage(footerbase64, "PNG", -4, pageHeight - 18, pageWidth+7, 17);
        }
      }

      doc.save(`risk-${data?.data?.riskNumber}.pdf`);
      document.getElementById("footer").style.display = "none";
      document.getElementById("allSection").style.display = "none";
      element.classList.add("headerForRiskDetails");
      setPdfDownloadButton(false)
    })().catch((error) => {
      console.error("Error generating PDF:", error);
    });
  };
  return (
    <div>
      <div
        className="d-flex"
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <div>
          {/* <Breadcrumbs /> */}
          <h5>Risk Detail</h5>
        </div>
        <button
          // onClick={() => generatePDF()}
          onClick={() => generatePDFViaHTML()}
          // onClick={() => handleExportToPDF()}
          className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex"
          disabled={pdfDownloadButton}
        >
          PDF Download
        </button>
      </div>

      

      <div
        className="py-5"
        id="content"
        ref={targetRef}
        style={{ padding: "4px" }}
      >
        {refreshPage ? <HeaderForPDF /> : ""}
        <h3
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "1em",
          }}
        >
          Risk detail for Risk Number: {data?.data?.riskNumber}
        </h3>
        <div
          className="print-report"
          style={{ border: "1px solid #e3e3e3", marginTop: "3em" }}
        >
          <table
            className="table table-style-1 mb-0"
            id="table-to-xls"
            style={{
              minHeight: "20rem",
            }}
          >
            <thead></thead>
            <tbody>
              <tr>
                <td style={colOne}>Risk ID:</td>
                <td style={colTwo}>{data?.data?.riskNumber}</td>
              </tr>

              <tr>
                <td style={colOne}>Risk Status:</td>
                <td style={colTwo}>
                  <span style={{ color: "green" }}>
                    {data?.data?.riskStatus?.name}
                  </span>
                </td>
              </tr>

              <tr>
                <td style={colOne}>Registration Date:</td>
                <td style={colTwo}>
                  {moment(data?.data?.startDate).format("DD MMM YYYY")}
                </td>
              </tr>

              <tr>
                <td style={colOne}>Completion Date:</td>
                <td style={colTwo}>
                  {" "}
                  {moment(data?.data?.endDate).format("DD MMM YYYY")}
                </td>
              </tr>

              <tr>
                <td style={colOne}>Risk Title:</td>
                <td style={colTwo}>{data?.data?.riskName}</td>
              </tr>

              <tr>
                <td style={colOne}>Risk Owner:</td>
                <td style={colTwo}>{data?.data?.riskOwner?.name}</td>
              </tr>

              <tr>
                <td style={colOne}>Risk Type:</td>
                <td style={colTwo}>{data?.data?.riskType?.name}</td>
              </tr>

              <tr>
                <td style={colOne}>Cause:</td>
                <td style={colTwo}>{data?.data?.casuse}</td>
              </tr>

              <tr>
                <td style={colOne}>Event:</td>
                <td style={colTwo}>{data?.data?.riskEvent}</td>
              </tr>

              <tr>
                <td style={colOne}>Project Phase:</td>
                <td style={colTwo}>{data?.data?.projectPhase?.name}</td>
              </tr>

              <tr>
                <td style={colOne}>Comments:</td>
                <td style={colTwo}>
                  <p style={{ wordWrap: "break-word" }}>
                    {" "}
                    {data?.data?.comment}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h4
            className="mt-2 px-4"
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "4em",
            }}
          >
            Risk Assessment Graph
          </h4>
          <div
            className=" p-4 d-flex"
            style={{ justifyContent: "space-around" }}
          >
            {data?.data?.riskAssessment?.length > 0 &&
              data?.data?.riskAssessment?.map((item) => {
                return (
                  <>
                    <div>
                      <h6
                        className="fs-14-normal text-primary"
                        style={{ textAlign: "center" }}
                      >
                        {item?.assessmentType} Risk:{" "}
                        {item?.riskMatrixValue?.yAxis *
                          item?.riskMatrixValue?.xAxis}
                      </h6>
                      <ShowRiskMatrix
                        data={data?.data?.riskMatrixId?.matrix}
                        matrixDesc={data?.data?.riskMatrixId?.colorDescription}
                        column={item?.riskMatrixValue?.xAxis}
                        row={item?.riskMatrixValue?.yAxis}
                      />
                      <div style={{ height: "1em", textAlign: "center" }}>
                        {item?.riskMatrixValue?.riskValue}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>

      {data?.data?.riskAction[0]?.data?.length > 0 && (
        <div className="py-5" id="actionPlan" style={{ padding: "4px" }}>
          <div className="mt-2 border-top">
            {data?.data?.riskAction[0]?.data?.length > 0 && (
              <>
                <h4
                  className="mt-5 px-4"
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "4em",
                  }}
                >
                  Action Plan
                </h4>

                <div
                  className="table-responsive"
                  style={{
                    overflowX: "auto",
                    maxWidth: "100%",
                  }}
                >
                  <table
                    className="table table-style-1 mb-0"
                    id="table-to-xls"
                    width="100%"
                  >
                    <thead>
                      <tr className="text-center">
                        <td style={colOne}>#</td>
                        <td style={colOne}>Action ID</td>
                        <td style={colOne}>Status</td>
                        <td style={colOne}>Progress</td>
                        <td style={colOne}>Description</td>
                        <td style={colOne}>Comment</td>
                        <td style={colOne}>Action Owner</td>
                        <td style={colOne}>Due Date</td>
                        <td style={colOne}>Start Date</td>
                        <td style={colOne}>Last Updated</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.riskAction[0]?.data?.length > 0 &&
                        data?.data?.riskAction[0]?.data?.map((item, index) => {
                          return (
                            <tr className="actiontd text-center">
                              <td>{index + 1}</td>
                              <td>
                                <div className="actionId">
                                  {item?.actionDetail?.actionId}
                                </div>
                              </td>
                              <td>{item?.actionDetail?.status}</td>
                              <td>
                                {item?.actionDetail?.progress}
                                <div
                                  style={{
                                    width: "100%",
                                    background: "#e1e1e1",
                                    height: "6px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: item?.actionDetail?.progress,
                                      background: "green",
                                      height: "6px",
                                      borderRadius: "10px",
                                    }}
                                  ></div>
                                </div>
                              </td>{" "}
                              <td>
                                <div className="short-para">
                                  {item?.actionDetail?.description}
                                </div>
                              </td>{" "}
                              <td>
                                <div className="short-para">
                                  {item?.actionDetail?.comments}
                                </div>
                              </td>{" "}
                              <td style={colOne}>
                                {item?.actionDetail?.actionOwner?.name}
                              </td>
                              <td>
                                {moment(
                                  item?.actionDetail?.completeDate
                                ).format("DD MMM YYYY")}
                              </td>{" "}
                              <td>
                                {moment(item?.actionDetail?.startDate).format(
                                  "DD MMM YYYY"
                                )}
                              </td>{" "}
                              <td className="text-center">
                                {moment(item?.actionDetail?.updatedAt).format(
                                  "DD MMM YYYY"
                                )}
                              </td>{" "}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="p-4">
                  <div className="mt-3">
                    <p>
                      <strong>Average Progress:</strong>{" "}
                      {data?.data?.riskAction[0]?.averageProgress}%
                    </p>
                    <div
                      style={{
                        width: "100%",
                        background: "#e1e1e1",
                        height: "6px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          width:
                            data?.data?.riskAction[0]?.averageProgress + "%",
                          background: "green",
                          height: "6px",
                          borderRadius: "10px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div className="py-5" id="context1" style={{ padding: "4px" }}>
        <div className="mt-4 border-top">
          <h3
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "2em",
            }}
          >
            Six Month Rolling Trend
          </h3>
          {data?.data?.riskAsmtCurrentAll?.length > 0 ? (
            <>
              <ResponsiveContainer
                width="100%"
                id="rollingTrend"
                height={500}
                style={{ marginLeft: "-10px", paddingRight: "30px" }}
              >
                <ComposedChart
                  data={list}
                  margin={{
                    top: 55,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Impact Score" fill="#777">
                    <LabelList dataKey="Impact Score" position="top" />
                  </Bar>
                  <Bar dataKey="Likelihood Score" fill="#E75D0F">
                    <LabelList
                      dataKey="Likelihood Score"
                      position="inside"
                      fill="black"
                      fontSize="0.8vw"
                    />
                  </Bar>
                  <Line
                    type="linear"
                    strokeWidth={4}
                    dot={false}
                    dataKey="exposure"
                    stroke="red"
                    label={{ fill: "black", fontSize: "1vw" }}
                  ></Line>
                </ComposedChart>
              </ResponsiveContainer>
            </>
          ) : (
            <div className="px-4">
              <NoData />
            </div>
          )}
        </div>
      </div>
      <div className="py-5" id="context2" style={{ padding: "4px" }}>
        <div className="mt-4 border-top">
          <h4
            className="px-4"
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "2em",
            }}
          >
            Risk Logs
          </h4>
          {data?.data?.riskLogs?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-style-1 mb-0 table-responsive-th">
                <thead>
                  <tr className="text-center">
                    <th>Date</th>
                    <th>Time</th>
                    <th>Username</th>
                    <th className="text-center">Event</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.riskLogs?.length > 0 &&
                    data?.data?.riskLogs?.map((item, index) => {
                      return (
                        <tr key={item?._id} className="text-center respo-td">
                          <td>
                            {moment(item?.updateAt).format("DD MMM YYYY")}
                          </td>
                          <td>{moment(item?.updateAt).format("hh:mm A")}</td>
                          <td>{item?.updatedBy?.name}</td>
                          <td
                            style={{
                              overflowX: "auto",
                              whiteSpace: "wrap",
                              textAlign: "center",
                            }}
                          >
                            {item?.updateFields.join(", ")}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="px-4">
              <NoData />
            </div>
          )}
        </div>
      </div>

      <div
        className="py-5"
        id="allSection"
        ref={targetRef}
        style={{ padding: "4px", display: "none", marginTop: "60px" }}
      >
        <h3
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "1em",
          }}
        >
          Risk detail for Risk Number: {data?.data?.riskNumber}
        </h3>
        <div className="print-report" style={{ border: "1px solid #e3e3e3" }}>
          <table
            className="table-style-1 mb-0"
            id="table-to-xls"
            style={{
              minHeight: "20rem",
            }}
          >
            <thead></thead>
            <tbody>
              <tr>
                <td style={colOne}>Risk ID:</td>
                <td style={colTwo}>{data?.data?.riskNumber}</td>
              </tr>

              <tr>
                <td style={colOne}>Risk Status:</td>
                <td style={colTwo}>
                  <span style={{ color: "green" }}>
                    {data?.data?.riskStatus?.name}
                  </span>
                </td>
              </tr>

              <tr>
                <td style={colOne}>Registration Date:</td>
                <td style={colTwo}>
                  {moment(data?.data?.startDate).format("DD MMM YYYY")}
                </td>
              </tr>

              <tr>
                <td style={colOne}>Completion Date:</td>
                <td style={colTwo}>
                  {" "}
                  {moment(data?.data?.endDate).format("DD MMM YYYY")}
                </td>
              </tr>

              <tr>
                <td style={colOne}>Risk Title:</td>
                <td style={colTwo}>{data?.data?.riskName}</td>
              </tr>

              <tr>
                <td style={colOne}>Risk Owner:</td>
                <td style={colTwo}>{data?.data?.riskOwner?.name}</td>
              </tr>

              <tr>
                <td style={colOne}>Risk Type:</td>
                <td style={colTwo}>{data?.data?.riskType?.name}</td>
              </tr>

              <tr>
                <td style={colOne}>Cause:</td>
                <td style={colTwo}>{data?.data?.casuse}</td>
              </tr>

              <tr>
                <td style={colOne}>Event:</td>
                <td style={colTwo}>{data?.data?.riskEvent}</td>
              </tr>

              <tr>
                <td style={colOne}>Project Phase:</td>
                <td style={colTwo}>{data?.data?.projectPhase?.name}</td>
              </tr>

              <tr>
                <td style={colOne}>Comments:</td>
                <td style={colTwo}>
                  <p style={{ wordWrap: "break-word" }}>
                    {" "}
                    {data?.data?.comment}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h4
            className="mt-2 px-4"
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "4em",
            }}
          >
            Risk Assessment Graph
          </h4>
          <div
            className=" p-4 d-flex"
            style={{ justifyContent: "space-around" }}
          >
            {data?.data?.riskAssessment?.length > 0 &&
              data?.data?.riskAssessment?.map((item) => {
                return (
                  <>
                    <div>
                      <h6
                        className="fs-14-normal text-primary text-end"
                        style={{ textAlign: "left", fontFamily: "Arial" }}
                      >
                        {item?.assessmentType} Risk:{" "}
                        {item?.riskMatrixValue?.yAxis *
                          item?.riskMatrixValue?.xAxis}
                      </h6>
                      <ShowRiskMatrix
                        data={data?.data?.riskMatrixId?.matrix}
                        matrixDesc={data?.data?.riskMatrixId?.colorDescription}
                        column={item?.riskMatrixValue?.xAxis}
                        row={item?.riskMatrixValue?.yAxis}
                      />
                      <div style={{ height: "1em" }}>
                        {item?.riskMatrixValue?.riskValue}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <div id="actionPlanMargin" style={{ height: "1130px", marginTop: "160px" }}>
            {data?.data?.riskAction[0]?.data?.length > 0 && (
              <>
                <h4
                  className="mt-5 px-4"
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "4em",
                  }}
                >
                  Action Plan
                </h4>

                <div
                  className="table-responsive"
                  style={{
                    overflowX: "auto",
                    maxWidth: "100%",
                  }}
                >
                  <table
                    className="table-style-1 mb-0"
                    id="table-to-xls"
                    width="100%"
                  >
                    <thead>
                      <tr className="text-center">
                        <td style={colOne}>#</td>
                        <td style={colOne}>Action ID</td>
                        <td style={colOne}>Status</td>
                        <td style={colOne}>Progress</td>
                        <td style={colOne}>Description</td>
                        <td style={colOne}>Comment</td>
                        <td style={colOne}>Action Owner</td>
                        <td style={colOne}>Due Date</td>
                        <td style={colOne}>Start Date</td>
                        <td style={colOne}>Last Updated</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.riskAction[0]?.data?.length > 0 &&
                        data?.data?.riskAction[0]?.data?.map((item, index) => {
                          return (
                            <tr className="actiontd text-center">
                              <td style={fontStyle}>{index + 1}</td>
                              <td style={fontStyle}>
                                <div className="actionId">
                                  {item?.actionDetail?.actionId}
                                </div>
                              </td>
                              <td style={fontStyle}>{item?.actionDetail?.status}</td>
                              <td style={fontStyle}>
                                {item?.actionDetail?.progress}
                                <div
                                  style={{
                                    width: "100%",
                                    background: "#e1e1e1",
                                    height: "6px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: item?.actionDetail?.progress,
                                      background: "green",
                                      height: "6px",
                                      borderRadius: "10px",
                                    }}
                                  ></div>
                                </div>
                              </td>{" "}
                              <td style={fontStyle}>
                                <div className="short-para">
                                  {item?.actionDetail?.description}
                                </div>
                              </td>{" "}
                              <td style={fontStyle}>
                                <div className="short-para">
                                  {item?.actionDetail?.comments}
                                </div>
                              </td>{" "}
                              <td style={colOne}>
                                {item?.actionDetail?.actionOwner?.name}
                              </td>
                              <td style={fontStyle}>
                                {moment(
                                  item?.actionDetail?.completeDate
                                ).format("DD MMM YYYY")}
                              </td>{" "}
                              <td style={fontStyle}>
                                {moment(item?.actionDetail?.startDate).format(
                                  "DD MMM YYYY"
                                )}
                              </td>{" "}
                              <td className="text-center" style={fontStyle}>
                                {moment(item?.actionDetail?.updatedAt).format(
                                  "DD MMM YYYY"
                                )}
                              </td>{" "}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="p-4">
                  <div className="mt-3">
                    <p>
                      <strong>Average Progress:</strong>{" "}
                      {data?.data?.riskAction[0]?.averageProgress}%
                    </p>
                    <div
                      style={{
                        width: "100%",
                        background: "#e1e1e1",
                        height: "6px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          width:
                            data?.data?.riskAction[0]?.averageProgress + "%",
                          background: "green",
                          height: "6px",
                          borderRadius: "10px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="mt-2 border-top" id="sixMonthMargin" style={{ height: "1220px" }}>
            <h3
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "3em",
              }}
            >
              Six Month Rolling Trend
            </h3>
            {data?.data?.riskAsmtCurrentAll?.length > 0 ? (
              <>
                <img src={rollingRechart} width="100%" height={500} />
                <ResponsiveContainer
                  width="100%"
                  height={500}
                  id="rollingRechart"
                >
                  <ComposedChart
                    data={list}
                    margin={{
                      top: 55,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Impact Score" fill="#777">
                      <LabelList dataKey="Impact Score" position="top" />
                    </Bar>
                    <Bar dataKey="Likelihood Score" fill="#E75D0F">
                      <LabelList
                        dataKey="Likelihood Score"
                        position="inside"
                        fill="black"
                        fontSize="0.8vw"
                      />
                    </Bar>
                    <Line
                      type="linear"
                      strokeWidth={4}
                      dot={false}
                      dataKey="exposure"
                      stroke="red"
                      label={{ fill: "black", fontSize: "1vw" }}
                    ></Line>
                  </ComposedChart>
                </ResponsiveContainer>
              </>
            ) : (
              <div className="px-4">
                <NoData />
              </div>
            )}
          </div>

          <div className="border-top" id="riskLogMargin" style={{ height: "1130px" }}>
            <h4
              className="px-4"
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "2em",
              }}
            >
              Risk Logs
            </h4>
            {data?.data?.riskLogs?.length > 0 ? (
              <div className="table-responsive">
                <table
                  className="table-style-1 mb-0 table-responsive-th"
                  id="table-to-xls"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Username</th>
                      <th>Event</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.riskLogs?.length > 0 &&
                      data?.data?.riskLogs?.map((item, index) => {
                        return (
                          <tr key={item?._id}>
                            <td style={fontStyle}>
                              {moment(item?.updateAt).format("DD MMM YYYY")}
                            </td>
                            <td style={fontStyle}>{moment(item?.updateAt).format("hh:mm A")}</td>
                            <td style={fontStyle}>{item?.updatedBy?.name}</td>
                            <td
                              style={{ overflowX: "auto", whiteSpace: "wrap", fontFamily:"Arial" }}
                            >
                              {item?.updateFields.join(", ")}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="px-4">
                <NoData />
              </div>
            )}
          </div>
        </div>
        {refreshPage ? <FooterForPDF margin="96%" /> : ""}
      </div>

      <div
        style={{ marginBottom: "30px" }}
        className="headerForRiskDetails topHeaderWrapper sticky-top d-flex justify-content-between align-items-center"
        id="header"
      >
        <div></div>
        <div className="d-flex gap-3 align-items-center justify-content-end">
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="transparent"
              className="p-0 border-0"
            ></Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="transparent"
              className="p-0 border-0"
            >
              <div className="d-flex gap-2 align-items-center">
                <div className="text-end">
                  <h5 class="mb-0 fs-16 fw-bold lh-1">{profileInfo?.name}</h5>
                  <p className="mb-0 fs-11 text-dark lh-base mt-1" style={{fontSize:"10px"}}>
                    {profileInfo?.email.toLowerCase()}
                  </p>
                </div>
                <div>
                  <div className="nameCircle" style={{fontSize:"10px"}}>
                    {profileInfo?.name.split(" ")[0].charAt(0)}
                    {profileInfo?.name.split(" ")[1].charAt(0)}
                  </div>
                </div>
              </div>
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>
      <div style={{ display: "none" }} id="footer">
        <div className="topHeaderWrapper">
          <div>
            <h6 style={{fontSize:"10px"}}>Date: {currentDate}</h6>
            <h6 style={{fontSize:"10px"}}>Time: {currentTime}</h6>
          </div>
          <img src={LOGO} width="80" alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default RiskDetail;
