import React from "react";
import Accordion from "react-bootstrap/Accordion";

import TimeLineChart from "../components/TimeLineChart";
import ActionOwnerReport from "../components/ActionOwnerReport";

const ActionReport = () => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12">
          <Accordion
            defaultActiveKey=""
            className="border-0 gap-3 d-flex flex-column"
          >
            <Accordion.Item eventKey="15">
              <Accordion.Header> Action By Status</Accordion.Header>
              <Accordion.Body>
                <ActionOwnerReport />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header> Action Proximity Report</Accordion.Header>
              <Accordion.Body>
                <TimeLineChart />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default ActionReport;
