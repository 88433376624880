import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import User from "../../assets/icons/ProfileUser.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  useGetProjectUserQuery,
  useAddMitigationMutation,
} from "../../services/apis";
import { useParams } from "react-router-dom";
import { PopUp } from "../../utils/alert";
import Loader from "../../utils/loader";
import { handleErrors } from "../../utils/error";
import {
  useMitigationByIdQuery,
  useUpdateMitigationMutation,
} from "../../services/apis";
import { useSelector, useDispatch } from "react-redux";
import { setEditMitigationStatus } from "../../services/slices/constants";
import CreateRiskOwner from "./CreateRiskOwner";

function MitigationModal({ show, close, id, edit = false, rollData = "", riskData}) {
  const statusCount = useSelector(
    (state) => state?.constants?.editMitigationStatus
  );
  const dispatch = useDispatch();
  const [list, setList] = useState();

  const { data: mitiDetail, refetch: mitiRefetch } = useMitigationByIdQuery(id ? id : rollData?.riskactionId);
  // const { data: mitiDetail, refetch: mitiRefetch } = useMitigationByIdQuery(id);

  const [selectedUser, setSelectedUser] = useState({ name: "", id: "" });
  const [actionType, setActionType] = useState(
    edit
      ? [
        {
          title: "DRAFT",
          value: "Draft",
        },
        {
          title: "IN PROGRESS",
          value: "InProgress",
        },
        {
          title: "COMPLETED",
          value: "Completed",
        },
      ]
      : [
        {
          title: "DRAFT",
          value: "Draft",
        },
      ]
  );

  const [description, setDescription] = useState("");
  const [summary, setSummary] = useState("");
  const [comments, setComments] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [userShow, setUserShow] = useState(false);


  const [status, setStatus] = useState({ name: "", id: "" });
  const [progress, setProgress] = useState({ name: "", id: "" });

  const { data, refetch } = useGetProjectUserQuery();
  const [addMitigation, { data: mitiData, isLoading, error }] =
    useAddMitigationMutation();
  const [
    updateMitigation,
    { data: updateData, isLoading: updateLoading, error: updateErr },
  ] = useUpdateMitigationMutation();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const updatedData = data?.data?.filter((item) => item?.userRole === 2);
    setList(updatedData);
  }, []);

  useEffect(() => {
    const updatedData = data?.data?.filter((item) =>
      item?.name.includes(statusSearch.toLowerCase())
    );
    setList(updatedData);
  }, [statusSearch]);

  useEffect(() => {
    mitiRefetch();
  }, [show]);

  console.log("rollData", rollData);

  useEffect(() => {
    setDescription(
      rollData ? rollData?.description : mitiDetail?.data?.description
    );
    setComments(rollData ? rollData?.comments : mitiDetail?.data?.comments);
    setSummary(rollData ? rollData?.summary : mitiDetail?.data?.summary);
    setStartDate(
      rollData
        ? rollData?.startDate.split("T")[0]
        : mitiDetail?.data?.startDate.split("T")[0]
    );
    setEndDate(
      rollData
        ? rollData?.completeDate.split("T")[0]
        : mitiDetail?.data?.completeDate.split("T")[0]
    );
    setProgress(
      rollData
        ? {
          name: rollData?.progress,
          id: rollData?.progress,
        }
        : {
          name: mitiDetail?.data?.progress,
          id: mitiDetail?.data?.progress,
        }
    );
    const updatedStatus = actionType?.filter(
      (item) => item?.value === mitiDetail?.data?.status
    );

    const updatedStatusWithRoll = actionType?.filter(
      (item) => item?.value === rollData?.status
    );

    setStatus(
      rollData
        ? {
          name: updatedStatusWithRoll[0]?.title,
          id: updatedStatusWithRoll[0]?.value,
        }
        : { name: updatedStatus[0]?.title, id: updatedStatus[0]?.value }
    );
    setSelectedUser(
      rollData
        ? {
          name: rollData?.actionOwner?.name,
          id: rollData?.actionOwner?._id,
        }
        : {
          name: mitiDetail?.data?.actionOwner?.name,
          id: mitiDetail?.data?.actionOwner?._id,
        }
    );
  }, [mitiDetail, rollData]);

  console.log("Progress", progress);

  const handleMitigation = async () => {
    const validationRules = [
      { field: description, message: "Description is mandatory" },
      { field: status?.id, message: "Select status progress" },
      { field: progress?.id, message: "Select action progress" },
      { field: selectedUser?.id, message: "Select action owner" },
      { field: startDate, message: "Select start date" },
      { field: endDate, message: "Select end date" },
    ];

    for (const rule of validationRules) {
      if (!rule.field) {
        PopUp(rule.message, "", "error");
        return;
      }
    }

    if (new Date(endDate) < new Date(startDate)) {
      PopUp("End date cannot be earlier than the start date", "", "error");
      return;
    }

    const objectValue = {
      riskId: id ? id : rollData?.riskactionId,
      // riskId: id,
      description,
      summary,
      comments,
      status: "Draft",
      progress: progress?.id,
      actionOwner: selectedUser?.id,
      completeDate: endDate,
      startDate,
    };

    await addMitigation(objectValue);
    setDescription("");
    setSummary("");
    setComments("");
    setStartDate("");
    setEndDate("");
    setSelectedUser({ name: "", id: "" })
    setStatus({ name: "", id: "" });
    setProgress({ name: "", id: "" });
  };

  const handleUpdateMitigation = () => {
    const validationRules = [
      { field: description, message: "Description is mandatory" },
      // { field: summary, message: "Action progress summary is mandatory" },
      // { field: comments, message: "Action comments is mandatory" },
      { field: status?.id, message: "Select status progress" },
      { field: progress?.id, message: "Select action progress" },
      { field: selectedUser?.id, message: "Select action owner" },
      { field: startDate, message: "Select start date" },
      { field: endDate, message: "Select end date" },
    ];

    for (const rule of validationRules) {
      if (!rule.field) {
        PopUp(rule.message, "", "error");
        return;
      }
    }

    if (new Date(endDate) < new Date(startDate)) {
      PopUp("End date cannot be earlier than the start date", "", "error");
      return;
    }

    const updatedFields = {};

    if (description !== mitiDetail?.data?.description) {
      updatedFields.description = description;
    }

    if (summary !== mitiDetail?.data?.summary) {
      updatedFields.summary = summary;
    }

    if (comments !== mitiDetail?.data?.comments) {
      updatedFields.comments = comments;
    }

    if (status?.id !== mitiDetail?.data?.status) {
      updatedFields.status = status?.id;
    }

    if (progress?.id !== mitiDetail?.data?.progress) {
      updatedFields.progress = progress?.id;
    }

    if (selectedUser?.id !== mitiDetail?.data?.actionOwner?._id) {
      updatedFields.actionOwner = selectedUser?.id;
    }

    if (endDate !== mitiDetail?.data?.completeDate.split("T")[0]) {
      updatedFields.completeDate = endDate;
    }

    if (startDate !== mitiDetail?.data?.startDate.split("T")[0]) {
      updatedFields.startDate = startDate;
    }
    updatedFields.id = id ? id : rollData?.riskactionId;
    // updatedFields.id = id;
    if (Object.keys(updatedFields).length > 0) {
      updatedFields.riskActionVersion = mitiDetail?.data?.riskActionVersion;
      updateMitigation(updatedFields);
    } else {
      PopUp("No changes detected", "", "info");
    }
  };

  useEffect(() => {
    if (mitiData?.success) {
      PopUp("Mitigation added successfully", "", "success");
      close();
    }
    if (updateData?.success) {
      PopUp("Mitigation updated successfully", "", "success");
      dispatch(setEditMitigationStatus(statusCount + 1));
      close();
    }
    handleErrors(edit ? updateErr : error);
  }, [mitiData, error, updateData, updateErr]);



  console.log("riskData",mitiDetail)

  return (
    <>
      <Modal
        show={show}
        size="lg"
        dialogClassName="modal-rounded modal-650"
        centered
        onHide={close}
      >
        <Modal.Header
          className="text-center border-0 justify-content-center position-relative abolute-close modal-header flex-column"
          closeButton
        >
          <Modal.Title className="text-black fw-bold fs-16">Mitigation for {`${mitiDetail?.data?.riskId?.riskName ? mitiDetail?.data?.riskId?.riskName : riskData?.riskName }`}</Modal.Title>
          {/* {mitiDetail?.data?.actionId && (
            <h6 className="fs-13 mt-2 text-secondary opacity-75">
              Action ID: {mitiDetail?.data?.actionId}
            </h6>
          )} */}
        </Modal.Header>
        <Modal.Body className="text-center row mx-0 pt-1">
          <p className="text-start">
          {mitiDetail?.data?.riskId?.riskEvent}
        </p>
          <div className="mb-2 col-md-12 text-start">
            <label className="cf_label fw-bold" htmlFor="">Action Description</label>{" "}
            {/* <span style={{ color: "red" }}>*</span> */}
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="2"
              className="textarea-1 w-100 py-3"
              placeholder="Max 200 characters"
            ></textarea>
          </div>
          <div className="mb-2 col-md-12 text-start">
            <label className="cf_label fw-bold" htmlFor="">Progress Summary</label>{" "}
            <textarea
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              rows="2"
              className="textarea-1 w-100 py-3"
              placeholder="Max 200 characters"
            ></textarea>
          </div>
          <div className="col-md-6">
            <div className="mb-2 col-md-12 text-start">
              <label className="cf_label fw-bold" htmlFor="">Comments</label>{" "}
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                rows="8"
                className="textarea-1 w-100 py-3"
                placeholder="Max 200 characters"
              ></textarea>
            </div>

            <div className="mb-2 col-md-12 text-start">
              <label className="cf_label fw-bold" htmlFor="">Start Date</label>{" "}
              {/* <span style={{ color: "red" }}>*</span> */}
              <input
                type="date"
                className="input-1 w-100"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            
            <div className="mb-2 col-md-12 text-start">
              <label className="cf_label fw-bold" htmlFor="">Action Owner</label>{" "}
              {/* <span style={{ color: "red" }}>*</span> */}
              <Dropdown className="w-100">
                <Dropdown.Toggle
                  className="input-1 w-100 dropWithArrow2 justify-content-center px-3"
                  id="dropdown-basic"
                  style={{ color: "#444" }}
                >
                  {selectedUser?.name || "Mitigation Action Owner"}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100 br-14 py-2 px-3">
                  <input
                    type="search"
                    placeholder="Search user"
                    value={statusSearch}
                    onChange={(e) => setStatusSearch(e.target.value)}
                  />
                  <div className="scroll-120 thin-scroll mt-2">
                    {!!list?.length &&
                      list?.map((item) => {
                        return (
                          <label
                            htmlFor={item?._id}
                            className="customRadio d-flex align-items-center gap-2"
                            key={item?._id}
                          >
                            <input
                              type="radio"
                              name="demoradio"
                              id={item?._id}
                              onChange={() =>
                                setSelectedUser({
                                  name: item?.name,
                                  id: item?._id,
                                })
                              }
                            />
                            <img src={User} alt="user" />
                            <h6 className="mb-0">
                              {item?.name} <small>Engineer</small>
                            </h6>
                          </label>
                        );
                      })}
                  </div>
                  <Link
                  className="fs-12-600-link mt-2 text-center d-block"
                  onClick={() => {
                    setUserShow(true);
                    close();
                }}
                >
                  + Create new user
                </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="mb-2 col-md-12 text-start">
              <label className="cf_label fw-bold" htmlFor="">Status</label>{" "}
              {/* <span style={{ color: "red" }}>*</span> */}
              <Dropdown className="w-100">
                <Dropdown.Toggle
                  className="input-1 w-100 dropWithArrow2 justify-content-center px-3"
                  id="dropdown-basic"
                  style={{ color: "#444" }}
                >
                  {status?.name || "Action Status"}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100 br-14 py-2 px-3">
                  {actionType?.map((item) => {
                    return (
                      <Dropdown.Item
                        className="gap-2 d-flex align-items-center"
                        onClick={() =>
                          setStatus({ name: item?.title, id: item?.value })
                        }
                      >
                        <span className="bg-secondary opacity-50 mr-2 wh-12"></span>
                        {item?.title}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="mb-2 col-md-12 text-start">
              <label className="cf_label fw-bold" htmlFor="">Progression</label>{" "}
              {/* <span style={{ color: "red" }}>*</span> */}
              <Dropdown className="w-100">
                <Dropdown.Toggle
                  className="input-1 w-100 dropWithArrow2 justify-content-center px-3"
                  id="dropdown-basic"
                  style={{ color: "#444" }}
                >
                  {progress?.name || "Action Progress"}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100 br-14 py-2 px-3">
                  {edit ? (
                    <div className="scroll-120 thin-scroll mt-2">
                      {["0", 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]?.map(
                        (item) => {
                          console.log("ITEM", item);
                          return (
                            <Dropdown.Item
                              className="gap-2 d-flex align-items-center"
                              onClick={() =>
                                setProgress({ name: item, id: `${item}%` })
                              }
                            >
                              {item}%
                            </Dropdown.Item>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div className="scroll-120 thin-scroll mt-2">
                      {["0", 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]?.map(
                        (item) => {
                          console.log("ITEM", item);
                          return (
                            <Dropdown.Item
                              className="gap-2 d-flex align-items-center"
                              onClick={() =>
                                setProgress({ name: item, id: `${item}%` })
                              }
                            >
                              {item}%
                            </Dropdown.Item>
                          );
                        }
                      )}
                    </div>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="mb-2 col-md-12 text-start mt-3">
              <label className="cf_label fw-bold" htmlFor="">Completion</label>{" "}
              {/* <span style={{ color: "red" }}>*</span> */}
              <input
                type="date"
                className="input-1 w-100"
                value={endDate}
                min={startDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </div>
            <div className="col-md-12 mt-4 text-start">
            {edit ? (
              <button
                className="btn btn-trinary w-100 h-48 px-5 fs-16 col-4"
                onClick={handleUpdateMitigation}
              >
                {updateLoading ? <Loader /> : "Update"}
              </button>
            ) : (
              <button
                className="btn btn-trinary w-100 h-48 px-5 fs-16 col-4"
                onClick={handleMitigation}
              >
                {isLoading ? <Loader /> : "SAVE"}
              </button>
            )}
          </div>
          </div>



          
        </Modal.Body>
      </Modal>
      <CreateRiskOwner
        show={userShow}
        close={() => setUserShow(false)}
        data={{}}
      />
    </>
  );
}

export default MitigationModal;
