import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.png";
import { useOtpVerifyMutation } from "../services/apis";
import { PopUp } from "../utils/alert";
import { handleErrors } from "../utils/error";
import Loader from "../utils/loader";
import { useLocation, useNavigate } from "react-router-dom";
const OtpVerification = () => {
  const [otpVerify, { data, isLoading, error }] = useOtpVerifyMutation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");

  const handleVerify = () => {
    if (!otp) {
      PopUp("OTP is mandatory", "", "error");
      return;
    }
    otpVerify({
      email: state?.email,
      emailOTP: otp,
    });
  };

  useEffect(() => {
    if (data?.success) {
      PopUp("OTP verified successfuly", "", "success");
      navigate("/reset-password", { state: { email: state?.email } });
    }
    handleErrors(error);
  }, [data, error]);

  return (
    <>
      <div className="mw-480">
        <div className="authBox bg-white rounded d-flex align-items-center flex-column">
          <div className="authHead w-100">
            <div className="wh-114 mx-auto">
              <img src={Logo} alt="Logo" className="img-contain" />
            </div>
          </div>
          <div className="authBody w-100">
            <h6 class="authTitle text-center mb-3">OTP Verification</h6>
            <div className="input-group mb-3">
              <input
                type="email"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                class="form-control rounded-pill border"
                placeholder="Enter OTP"
              />
            </div>
            <div className="input-group mt-4">
              <button
                className="btn btn-primary rounded-pill w-100"
                onClick={handleVerify}
                style={{ justifyContent: "center" }}
              >
                {isLoading ? <Loader /> : "Verify"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
