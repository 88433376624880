import React, { useState, useEffect } from "react";
import { useGetActionReportQuery } from "../services/apis";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";

const ActionOwnerReport = () => {
  const { toPDF, targetRef } = usePDF({
    filename: `actionByStatusReport.pdf`,
  });
  const { id } = useParams();
  const { data: info, isFethcing, refetch } = useGetActionReportQuery(id);
  const [data, setData] = useState([]);

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    const newArray = info?.data?.map((item) => ({
      name: item?.actionOwner[0]?.name,
      Draft: item?.statusCounts[0]?.count,
      InProgresss: item?.statusCounts[1]?.count,
      Rejected: item?.statusCounts[3]?.count,
      Completed: item?.statusCounts[2]?.count,
    }));
    setData(newArray);
  }, [info]);

  return (
    <div>
      <button className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex" onClick={() => toPDF()}>
        PDF Download
      </button>
      <div className="px-5 pt-4" ref={targetRef}>
        {" "}
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={500}
            height={300}
            barSize={50}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Draft" stackId="q" fill="red" />
            <Bar dataKey="InProgresss" stackId="q" fill="orange" />
            <Bar dataKey="Rejected" stackId="q" fill="#FFD700" />
            <Bar dataKey="Completed" stackId="q" fill="green" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ActionOwnerReport;
