import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.png";
import { useForgotPasswordMutation } from "../services/apis";
import Loader from "../utils/loader";
import { PopUp } from "../utils/alert";
import { handleErrors } from "../utils/error";
import { useNavigate } from "react-router-dom";
import { OTP } from "../utils/routes";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword, { data, isLoading, error }] =
    useForgotPasswordMutation();

  const [email, setEmail] = useState("");

  const handleForgot = () => { 
    if (!email) {
      PopUp("Email is mandatory", "", "error");
      return;
    }
    forgotPassword({
      email,
    });
  };
 
  useEffect(() => {
    if (data?.success) {
      PopUp("OTP successfully sent to your email inbox", "", "success");
      navigate(OTP, { state: { email } });
    }
    handleErrors(error);
  }, [data, error]);

  return (
    <>
      <div className="mw-480">
        <div className="authBox bg-white rounded d-flex align-items-center flex-column">
          <div className="authHead w-100">
            <div className="wh-114 mx-auto">
              <img src={Logo} alt="Logo" className="img-contain" />
            </div>
          </div>
          <div className="authBody w-100">
            <h6 class="authTitle text-center mb-3">Forgot Password?</h6>
            <div className="input-group mb-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                class="form-control rounded-pill border"
                placeholder="Your Registered Email"
              />
            </div>
            <div className="input-group mt-4 ">
              <button
                className="btn btn-primary rounded-pill w-100 "
                style={{ justifyContent: "center" }}
                onClick={handleForgot}
              >
                {isLoading ? <Loader /> : "Send"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
