import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useActionProximityReportQuery } from "../services/apis";
import { useParams } from "react-router-dom";
import moment from "moment";
import { usePDF } from "react-to-pdf";

const TimeLineChart = () => {
  const { toPDF, targetRef } = usePDF({
    filename: `actionProximityReport.pdf`,
  });
  const { id } = useParams();
  const { data, isfetching, refetch } = useActionProximityReportQuery(id);

  const [list, setList] = useState([]);
  const [chartData, setChartData] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        type: "datetime",
      },
    },
  });

  useEffect(() => {
    const newArray = data?.data?.map((item) => ({
      x: item?.actionId,
      y: [
        new Date(item?.startDate).getTime(),
        new Date(item?.completeDate).getTime(),
      ],
    }));
    setList(newArray);
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    // Update chartData when list is updated
    if (list?.length > 0) {
      setChartData({
        series: [
          {
            data: list,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "rangeBar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          xaxis: {
            type: "datetime",
          },
        },
      });
    }
  }, [list]);

  return (
    <div id="chart">
      <button className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex" onClick={() => toPDF()}>
      PDF Download
      </button>
      <div className="px-5 pt-4" ref={targetRef}>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="rangeBar"
          height={350}
        />
      </div>
    </div>
  );
};

export default TimeLineChart;
