export const initialInputs = [
  {
    likelihood: "0",
    description: "Rare",
    start: "0",
    end: "10",
    position: 1,
  },
  {
    likelihood: "10",
    description: "Remote",
    start: "10",
    end: "25",
    position: 2,
  },
  {
    likelihood: "25",
    description: "Possible",
    start: "25",
    end: "50",
    position: 3,
  },
  {
    likelihood: "50",
    description: "Likely",
    start: "50",
    end: "75",
    position: 4,
  },
  {
    likelihood: "75",
    description: "Almost certain",
    start: "75",
    end: "100",
    position: 5,
  },
];

export const initialInputsEmpty = [
  {
    likelihood: "",
    description: "",
    start: "",
    end: "",
    position: 1,
  },
  {
    likelihood: "",
    description: "",
    start: "",
    end: "",
    position: 2,
  },
  {
    likelihood: "",
    description: "",
    start: "",
    end: "",
    position: 3,
  },
  {
    likelihood: "",
    description: "",
    start: "",
    end: "",
    position: 4,
  },
  {
    likelihood: "",
    description: "",
    start: "",
    end: "",
    position: 5,
  },
]

export const costImpactData = [
  {
    impact: "0",
    description: "Negligible",
    start: "0",
    end: "1000",
    position: 1,
  },
  {
    impact: "1000",
    description: "Moderate",
    start: "1000",
    end: "10000",
    position: 2,
  },
  {
    impact: "10000",
    description: "Significant",
    start: "10000",
    end: "100000",
    position: 3,
  },
  {
    impact: "100000",
    description: "Excessive",
    start: "100000",
    end: "500000",
    position: 4,
  },
  {
    impact: "500000",
    description: "Critical",
    start: "500000",
    end: "1000000",
    position: 5,
  },
];

export const costImpactDataEmpty = [
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 1,
  },
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 2,
  },
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 3,
  },
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 4,
  },
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 5,
  },
];

export const timeImpactData = [
  {
    impact: "1",
    description: "Negligible",
    start: "1",
    end: "7",
    position: 1,
  },
  {
    impact: "7",
    description: "Moderate",
    start: "7",
    end: "14",
    position: 2,
  },
  {
    impact: "14",
    description: "Significant",
    start: "14",
    end: "21",
    position: 3,
  },
  {
    impact: "21",
    description: "Excessive",
    start: "21",
    end: "28",
    position: 4,
  },
  {
    impact: "28",
    description: "Critical",
    start: "28",
    end: "35",
    position: 5,
  },
];

export const timeImpactDataEmpty = [
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 1,
  },
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 2,
  },
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 3,
  },
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 4,
  },
  {
    impact: "",
    description: "",
    start: "",
    end: "",
    position: 5,
  },
];

export const customImpactData = [
  {
    impact: 1,
    description: "",
    detail: "",
    position: 1,
  },
  {
    impact: 2,
    description: "",
    detail: "",
    position: 2,
  },
  {
    impact: 3,
    description: "",
    detail: "",
    position: 3,
  },
  {
    impact: 4,
    description: "",
    detail: "",
    position: 4,
  },
  {
    impact: 5,
    description: "",
    detail: "",
    position: 5,
  },
];

export const colorsInput = [
  {
    color: "light-green",
    description: "Minimal",
  },
  {
    color: "green",
    description: "Substantial",
  },
  {
    color: "yellow",
    description: "Major",
  },
  {
    color: "amber",
    description: "Extreme",
  },
  {
    color: "red",
    description: "Castastorphic",
  },
];
export const colorsInputEmpty = [
  {
    color: "light-green",
    description: "",
  },
  {
    color: "green",
    description: "",
  },
  {
    color: "yellow",
    description: "",
  },
  {
    color: "amber",
    description: "",
  },
  {
    color: "red",
    description: "",
  },
];

export const likeValData = [
  {
    value: "",
    description: "",
  },
  {
    value: "",
    description: "",
  },
  {
    value: "",
    description: "",
  },
  {
    description: "",
  },
  {
    value: "",
    description: "",
  },
];

export const impactValData = [
  {
    value: "",
    description: "",
  },
  {
    value: "",
    description: "",
  },
  {
    value: "",
    description: "",
  },
  {
    description: "",
  },
  {
    value: "",
    description: "",
  },
];
