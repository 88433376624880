import React, { useState, useEffect } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const EditShowMatrix = ({ info, data,matrixDesc }) => {
  const [matrixData, setMatrixData] = useState([]);
  useEffect(() => {
    setMatrixData(info?.length > 0 ? info : data);
  }, [info, data]);

  const getColorDescription = (color) => {
    const foundColor = matrixDesc?.find(desc => desc?.color === color);
    return foundColor ? foundColor.description : color;
  }
    return (
    <div
    className="ms-auto"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        width: "97%",
        height: "97%",
        aspectRatio:2 / 2
      }}
    >
      {!!matrixData?.length &&
        matrixData?.map((box) => (
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{getColorDescription(box.color).toUpperCase().replace('-',' ')}</Tooltip>}>
          <div
            key={box.cellId}
            className={` bg-${box.color} box `}
            style={{
              border: "0.001em solid white",
              width: "100%",
              height: "100%",
            }}
          ></div>
          </OverlayTrigger>
        ))}
    </div>
  );
};

export default EditShowMatrix;
