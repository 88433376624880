import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useRiskProximityReportQuery } from "../services/apis";
import moment from "moment";
import { useParams } from "react-router-dom";
import { usePDF,Margin } from "react-to-pdf";
import HeaderForPDF from "./HeaderForPDF";
import FooterForPDF from "./FooterForPDF";
import jsPDF from "jspdf";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import LOGO from "../assets/images/EPRisk-logo.png";
import html2canvas from "html2canvas";
const colTwo = {
	whiteSpace: "normal",
	wordWrap: "anywhere",
	minWidth: "200px",
	fontFamily: "Arial"
};

const fontStyle = {
	fontFamily: "Arial"
  }

const TimeLineRisk = () => {
	const { toPDF, targetRef } = usePDF({
		filename: `proximityOfRisk.pdf`,
		page: {
			margin: Margin.SMALL,
		 },
	});
	const profileInfo = useSelector(
		(state) => state?.constants?.profileInfo?.data
	);
	const currentDate = new Date().toLocaleDateString();
	const currentTime = new Date().toLocaleTimeString();
	const { id } = useParams();
	const { data, isfetching, refetch } = useRiskProximityReportQuery(id);
	const [pdfDownloadButton, setPdfDownloadButton] = useState(false);

	const [list, setList] = useState([]);
	const [apexchart, setApexchart] = useState("");
	const [refreshPage, setRefreshPage] = useState(false)
	// console.log(data)
	const [chartData, setChartData] = useState({
		series: [
			{
				data: [],
			},
		],
		options: {
			chart: {
				height: 350,
				type: "rangeBar",
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			xaxis: {
				type: "datetime",
			},
		},
	});

	useEffect(() => {
		const newArray = data?.data?.map((item) => ({
			x: item?.riskNumber,
			y: [
				new Date(item?.startDate).getTime(),
				new Date(item?.endDate).getTime(),
			],
		}));
		setList(newArray);
	}, [data]);

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		// Update chartData when list is updated
		if (list?.length > 0) {
			setChartData({
				series: [
					{
						data: list,
					},
				],
				options: {
					chart: {
						height: 350,
						type: "rangeBar",
					},
					plotOptions: {
						bar: {
							horizontal: true,
						},
					},
					xaxis: {
						type: "datetime",
					},
				},
			});
		}
	}, [list]);

	const handleExportToPDF = () => {
		setRefreshPage(true); // Set refreshPage to true
	  };
	  useEffect(() => {
		// Call toPDF when refreshPage is true
		if (refreshPage) {
		  toPDF();
		  setRefreshPage(false); // Reset refreshPage to false after calling toPDF
		}
	  }, [refreshPage, toPDF]);

	  const generatePDFViaHTML = async () => {
		setPdfDownloadButton(true)
		document.getElementById("riskProximityAnalysis").style.display = "block";
		setTimeout(async () => {      
		  const getChart = document.getElementById("ApexChart");
			  if (getChart) {
				await html2canvas(getChart, {
				  scale: 3,
				  useCORS: true,
				}).then((canvas) => {
				  const imgData = canvas.toDataURL("image/png");
				  setApexchart(imgData);
				});
			  }
		}, 1000);
		const waitFor1 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
		await waitFor1(2500);
		let screen = 0
		console.log(window.outerWidth);
		if (window.outerWidth <= 1024) {
		  screen = 1024
		}
		const doc = new jsPDF({
		  orientation: "p",
		  unit: "mm",
		  format: [300, 320],
		  compress: true,
		});
	
		document.getElementById("endfooter").style.display = "block";
		var element = document.getElementById("topheader");
		element.classList.remove("headerForRiskDetails");
	
		const addHtmlToPdf = async (doc, elementId, x, y, width, height) => {
		  const element = document.getElementById(elementId);
		  await doc.html(element, {
			callback: async function (doc) {
			  // Handle additional elements or adjustments here
			},
			x: x,
			y: y,
			width: width,
			windowWidth: width * 3.7795, // Convert mm to px for rendering width
		  });
		};
	
		(async () => {
		  await addHtmlToPdf(doc, "riskProximityAnalysis", 5, -12, 290, 230);
			
		  

		  const pageCount = doc.internal.getNumberOfPages();
		  for (let i = 1; i <= pageCount; i++) {
			doc.setPage(i);
			const pageSize = doc.internal.pageSize;
			const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
			const pageHeight = pageSize.height
			  ? pageSize.height
			  : pageSize.getHeight();
	
			//Header
			const header = document.getElementById("topheader");
			let headerBase64 = await html2canvas(header, {
			  scale: 3,
			  useCORS: true,
			}).then(async (canvas) => {
				return canvas.toDataURL("image/png");
			});
			
			if(screen == 1024){
			  doc.addImage(headerBase64, "PNG", 0, 0, pageWidth, 15); //1024
			}else{
			  doc.addImage(headerBase64, "PNG", 0, 0, pageWidth, 12);
			}
	
			//Footer
			const footer = document.getElementById("endfooter");
			let footerbase64 = await html2canvas(footer, {
			  scale: 3,
			  useCORS: true,
			}).then(async (canvas) => {
			  return canvas.toDataURL("image/png");
			});
	
			if(screen == 1024){
			  console.log(screen);
			  doc.addImage(footerbase64, "PNG", -7, pageHeight - 22, pageWidth+14, 22);
			}else{
			  doc.addImage(footerbase64, "PNG", -4, pageHeight - 18, pageWidth+7, 17);
			}
		  }
	
		doc.save(`proximityOfRisk.pdf`);
		document.getElementById("endfooter").style.display = "none";
		document.getElementById("riskProximityAnalysis").style.display = "none";
		element.classList.add("headerForRiskDetails");
		setPdfDownloadButton(false)
		})().catch((error) => {
		  console.error("Error generating PDF:", error);
		});
	  };

	return (
		<>
		<div id="chart">
			<button className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex" onClick={() => generatePDFViaHTML()} disabled={pdfDownloadButton}>
			PDF Download
			</button>
			<div className="px-5 pt-4" ref={targetRef}>
			{refreshPage ?  <HeaderForPDF /> :""}  

				<ReactApexChart
					options={chartData.options}
					series={chartData.series}
					type="rangeBar"
					height={350}
					id="ApexChart"
				/>
				<div className="table-responsive">
					<table
						class="table  table-style-1 mb-0 mt-4"
						style={{ width: "100%" }}
					>
						<thead>
							<tr>
								<th>Ranking</th>
								<th>Risk ID</th>
								<th style={colTwo}>
									Cause
								</th>
								<th style={colTwo}>
									Event
								</th>
								<th style={colTwo}>
									Consequence
								</th>
								<th>
									Owner
								</th>
								<th>Earliest Date</th>
								<th>Expiry Date</th>
							</tr>
						</thead>

						<tbody>
							{!!data?.data?.length &&
								data?.data?.map((item, index) => {
									return (
										<tr key={item?._id}>
											<td style={{ width: "2em" }}>{index + 1}</td>
											<td>{item?.riskNumber}</td>
											<td style={colTwo}>{item?.casuse?.slice(0, 15)}</td>
											<td style={colTwo}>{item?.riskEvent}</td>
											<td style={colTwo}>{item?.riskImpact}</td>
											<td>{item?.riskOwnerDetails[0]?.name || ""}</td>
											<td>{moment(item?.startDate).format("DD MMM YYYY")}</td>
											<td>{moment(item?.endDate).format("DD MMM YYYY")}</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
                {refreshPage ?  <FooterForPDF margin="96%"/> :"" }

			</div>
		</div>
		<div id="riskProximityAnalysis" style={{display:"none"}}>
				<div style={{height:"1220px", marginTop:"100px"}}>
					<img src={apexchart} width="100%" height={500} />
				</div>
				<div className="table-responsive" style={{height:"1130px"}}>
					<table
						class="table-style-1 mb-0 mt-4"
						style={{ width: "100%" }}
					>
						<thead>
							<tr className="fontinRiskProxi">
								<th style={fontStyle}>Ranking</th>
								<th style={fontStyle}>Risk ID</th>
								<th style={colTwo}>
									Cause
								</th>
								<th style={colTwo}>
									Event
								</th>
								<th style={colTwo}>
									Consequence
								</th>
								<th style={fontStyle}>
									Owner
								</th>
								<th style={fontStyle}>Earliest Date</th>
								<th style={fontStyle}>Expiry Date</th>
							</tr>
						</thead>

						<tbody>
							{!!data?.data?.length &&
								data?.data?.map((item, index) => {
									return (
										<tr key={item?._id}>
											<td style={{ width: "2em",fontFamily: "Arial" }}>{index + 1}</td>
											<td style={fontStyle}>{item?.riskNumber}</td>
											<td style={colTwo}>{item?.casuse?.slice(0, 15)}</td>
											<td style={colTwo}>{item?.riskEvent}</td>
											<td style={colTwo}>{item?.riskImpact}</td>
											<td style={fontStyle}>{item?.riskOwnerDetails[0]?.name || ""}</td>
											<td style={fontStyle}>{moment(item?.startDate).format("DD MMM YYYY")}</td>
											<td style={fontStyle}>{moment(item?.endDate).format("DD MMM YYYY")}</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
		</div>
		<div
        style={{ marginBottom: "30px" }}
        className="headerForRiskDetails topHeaderWrapper sticky-top d-flex justify-content-between align-items-center"
        id="topheader"
      >
        <div></div>
        <div className="d-flex gap-3 align-items-center justify-content-end">
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="transparent"
              className="p-0 border-0"
            ></Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="transparent"
              className="p-0 border-0"
            >
              <div className="d-flex gap-2 align-items-center">
                <div className="text-end">
                  <h5 class="mb-0 fs-16 fw-bold lh-1">{profileInfo?.name}</h5>
                  <p className="mb-0 fs-11 text-dark lh-base mt-1" style={{fontSize:"9px"}}>
                    {profileInfo?.email.toLowerCase()}
                  </p>
                </div>
                <div>
                  <div className="nameCircle" style={{fontSize:"9px"}}>
                    {profileInfo?.name.split(" ")[0].charAt(0)}
                    {profileInfo?.name.split(" ")[1].charAt(0)}
                  </div>
                </div>
              </div>
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>
      <div style={{ display: "none" }} id="endfooter">
        <div className="topHeaderWrapper">
          <div>
            <h6 style={{fontSize:"10px"}}>Date: {currentDate}</h6>
            <h6 style={{fontSize:"10px"}}>Time: {currentTime}</h6>
          </div>
          <img src={LOGO} width="80" alt="Logo" />
        </div>
      </div>
		</>
	);
};

export default TimeLineRisk;
