import React from "react";
import { Link, NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../assets/images/EPRisk-logo.png";
import Contract from "../assets/icons/contract.png";
import Invitations from "../assets/icons/Invitations.png";
import CMS from "../assets/icons/CMS.png";
import Support from "../assets/icons/Support.png";

const Icon = ({ src, alt }) => <img src={src} alt={alt} />;

const LeftSidebar = () => {
  const links = [
    { to: "/contracts", icon: Contract, text: "Contracts" },

    // { to: "/risk-manager", icon: Risk, text: "Risk Managers" },
    // { to: "/engineers", icon: Eng, text: "Engineers" },
    // { to: "/saved-reports", icon: Saved, text: "Saved Reports" },
    { to: "/platform-support", icon: Support, text: "Platform Support" },
    { to: "/invitations", icon: Invitations, text: "Invitations" },
    { to: "/cms", icon: CMS, text: "CMS" },
  ];

  return (
    <>
      <Navbar key="lg" expand="lg" className="justify-content-lg-end justify-content-between">
          <Link className="navbar-brand mx-0 py-0" to="/contracts">
            <img src={Logo} alt="logo" className="img-contain" />
          </Link>
        <Navbar.Toggle aria-controls={`offcanvasNavbarLabel-expand-lg`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-column w-100">
              {links.map((link) => (
                <NavLink  
                  key={link.to}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to={link.to}
                >
                  <Icon src={link.icon} alt={link.text} /> {link.text}
                </NavLink>
              ))}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
};

export default LeftSidebar;
