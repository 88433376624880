import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUpdateEmailMutation } from "../services/apis";
import { PopUp } from "../utils/alert";
import { handleErrors } from "../utils/error";
import Loader from "../utils/loader";
import { useNavigate } from "react-router-dom";

const ChangeEmail = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(false);
  const [email, setEmail] = useState("");

  const [updateEmail, { data, error, isLoading }] = useUpdateEmailMutation();

  const updateEmailHandler = () => {
    if (!email) {
      PopUp("Please enter a email", "", "error");
      return;
    }
    updateEmail({ email });
  };

  useEffect(() => {
    if (data?.success) {
      PopUp("Email updated successfully", "", "success");
      localStorage.clear();
      navigate("/");
    }
    handleErrors(error);
  }, [data, error]);
  const showPassword = () => setPassword(true);
  const closePassword = () => setPassword(false);
  return (
    <>
      <div className="row mt-3">
        <div className="col-md-5 mx-auto">
          <div className="changePassword">
            <div class="input-group mb-3">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                class="form-control rounded-pill border"
                placeholder="Enter New Email*"
              />
            </div>
            <div class="input-group mt-4">
              <a
                class="btn btn-primary rounded-pill w-100 justify-content-center"
                onClick={updateEmailHandler}
              >
                {isLoading ? <Loader /> : "Change Email"}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeEmail;
