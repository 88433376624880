import React, { useState, useEffect } from "react";
const Matrix = ({ selectedColor,initialData,defaultValue, getMatrix, dataList, edit = false }) => {

  const [matrixData, setMatrixData] = useState(initialData || []);


  const [defaultColors, setDefaultColors] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    // Set initial matrix data based on defaultValue or initialData
    const initialMatrixData = defaultValue ? [
      { cellId: 1, color: "yellow", isFilled: true },
      { cellId: 2, color: "amber", isFilled: true },
      { cellId: 3, color: "amber", isFilled: true },
      { cellId: 4, color: "red", isFilled: true },
      { cellId: 5, color: "red", isFilled: true },
      { cellId: 6, color: "yellow", isFilled: true },
      { cellId: 7, color: "yellow", isFilled: true },
      { cellId: 8, color: "amber", isFilled: true },
      { cellId: 9, color: "amber", isFilled: true },
      { cellId: 10, color: "red", isFilled: true },
      { cellId: 11, color: "green", isFilled: true },
      { cellId: 12, color: "yellow", isFilled: true },
      { cellId: 13, color: "yellow", isFilled: true },
      { cellId: 14, color: "amber", isFilled: true },
      { cellId: 15, color: "amber", isFilled: true },
      { cellId: 16, color: "light-green", isFilled: true },
      { cellId: 17, color: "green", isFilled: true },
      { cellId: 18, color: "yellow", isFilled: true },
      { cellId: 19, color: "yellow", isFilled: true },
      { cellId: 20, color: "yellow", isFilled: true },
      { cellId: 21, color: "light-green", isFilled: true },
      { cellId: 22, color: "light-green", isFilled: true },
      { cellId: 23, color: "green", isFilled: true },
      { cellId: 24, color: "yellow", isFilled: true },
      { cellId: 25, color: "yellow", isFilled: true },
    ] : initialData || [];
    setMatrixData(initialMatrixData);
}, [defaultValue, initialData]);
  
  const toggleBoxSelection = (cellId) => {
    console.log('cellId',cellId)
    if (selectedIds.includes(cellId)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== cellId));
    } else {
      setSelectedIds([...selectedIds, cellId]);
    }
  };

  const updateMatrixData = () => {
    const updatedMatrixData =
      matrixData &&
      matrixData?.map((box) => ({
        ...box,
        isFilled: selectedIds.includes(box.cellId),
        color: selectedIds.includes(box.cellId)
          ? selectedColor
          : defaultColors[box.cellId] || "white",
          // : defaultColors[box.cellId] || "white",
        yAxis: ((box.cellId - 1) % 5) + 1,
        xAxis: 4 - Math.floor((box.cellId - 1) / 5) + 1,
      }));
    setMatrixData(updatedMatrixData);
  };

  useEffect(() => {
    updateMatrixData();
  }, [selectedColor, selectedIds, defaultValue]);

  useEffect(() => {
    setSelectedIds([]);
  }, [selectedColor]);

  useEffect(() => {
    const initialDefaultColors = {};
    matrixData?.forEach((box) => {
      initialDefaultColors[box.cellId] = box.color;
    });
    setDefaultColors(initialDefaultColors);
  }, [selectedColor]);

  useEffect(() => {
    getMatrix(matrixData);
  }, [selectedIds, matrixData]);


  return (
<div
  style={{
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    width: "98%",
    height: "98%",
    aspectRatio: 2 / 2,
    justifyContent: "flex-end", // Align the grid to the right
  }}
>
  {matrixData?.length > 0 &&
    matrixData?.map((box) => (
      <div
        key={box.cellId}
        className={` bg-${
          box.color == "white" ? "light" : box.color
        } box`}
        style={{
          border: "0.5px solid #f5f8fb",
          width: "45px",
          height: "45px",
          margin: "auto",
        }}
        onClick={() => toggleBoxSelection(box.cellId)}
      ></div>
    ))}
</div>
  );
};

export default Matrix;
