import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import User from "../assets/icons/ProfileUser.svg";
import CreateRiskMatrix from "../components/modals/CreateRiskMatrix";
import CreateProjectManagerModal from "../components/modals/CreateProjectManagerModal";
import { useGetProjectUserQuery } from "../services/apis";

import {
	useGetAllRiskListQuery,
	useGetProjectDetailQuery,
} from "../services/apis";
import RiskDrop from "../components/RiskDrop";
import MitigationModal from "../components/modals/MitigationModal";
import { useParams } from "react-router-dom";
import ShowRiskMatrix from "../components/ShowRiskMatrix";
import { useAddRiskMutation } from "../services/apis";
import { PopUp } from "../utils/alert";
import { handleErrors } from "../utils/error";
import Loader from "../utils/loader";
import { useNavigate } from "react-router-dom";
import CreateRiskOwner from "../components/modals/CreateRiskOwner";
import { useDispatch } from "react-redux";
import { setRiskLinks } from "../services/slices/constants";
import Breadcrumbs from "../components/Breadcrumbs";

const AddNewRisk = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [riskMatrix, setRiskMatrix] = useState(false);
	const [mitiShow, setMitiShow] = useState(false);
	const [userData, setUserData] = useState([]);
	const [riskStatus, setRiskStatus] = useState([]);
	const [likeVal, setLikeVal] = useState({
		value: "",
		position: 0,
		description: "",
	});
	const [impactVal, setImpactVal] = useState({
		value: "",
		position: 0,
		description: "",
	});

	const [timeVal, setTimeVal] = useState({
		value: "",
		position: 0,
		description: "",
	});

	const [customVal, setCustomVal] = useState({
		value: "",
		position: 0,
		description: "",
	});
	const [riskType, setRiskType] = useState([]);
	const [riskSubType, setRiskSubType] = useState([]);
	const [riskSubTypeOrg, setRiskSubTypeOrg] = useState([]);
	const [riskSubTypeProject, setRiskSubTypeProject] = useState([]);
	const [riskSubTypeExternal, setRiskSubTypeExternal] = useState([]);
	const [projectPhase, setProjectPhase] = useState([]);
	const [projectAllocation, setProjectAllocation] = useState([]);
	const [currency, setCurrency] = useState("");
	const [skip, setSkip] = useState(true);
	const [riskId, setRiskId] = useState("");
	const [title, setTitle] = useState("");
	const [riskOwner, setRiskOwner] = useState({
		name: "",
		value: "",
	});
	const [riskStatusId, setRiskStatusId] = useState("");
	const [riskTypeId, setRiskTypeId] = useState("");
	const [riskSubTypeId, setRiskSubTypeId] = useState("");
	const [riskTypeOrgId, setRiskTypeOrgId] = useState("");
	const [riskTypeProjectId, setRiskTypeProjectId] = useState("");
	const [riskTypeExternalId, setRiskTypeExternalId] = useState("");
	const [projectPhaseId, setProjectPhaseId] = useState("");
	const [allocationId, setAllocationId] = useState("");
	const [riskDate, setRiskDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [comment, setComment] = useState("");
	const [riskCause, setRiskCause] = useState("");
	const [eventRisk, setEventRisk] = useState("");
	const [riskImpact, setRiskImpact] = useState("");
	const [isVerify, setIsVerify] = useState(0);
	const [newData, setNewData] = useState([]);
	const [stateName, setStateName] = useState("");
	const [ownerSearch, setOwnerSearch] = useState("");

	const [userShow, setUserShow] = useState(false);
	const [colors, setColors] = useState("");

	const { data: projectData, refetch: projectRefetch } =
		useGetProjectDetailQuery(id);

	useEffect(() => {
		setNewData(projectData?.data?.result[0]?.riskMatrixId);
	}, [projectData]);

	useEffect(() => {
		setCurrency(newData?.impactType);
	}, [newData]);

	const { data, refetch } = useGetAllRiskListQuery();

	const {
		data: users,

		refetch: userRefetch,
	} = useGetProjectUserQuery();

	const [addRisk, { data: riskData, isLoading, error }] = useAddRiskMutation();

	useEffect(() => {
		userRefetch();
	}, []);

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		const updatedData = users?.data?.filter((item) => item?.userRole !== 0);
		setUserData(updatedData);
	}, [users]);
	const closeRiskMatrix = () => setRiskMatrix(false);

	const [project, setProject] = useState(false);
	const showProject = () => setProject(true);

	useEffect(() => {
		const optionTypes = {
			riskStatus: setRiskStatus,
			riskType: setRiskType,
			riskSubTypeTechnical: setRiskSubType,
			riskSubTypeOrg: setRiskSubTypeOrg,
			riskSubTypeProject: setRiskSubTypeProject,
			riskSubTypeExternal: setRiskSubTypeExternal,
			projectPhase: setProjectPhase,
			projectAllocation: setProjectAllocation,
		};

		data?.data?.forEach((item) => {
			const setter = optionTypes[item?.optionType];
			if (setter) {
				setter(item?.options);
			}
		});
	}, [data]);

	useEffect(() => {
		const newList = data?.data?.filter(
			(item) => item?.optionType === "riskStatus"
		);
		const updatedList = newList[0]?.options?.filter(
			(item) => item?.name === "Draft"
		);
		setRiskStatus(updatedList);
	}, [riskType]);

	const handleIds = (id, type) => {
		const idSetters = {
			1: setRiskStatusId,
			2: setRiskTypeId,
			3: setRiskSubTypeId,
			4: setRiskTypeOrgId,
			5: setRiskTypeProjectId,
			6: setRiskTypeExternalId,
			7: setProjectPhaseId,
			8: setAllocationId,
		};

		const setter = idSetters[type];
		if (setter) {
			setter(id);
		}
	};

	const handleStateName = (title) => {
		setStateName(title);
	};

	const handleRisk = () => {
		const fields = [
			{ value: title, message: "Risk Name is Mandatory" },
			{ value: riskOwner, message: "Select Risk Owner" },
			{ value: riskStatus, message: "Select Risk Status" },
			{ value: riskDate, message: "Risk Date is Mandatory" },
			{ value: riskTypeId, message: "Select Risk Type" },
			{ value: projectPhaseId, message: "Select Project Phase" },
			{ value: allocationId, message: "Select Contractual Allocation" },
			{ value: riskCause, message: "Risk Cause is mandatory" },
			{ value: eventRisk, message: "Risk Event is mandatory" },
			{ value: riskImpact, message: "Risk Impact is mandatory" },
			{ value: startDate, message: "Risk Start Date is mandatory" },
			{ value: endDate, message: "Risk End Date is mandatory" },
			// { value: comment, message: "Comment is mandatory" },
		];

		if (stateName === "Technical" && !riskSubTypeId) {
			PopUp("Risk Subtype (Technical) is mandatory", "", "error");
			return;
		}

		if (stateName === "Organizational" && !riskTypeOrgId) {
			PopUp("Risk Subtype (Technical) is mandatory", "", "error");
			return;
		}

		if (stateName === "Project Management" && !riskTypeProjectId) {
			PopUp("Risk Subtype (Technical) is mandatory", "", "error");
			return;
		}

		if (stateName === "External" && !riskTypeExternalId) {
			PopUp("Risk Subtype (Technical) is mandatory", "", "error");
			return;
		}

		for (const field of fields) {
			if (!field.value) {
				PopUp(field.message, "", "error");
				return;
			}
		}

		if (new Date(endDate) < new Date(startDate)) {
			PopUp("End date cannot be earlier than the start date", "", "error");
			return;
		}

		if (new Date(endDate) < new Date(riskDate)) {
			PopUp("End date cannot be earlier than the risk reminder date", "", "error");
			return;
		}

		const scaleArr = [impactVal, timeVal, customVal];

		let maxPosition = -Infinity;
		let objectWithMaxPosition = null;

		for (const obj of scaleArr) {
			if (obj.position > maxPosition) {
				maxPosition = obj.position;
				objectWithMaxPosition = obj;
			}
		}

		const riskObject = {
			riskName: title,
			riskOwner: riskOwner.value,
			riskStatus: riskStatusId,
			riskDate,
			riskType: riskTypeId,
			projectPhase: projectPhaseId,
			contAllocation: allocationId,
			casuse: riskCause,
			riskEvent: eventRisk,
			riskImpact: riskImpact,
			startDate,
			endDate,
			comment,
			projectId: id,
			riskMatrixId: newData?._id,
			costName: impactVal?.description,
			costScore: impactVal?.value || impactVal?.impact,
			costPosition: impactVal?.position,
			timeName: timeVal?.description,
			timeScore: timeVal?.value || timeVal?.impact,
			timePosition: timeVal?.position,
			probabilityName: likeVal?.description,
			probabilityValue: likeVal?.value || likeVal?.likelihood,
			consequenceName: objectWithMaxPosition?.description,
			consequenceValue: objectWithMaxPosition?.value || impactVal?.impact,
			riskMatrixValue: {
				xAxis: likeVal?.position,
				yAxis: Math.max(
					impactVal?.position || 1,
					timeVal?.position || 1,
					customVal?.position || 1
				),
				riskValue: colors?.description,
				color: colors?.color,
			},
		};
		console.log("colors", colors);
		if (
			projectData?.data?.result?.length > 0 &&
			projectData?.data?.result[0]?.riskMatrixId?.isCustomeImpact
		) {
			riskObject.customName = customVal?.description;
			riskObject.customPosition = customVal?.position;
		}

		switch (stateName) {
			case "Technical":
				riskObject.techRisk = riskSubTypeId;
				break;
			case "Organizational":
				riskObject.orgRisk = riskTypeOrgId;
				break;
			case "Project Management":
				riskObject.pmRisk = riskTypeProjectId;
				break;
			case "External":
				riskObject.externalRisk = riskTypeExternalId;
				break;
			default:
				riskObject.orgRisk = riskTypeOrgId;
				break;
		}

		addRisk(riskObject);
	};

	useEffect(() => {
		if (riskData?.success) {
			PopUp("Risk added successfully", "", "success");
			navigate(`/view-project/${id}`);
		}
		handleErrors(error);
	}, [riskData, error]);

	useEffect(() => {
		const updatedData = users?.data?.filter((item) =>
			item?.name.includes(ownerSearch.toLowerCase())
		);
		setUserData(updatedData);
	}, [ownerSearch]);

	useEffect(() => {
		setSkip(true);
		setIsVerify(0);
	}, [riskId]);

	useEffect(() => {
		setLikeVal(
			newData?.likelihoodDetail?.length > 0 && newData?.likelihoodDetail[0]
		);
		setImpactVal(
			newData?.costImpactDetail?.length > 0 && newData?.costImpactDetail[0]
		);
		setCustomVal(
			newData?.customImpactDetail?.length > 0 && newData?.customImpactDetail[0]
		);
		setTimeVal(
			newData?.timeImpactDetail?.length > 0 && newData?.timeImpactDetail[0]
		);
	}, [newData]);

	useEffect(() => {
		const yValue = Math.max(
			impactVal?.position || 1,
			timeVal?.position || 1,
			customVal?.position || 1
		);
		const matrix = [
			[21, 22, 23, 24, 25],
			[16, 17, 18, 19, 20],
			[11, 12, 13, 14, 15],
			[6, 7, 8, 9, 10],
			[1, 2, 3, 4, 5],
		];

		function getValueAt(matrix, rowIndex, columnIndex) {
			if (
				matrix &&
				Array.isArray(matrix) &&
				matrix.length > rowIndex &&
				Array.isArray(matrix[rowIndex]) &&
				matrix[rowIndex].length > columnIndex
			) {
				return matrix[rowIndex][columnIndex];
			} else {
				return undefined;
			}
		}

		console.log("indices", yValue - 1);
		const value = getValueAt(matrix, likeVal?.position - 1, yValue - 1);
		const updatedColor = newData?.matrix?.filter(
			(item) => item?.cellId === value
		);
		const colors = newData?.colorDescription?.filter(
			(item) => item.color === updatedColor[0]?.color
		);
		console.log("value", value);
		console.log("VGVGVG", newData);
		console.log("first, updatedColor", updatedColor);
		setColors(!!colors?.length && colors[0]);
	}, [likeVal, impactVal, timeVal, customVal]);

	return (
		<>
			<div className="seachBarWIthButton d-flex justify-content-between align-items-center">
				<h6 className="section-heading mb-0">ADD A NEW RISK</h6>

			</div>
			<div className="row mt-4">
				<div className="col-md-6 row h-fit-col px-0 mx-0">
					{isVerify === 2 && (
						<p style={{ color: "red", fontSize: "12px" }}>
							Risk id is already exist
						</p>
					)}

					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">Risk Title</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<input
							type="text"
							value={title}
							placeholder="Risk Title"
							onChange={(e) => setTitle(e.target.value)}
							className="input-1 w-100"
						/>
					</div>

					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">Risk Owner</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<Dropdown className="w-100">
							<Dropdown.Toggle
								className="input-1 w-100 dropWithArrow2 px-3"
								id="dropdown-basic"
							>
								{riskOwner?.name || "Risk Owner"}
							</Dropdown.Toggle>

							<Dropdown.Menu className="w-100 br-14 py-2 px-3">
								<input
									type="search"
									placeholder="Search user"
									value={ownerSearch}
									onChange={(e) => setOwnerSearch(e.target.value)}
								/>
								<div className="scroll-120 thin-scroll mt-2">
									{!!userData?.length &&
										userData?.map((item) => {
											return (
												<label
													htmlFor={item?._id}
													className="customRadio d-flex align-items-center gap-2"
													key={item?._id}
												>
													<input
														type="radio"
														name="demoradio"
														id={item?._id}
														value={riskOwner}
														onChange={(e) =>
															setRiskOwner({
																name: item?.name,
																value: item?._id,
															})
														}
													/>
													<img src={User} alt="user" />
													<h6 className="mb-0">
														{item?.name}{" "}
														<small>
															{item?.userRole === 1
																? "Risk Manager"
																: item?.userRole === 2
																	? "Engineer"
																	: ""}
														</small>
													</h6>
												</label>
											);
										})}
								</div>
								<Link
									className="fs-12-600-link mt-2 text-center d-block"
									onClick={() => setUserShow(true)}
								>
									+ Create new user
								</Link>
							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">Contractual Allocation</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<RiskDrop
							list={projectAllocation}
							listTitle="Contractual Allocation"
							state={handleIds}
							numId={8}
						/>
					</div>


					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">Risk Type</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<RiskDrop
							list={riskType}
							listTitle="Risk Type"
							state={handleIds}
							numId={2}
							stateName={handleStateName}
						/>
					</div>
					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">Risk Status</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<RiskDrop
							list={riskStatus}
							listTitle="Risk Status"
							state={handleIds}
							numId={1}
							add
						/>
					</div>
					{stateName === "Technical" && (
						<div className="mb-4 col-md-6">
							<label className="cf_label" htmlFor="">Risk Subtype (Technical)</label>{" "}
							{/* <span style={{ color: "red" }}>*</span> */}
							<RiskDrop
								list={riskSubType}
								listTitle="Risk Subtype (Technical)"
								state={handleIds}
								numId={3}
							/>
						</div>
					)}

					{stateName === "Organizational" && (
						<div className="mb-4 col-md-6">
							<label className="cf_label" htmlFor="">Risk Subtype (Organizational)</label>{" "}
							{/* <span style={{ color: "red" }}>*</span> */}
							<RiskDrop
								list={riskSubTypeOrg}
								listTitle="Risk Subtype (Organizational)"
								state={handleIds}
								numId={4}
							/>
						</div>
					)}

					{stateName === "Project Management" && (
						<div className="mb-4 col-md-6">
							<label className="cf_label" htmlFor="">Risk Subtype (Project Mgmt)</label>{" "}
							{/* <span style={{ color: "red" }}>*</span> */}
							<RiskDrop
								list={riskSubTypeProject}
								listTitle="Risk Subtype (Project Mgmt)"
								state={handleIds}
								numId={5}
							/>
						</div>
					)}

					{stateName === "External" && (
						<div className="mb-4 col-md-6">
							<label className="cf_label" htmlFor="">Risk Subtype (External)</label>{" "}
							{/* <span style={{ color: "red" }}>*</span> */}
							<RiskDrop
								list={riskSubTypeExternal}
								listTitle="Risk Subtype (External)"
								state={handleIds}
								numId={6}
							/>
						</div>
					)}

					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">Project Phase</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<RiskDrop
							list={projectPhase}
							listTitle="Project Phase"
							state={handleIds}
							numId={7}
						/>
					</div>



					
					<div className="mb-4 col-md-12">
						<label className="cf_label" htmlFor="">Risk Description</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<textarea
							name=""
							id=""
							rows="3"
							className="textarea-1 w-100"
							placeholder="Brief description of the possible event caused by the risk (max 250 characters)"
							value={eventRisk}
							onChange={(e) => setEventRisk(e.target.value)}
						></textarea>
					</div>
					<div className="mb-4 col-md-12">
						<label className="cf_label" htmlFor="">Cause of Risk</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<textarea
							name=""
							id=""
							rows="3"
							className="textarea-1 w-100"
							placeholder="Brief description of the cause (max 250 characters)"
							value={riskCause}
							onChange={(e) => setRiskCause(e.target.value)}
						></textarea>
					</div>
					<div className="col-md-12">
						<label className="cf_label" htmlFor="">Risk Impact</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<textarea
							name=""
							id=""
							rows="3"
							className="textarea-1 w-100"
							placeholder="Brief description of the possible impact caused by the risk (max 250 characters)"
							value={riskImpact}
							onChange={(e) => setRiskImpact(e.target.value)}
						></textarea>
					</div>
				</div>
				<div className="col-md-6 row h-fit-col px-0 mx-0">
					<div className="col-md-12 mb-4"><span className="h-80 d-block"></span></div>
					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">Start Date</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<input
							type="date"
							className="input-1 w-100"
							value={startDate}
							// min={riskDate}
							onChange={(e) => setStartDate(e.target.value)}
							required
						/>
					</div>
					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">End Date</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<input
							type="date"
							className="input-1 w-100"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							min={startDate}
							required
						/>
					</div>
					<div className="mb-4 col-md-6">
					<span class="h-80 d-block"></span>
					</div>
					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">Set a Reminder Date</label>{" "}
						{/* <span style={{ color: "red" }}>*</span> */}
						<input
							type="date"
							className="input-1 w-100"
							value={riskDate}
							onChange={(e) => setRiskDate(e.target.value)}
							max={endDate}
							required
						/>
					</div>
					{/* <div className="mb-4 col-md-4"></div> */}
					<div className="mb-4 col-md-6">
						<label className="cf_label" htmlFor="">Comments</label>{" "}
						<textarea
							value={comment}
							onChange={(e) => setComment(e.target.value)}
							rows="10"
							className="textarea-1 w-100"
							placeholder="Max 200 charaters"
						></textarea>
					</div>
					<div className="mb-4 col-md-6">
						<div className="mb-4">
							<div>
								<label className="cf_label" htmlFor="">
									Value for Level of likelihood{" "}
									{/* <span style={{ color: "red" }}>*</span> */}
								</label>
								<div
									class="btn-group  border-0 overflow-hidden mt-1 w-100 justify-content-between"
									role="group"
									aria-label="Basic outlined example"
									style={{ padding: "0px" }}
								>
									{!!newData?.likelihoodDetail?.length &&
										newData?.likelihoodDetail?.map((item, index) => {
											return (
												<input
													type="text"
													key={item}
													style={{
														background:
															likeVal?.position ===
															(likeVal?.position === 0
																? index
																: item?.position) && "#d9d9d9",
														cursor: "pointer",
													}}
													className="form-control text-center"
													value={item?.likelihood}
													onClick={() =>
														setLikeVal({
															value: item?.likelihood,
															position: item?.position,
															description: item?.description,
														})
													}
												/>
											);
										})}
								</div>
							</div>
							{/* <p
								style={{
									fontSize: "15px",
									fontWeight: "bold",
									marginTop: "6px",
									textAlign: "center",
								}}
							>
								{likeVal?.description}
							</p> */}
						</div>

						<div className="mb-4">
							<div>
								<label className="cf_label" htmlFor="">
									Value for Level of impact on <strong className="text-uppercase">Cost</strong>{" "}
									{/* <span style={{ color: "red" }}>*</span> */}
								</label>
								<div
									class="btn-group  border-0 overflow-hidden mt-1 w-100  justify-content-between"
									role="group"
									aria-label="Basic outlined example"
									style={{ padding: "0px" }}
								>
									{/* <select
										className="form-control text-primary fw-bold text-center rounded-0 border-0 border-end"
										value={currency}
										onChange={(e) => setCurrency(e.target.value)}
									>
										<option value="dollar">$</option>
										<option value="percent">%</option>
									</select> */}
									{!!newData?.costImpactDetail?.length &&
										newData?.costImpactDetail?.map((item, index) => {
											return (
												<input
													type="text"
													key={item?.impact}
													className="form-control text-center "
													style={{
														background:
															impactVal?.position ===
															(impactVal?.position === 0 ? index : index + 1) &&
															"#d9d9d9",
														cursor: "pointer",
													}}
													value={item?.impact}
													onClick={() =>
														setImpactVal({
															value: item?.impact,
															position: item?.position,
															description: item?.description,
														})
													}
												/>
											);
										})}
								</div>
							</div>
							{/* <p
								style={{
									fontSize: "15px",
									fontWeight: "bold",
									marginTop: "6px",
									textAlign: "center",
								}}
							>
								{impactVal?.description}
							</p> */}
						</div>

						<div className="mb-4">
							<div>
								<label className="cf_label" htmlFor="">
									Value for Level of impact on <strong className="text-uppercase">Schedule</strong>{" "}
									{/* <span style={{ color: "red" }}>*</span> */}
								</label>
								<div
									class="btn-group border-0 overflow-hidden mt-1 w-100 justify-content-between"
									role="group"
									aria-label="Basic outlined example"
									style={{ padding: "0px" }}
								>
									{/* <select
										className="form-control text-primary fw-bold text-center rounded-0 border-0 border-end"
										value={currency}
										onChange={(e) => setCurrency(e.target.value)}
									>
										<option value="dollar">$</option>
										<option value="percent">%</option>
									</select> */}
									{!!newData?.timeImpactDetail?.length &&
										newData?.timeImpactDetail?.map((item, index) => {
											return (
												<input
													type="text"
													key={item?.impact}
													className="form-control text-center "
													style={{
														background:
															timeVal?.position ===
															(timeVal?.position === 0 ? index : index + 1) &&
															"#d9d9d9",
														cursor: "pointer",
													}}
													value={item?.impact}
													onClick={() =>
														setTimeVal({
															value: item?.impact,
															position: item?.position,
															description: item?.description,
														})
													}
												/>
											);
										})}
								</div>
							</div>
							{/* <p
								style={{
									fontSize: "15px",
									fontWeight: "bold",
									marginTop: "6px",
									textAlign: "center",
								}}
							>
								{timeVal?.description}
							</p> */}
						</div>
						{newData?.isCustomeImpact && (
							<div className="mb-4">
								<div>
									<label className="cf_label" htmlFor="">
										Value for Level of impact on <strong className="text-uppercase">{newData?.customImpactTextDetail}</strong>{" "}
										{/* <span style={{ color: "red" }}>*</span> */}
									</label>
									<div
										class="btn-group  border-0 overflow-hidden mt-1 w-100 justify-content-between"
										role="group"
										aria-label="Basic outlined example"
										style={{ padding: "0px" }}
									>
										{!!newData?.customImpactDetail?.length &&
											newData?.customImpactDetail?.map((item, index) => {
												return (
													<input
														type="text"
														key={item}
														style={{
															background:
																customVal?.position ===
																(customVal?.position === 0
																	? index
																	: index + 1) && "#d9d9d9",
															cursor: "pointer",
														}}
														className="form-control text-center"
														value={item?.impact}
														onClick={() =>
															setCustomVal({
																value: item?.impact,
																position: item?.position,
																description: item?.description,
															})
														}
													/>
												);
											})}
									</div>
								</div>
								{/* <p
								style={{
									fontSize: "15px",
									fontWeight: "bold",
									marginTop: "6px",
									textAlign: "center",
								}}
							>
								{customVal?.description}
							</p> */}
							</div>
						)}
					</div>

					<div className="d-flex gap-2 justify-content-end mt-4">
						<button
							className="btn btn-outline-primary rounded-pill fs-14-normal px-3 h-48 col-md-4"
							onClick={() => navigate(`/view-project/${id}`)}
						>
							CANCEL
						</button>
						<button
							className="btn btn-trinary rounded-pill fs-14-normal px-3 h-48 col-md-4"
							onClick={handleRisk}
						>
							{isLoading ? <Loader /> : "SAVE"}
						</button>
					</div>

					{/* <div className="col-md-3" style={{ position: "relative" }}>
					<div style={{ position: "sticky", top: "100px" }}>
						<h6 class="fs-14-normal text-end mb-0 lh-1 mt-1">
							Base Risk: {likeVal?.position * impactVal?.position}
						</h6>
						<div>
							<ShowRiskMatrix
								data={newData?.matrix}
								column={likeVal?.position}
								row={Math.max(
									impactVal?.position || 0,
									timeVal?.position || 0,
									customVal?.position || 0
								)}
							/>
							<p className="text-center d-flex justify-content-center align-items-center"
                              style={{
                                marginTop: "1em",
                                borderRadius: "6px",
                                padding: "0.5em 1em",
                                fontSize: "12px",
                                backgroundColor: '#d9d9d9',
                                height: '46px',
                              }}>{colors?.description}</p>
						</div>
					</div>
				</div> */}
				</div>
			</div>
			<MitigationModal show={mitiShow} close={() => setMitiShow(false)} />
			<CreateRiskMatrix show={riskMatrix} close={closeRiskMatrix} />
			<CreateProjectManagerModal show={project} close={showProject} />
			<CreateRiskOwner
				show={userShow}
				close={() => setUserShow(false)}
				data={{}}
			/>
		</>
	);
};

export default AddNewRisk;
