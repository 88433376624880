import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CreateRiskManager from "../components/modals/CreateRiskManager";
import BlockModal from "../components/modals/BlockModal";
import moment from "moment";
import {
  useGetAllInvitesMutation,
  useInviteAcceptMutation,
} from "../services/apis";
import { PopUp } from "../utils/alert";
import NoData from "../components/NoData";
import Spinner from "../components/Spinner";

const Invitation = () => {
  const id = useSelector((state) => state?.constants?.profileInfo);

  const [risk, setRisk] = useState(false);
  const [newData, setNewdata] = useState([]);
  const [status, setStatus] = useState("");
  const [inviteId, setInviteId] = useState("");
  const [search, setSearch] = useState("");

  const [showToggle, setShowToggle] = useState(false);
  const [statusId, setStatusId] = useState("");

  const [getAllInvites, { data, isLoading, error }] =
    useGetAllInvitesMutation();

  const [
    inviteAccept,
    { data: acceptData, isLoading: acceptLoading, error: acceptErr },
  ] = useInviteAcceptMutation();

  const handleFn = () => {
    const objectInvitation = {
      id: id?.data?._id,
    };

    if (search !== "") {
      objectInvitation.search = search;
    }
    getAllInvites(objectInvitation);
  };

  useEffect(() => {
    handleFn();
  }, [id, search]);

  useEffect(() => {
    setNewdata(data?.data?.data);
  }, [data]);

  useEffect(() => {
    if (acceptData?.success) {
      PopUp(
        "Status changes successfully",
        "Please check your inbox for further information",
        "success"
      );
      const updatedData = newData?.map((item) => {
        if (item._id === inviteId) {
          return { ...item, isReject: status === 1 ? true : false };
        }
        return item;
      });
      setNewdata(updatedData);
      handleFn();
    }
  }, [acceptData]);

  const handleAccept = async (status, id) => {
    setStatus(status);
    setInviteId(id);
    await inviteAccept({ id, status: status });
  };

  const closeRisk = () => setRisk(false);

  const [block, setBlock] = useState(false);
  const closeBlock = () => setBlock(false);

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="seachBarWIthButton d-flex justify-content-between align-items-center">
            <input
              type="search"
              placeholder="Search"
              className="rounded-pill searchBar"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="br-10 mt-3">
              {newData?.length === 0 ? (
                <NoData />
              ) : (
                <table class="table table-style-1 mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>User Role</th>
                      <th>User Type</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!newData?.length &&
                      newData?.map((item) => {
                        return (
                          <tr key={item?._id}>
                            <td>{item?.name}</td>
                            <td>{item?.email}</td>
                            <td>
                              {item?.userRole === 0
                                ? "Project Manager"
                                : item?.userRole === 1
                                ? "Risk Manager"
                                : "Engineer"}
                            </td>
                            <td>
                              {" "}
                              {item?.userType === 0
                                ? "Internal"
                                : item?.userType === 1
                                ? "External"
                                : ""}
                            </td>
                            <td>
                              {moment(item?.inviteDate).format("DD MMM YYYY")}
                            </td>
                            <td>
                              <div
                                className="dropdown"
                                onClick={() => {
                                  setShowToggle(!showToggle);
                                  setStatusId(item?._id);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {item?.status === "Pending" ? (
                                  <button
                                    type="button"
                                    className="badge badge-secondary dropdown-toggle px-2 py-1"
                                    style={{
                                      border: "none",
                                      background: "#e7e7e7",
                                      color: "#000",
                                    }}
                                    data-toggle="dropdown"
                                  >
                                    {acceptLoading ? "Loading.." : "Pending"}
                                  </button>
                                ) : item?.status === "Rejected" ? (
                                  <button
                                    type="button"
                                    className="badge badge-danger dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    {acceptLoading ? "Loading.." : "Rejected"}
                                  </button>
                                ) : item?.status === "Accepted" ? (
                                  <button
                                    type="button"
                                    className="badge badge-success dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    {acceptLoading ? "Loading.." : "Resolved"}
                                  </button>
                                ) : null}

                                <div
                                  className={`dropdown-menu p-0 ${
                                    item?._id === statusId && showToggle
                                      ? "show"
                                      : ""
                                  }`}
                                >
                                  <a
                                    className="dropdown-item px-3 py-2 font-14"
                                    onClick={() =>
                                      handleAccept("Accepted", item?._id)
                                    }
                                  >
                                    <span className="badge-success mr-2 wh-12" />{" "}
                                    Resolved
                                  </a>
                                  <a
                                    className="dropdown-item px-3 py-2 font-14"
                                    onClick={() =>
                                      handleAccept("Rejected", item?._id)
                                    }
                                  >
                                    <span className="badge-danger mr-2 wh-12" />{" "}
                                    Reject
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          )}
          {/* Pagination */}
        </div>
      </div>
      <CreateRiskManager show={risk} close={closeRisk} />
      <BlockModal show={block} close={closeBlock} />
    </>
  );
};

export default Invitation;
