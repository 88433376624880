import React from "react";
import LOGO from "../assets/images/EPRisk-logo.png";
import moment from "moment";

const FooterForPDF = ({ margin }) => {
  const currentDate = new Date().toLocaleDateString();
  const currentTime = new Date().toLocaleTimeString();
  return (
    <div style={{marginTop:margin}}>
      <div className="topHeaderWrapper d-flex justify-content-between align-items-center">
        <img src={LOGO} width="120" alt="Logo" />
        <div style={{ height: "100%", marginTop: "5%" }}>
          <h6>Date: {currentDate}</h6>
          <h6>Time: {currentTime}</h6>
        </div>
      </div>
    </div>
  );
};

export default FooterForPDF;
