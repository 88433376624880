import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetNotificationMutation,
  useDeleteNotificationMutation,
} from "../services/apis";
import Spinner from "../components/Spinner";
import moment from "moment";
import { Link } from "react-router-dom";
import Delete from "../assets/icons/delete.svg";
import { PopUp } from "../utils/alert";

const Notifications = () => {
  const profileInfo = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );
  const [list, setList] = useState([]);
  const [delId, setDelId] = useState("");

  const [getNotification, { data: infoData, isLoading: notiLoad }] =
    useGetNotificationMutation();

  const [deleteNotification, { data: delData }] =
    useDeleteNotificationMutation();

  useEffect(() => {
    getNotification({ id: profileInfo?._id, limit: 50 });
  }, []);

  useEffect(() => {
    setList(infoData?.data?.data);
  }, [infoData]);

  const handleDelete = (id) => {
    setDelId(id);
    deleteNotification({ id });
  };

  useEffect(() => {
    if (delData?.success) {
      PopUp("Notification deleted successfully", "", "success");
      const updatedList = list.filter((item) => item?._id !== delId);
      setList(updatedList);
    }
  }, [delData]);
  return (
    <div>
      <h4 className="">Notifications</h4>
      <div className="">
        {notiLoad ? (
          <Spinner />
        ) : (
          <>
            {!!list?.length ? (
              list?.map((item) => {
                return (
                  <div className="grey-box mb-2">
                    <div className="d-flex  align-items-center mb-2">
                      {item?.notificationId?.image && (
                        <img
                          src={item?.notificationId?.image}
                          alt=""
                          style={{
                            width: "130px",
                            marginRight: "20px",
                            borderRadius: "10px",
                          }}
                        />
                      )}

                      <div style={{ marginRight: "auto" }}>
                        <h6 className="fs-13 mb-0">
                          {item?.notificationId?.subject}
                        </h6>
                        <p className="fs-11-06 mb-0">
                          {item?.notificationId?.description}
                        </p>
                        <span className="time fs-11-04">
                          {moment(item?.notificationId?.isCreatedAt).format(
                            "DD MMM YYYY hh:mm"
                          )}
                        </span>
                      </div>
                      <Link onClick={() => handleDelete(item?._id)}>
                        <img src={Delete} alt="" />
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No notification found</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
