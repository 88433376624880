import React, { useState, useEffect } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const ShowMatrix = ({ data,matrixDesc, status }) => {
  const [matrixData, setMatrixData] = useState();
  useEffect(() => {
    setMatrixData(data);
  }, [data]);
console.log('matrixDesc',data)
const getColorDescription = (color) => {
  const foundColor = matrixDesc?.find(desc => desc?.color === color);
  return foundColor ? foundColor.description : color;
}
  return (
    <div className="ms-auto m-0"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        width: status == "viewProject" ? "100%" : "97%",
        height: status == "viewProject" ? "100%" : "97%",
        margin: "auto",
        aspectRatio:2 / 2
      }}
    >
      {matrixData?.length > 0 &&
        matrixData?.map((box) => (
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"  style={{position:"fixed"}}>{getColorDescription(box.color) }</Tooltip>}>
          {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"  style={{position:"fixed"}}>{box.color?.toUpperCase().replace('-',' ')}</Tooltip>}> */}
          <div
            key={box.cellId}
            className={` bg-${box.color} box `}
            style={{
              border: "0.001em solid white",
              width: "100%",
              height: "100%",
            }}
          ></div>
          </OverlayTrigger>
        ))}
    </div>
  );
};
export default ShowMatrix;
