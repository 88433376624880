import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Eye from "../assets/icons/eye-color.svg";
import ReplyModal from "../components/modals/ReplyModal";
import { usePlatformListMutation } from "../services/apis";
import { useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import NoData from "../components/NoData";
import Pagination from "../components/Pagination";
import { PAGE_LIMIT } from "../utils/constants";

const PlatformSupport = () => {
  const senderEmail = useSelector(
    (state) => state?.constants?.profileInfo?.data
  );
  const [reply, setReply] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({});

  const [platformList, { data, isLoading }] = usePlatformListMutation();

  useEffect(() => {
    const obj = {
      userId: senderEmail?._id,
      limit: PAGE_LIMIT,
      page,
    };

    if (fromDate !== "") {
      obj.fromDate = fromDate;
    }

    if (toDate !== "") {
      obj.toDate = fromDate;
    }
    platformList(obj);
  }, [reply, toDate, fromDate, page]);

  useEffect(() => {
    setPageData(data?.data);
  }, [data]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const closeReply = () => setReply(false);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="seachBarWIthButton gap-3 d-flex justify-content-between align-items-end flex-wrap h-auto">
            <div className="d-flex gap-2 flex-sm-nowrap flex-wrap col-md-5 col-12">
              <div className="form-group w-100">
                <label htmlFor="" className="w-100">
                  From
                </label>
                <input
                  type="date"
                  className="input-1 w-100 h-48" 
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="form-group w-100">
                <label htmlFor="" className="w-100">
                  To
                </label>
                <input
                  type="date"
                  className="input-1 w-100 h-48"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            <div className="seachBarWIthButton d-flex justify-content-between align-items-center">
              <Link
                className="btn btn-outline-primary rounded-pill fs-14-normal px-3 h-48 align-items-center d-flex"
                onClick={() => setReply(true)}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1em" }}
                >
                  <i
                    class="fa-solid fa-plus"
                    style={{ fontWeight: "bold" }}
                  ></i>{" "}
                  <span>Add New Query</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="table-responsive br-10 mt-3">
            {isLoading ? (
              <Spinner />
            ) : (
              <table class="table table-style-1 mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "100px" }}>S.No.</th>
                    <th style={{ width: "80%", textAlign: "left" }}>Query</th>
                    <th>Status</th>
                    <th style={{ width: "100px" }}>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {!!data?.data?.data?.length &&
                    data?.data?.data.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td style={{ width: "2em" }}>{index + 1}</td>
                          <td style={{ textAlign: "left" }}>{item?.query}</td>
                          <td>
                            <button
                              className={`btn btn-sm bg-${
                                item?.status === "Rejected"
                                  ? "danger"
                                  : item?.status === "Accepted"
                                  ? "success"
                                  : "secondary"
                              } text-white rounded-pill`}
                              style={{ fontSize: "13px" }}
                            >
                              {item?.status === "Accepted"
                                ? "resolved"
                                : item?.status === "Rejected"
                                ? "rejected"
                                : "pending"}
                            </button>
                          </td>
                          <td>
                            <div className="table-action">
                              <Link
                                to={`/view-ticket/${item?._id}`}
                                className="ms-2"
                              >
                                <img src={Eye} alt="View" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
          {data?.data?.data?.length === 0 && <NoData />}
          {pageData?.totalRecords > PAGE_LIMIT && (
            <Pagination
              data={pageData}
              pageNo={handlePageChange}
              pageVal={page}
            />
          )}
        </div>
      </div>
      <ReplyModal show={reply} close={closeReply} />
    </>
  );
};

export default PlatformSupport;
