import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { useRiskTopNReportMutation } from "../services/apis";
import { Link, useParams } from "react-router-dom";
import NoData from "./NoData";
import { usePDF,Margin } from "react-to-pdf";
import HeaderForPDF from "./HeaderForPDF";
import FooterForPDF from "./FooterForPDF";
import LOGO from "../assets/images/EPRisk-logo.png";
import Dropdown from "react-bootstrap/Dropdown";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const colTwo = {
	whiteSpace: "normal",
	wordWrap: "anywhere",
	fontFamily: "Arial"
	// minWidth: "200px",
};

const fontStyle = {
    fontFamily: "Arial"
}

const ChartOne = () => {
	const { id } = useParams();
	const { toPDF, targetRef } = usePDF({
		filename: `top10risk.pdf`,
		page: {
			margin: Margin.SMALL,
		 },
	});

	const profileInfo = useSelector(
		(state) => state?.constants?.profileInfo?.data
	);
	const [pdfDownloadButton, setPdfDownloadButton] = useState(false);
	const [topRiskchart, setTopRiskchart] = useState("");
	const [status, setStatus] = useState("Factored");

	const [riskTopNReport, { data: info }] = useRiskTopNReportMutation();
	const [list, setList] = useState([]);
	const [refreshPage, setRefreshPage] = useState(false)

	const currentDate = new Date().toLocaleDateString();
  	const currentTime = new Date().toLocaleTimeString();

	useEffect(() => {
		riskTopNReport({
			id,
			type: status,
		});
	}, [status]);

	useEffect(() => {
		const newArray =
			info?.data?.data?.length > 0 &&
			info?.data?.data?.map((item) => {
				return {
					name: item?.riskDetail.riskNumber,
					"Target Evaluation":
						item?.data[2]?.finalEvaluation === null ||
						item?.data[2]?.finalEvaluation === undefined
							? 0
							: item?.data[2]?.finalEvaluation,
					"Initial Evaluation":
						item?.data[0]?.finalEvaluation === null ||
						item?.data[0]?.finalEvaluation === undefined
							? 0
							: item?.data[0]?.finalEvaluation,
					"Current Evalution":
						item?.data[1]?.finalEvaluation === null ||
						item?.data[1]?.finalEvaluation === undefined
							? 0
							: item?.data[1]?.finalEvaluation,
				};
			});
		setList(newArray);
	}, [info]);

// console.log(typeof(list))
// console.log(Object.values(list).sort((a,b) =>  a['Target Evaluation']-b['Target Evaluation'] ));
const sortDecList = Object.values(list).sort((a,b) =>  (b['Target Evaluation']+b['Current Evalution']+b['Initial Evaluation'])-(a['Target Evaluation']+a['Current Evalution']+a['Initial Evaluation']) )


// console.log(sortDecList[1].sort((a,b) => b - a ))

const handleExportToPDF = () => {
		setRefreshPage(true); // Set refreshPage to true
	  };
	  useEffect(() => {
		// Call toPDF when refreshPage is true
		if (refreshPage) {
		  toPDF();
		  setRefreshPage(false); // Reset refreshPage to false after calling toPDF
		}
	  }, [refreshPage, toPDF]);
	  const getFinalEvalution = (data,type) => {
		let getFinal = data.find(fData => fData.assessmentType == type)
		if(getFinal){
			return getFinal.finalEvaluation.toFixed(2)
		}
	  }

	const generatePDF = async () => {
		const doc = new jsPDF({
			orientation: 'p',
			unit: 'mm',
			format: [300, 300],
			compress:true
		   });
		document.getElementById("footer").style.display = "block"
		const content = document.getElementById('header');
		html2canvas(content,{
		  scale: 3, // Increase scale for higher resolution
		  windowWidth: content.offsetWidth,
		  windowHeight: content.offsetHeight,
		  useCORS: true // Enable CORS for cross-origin images
		})
		.then(async (canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdfWidth = doc.internal.pageSize.getWidth();
			const height = content.offsetHeight * (pdfWidth / content.offsetWidth);
			doc.addImage(imgData, 'PNG', 0, 3, pdfWidth, height);

			const header = document.getElementById('content');
			console.log("header",header);
			await html2canvas(header,{
			  scale: 3, // Increase scale for higher resolution
			  windowWidth: header.offsetWidth,
			  windowHeight: header.offsetHeight,
			  useCORS: true // Enable CORS for cross-origin images
			})
			.then(async (canvas) => {
				const imgData = canvas.toDataURL('image/png');
				let pdfWidth = doc.internal.pageSize.getWidth();
				doc.addImage(imgData, 'PNG', 0, 20, pdfWidth, 80);
			})

		  //Second
		  doc.addPage();
			const actionPlan = document.getElementById('content2');
			await html2canvas(actionPlan,{
				scale: 3, // Increase scale for higher resolution
				windowWidth: header.offsetWidth,
				windowHeight: header.offsetHeight,
				useCORS: true // Enable CORS for cross-origin images
			  })
			.then(async(canvas) => {
			  const imgData = canvas.toDataURL('image/png');
			  const pdfWidth = doc.internal.pageSize.getWidth();
			  const height = actionPlan.offsetHeight * (pdfWidth / actionPlan.offsetWidth);
			  doc.addImage(imgData, 'PNG', 0, 10, pdfWidth, height);

				//Footer and Header Added in all Pages
				const footer = document.getElementById("footer")
				const pageCount = doc.internal.getNumberOfPages();
				for (let i = 1; i <= pageCount; i++) {
				  doc.setPage(i);
				  await html2canvas(footer)
				  .then(async(canvas) => {
					const imgData = canvas.toDataURL('image/png');
					const pdfHeight = doc.internal.pageSize.getHeight();
					const imageHeight = canvas.height * (pdfWidth / canvas.width);
					const imageX = 0;
					const imageY = pdfHeight - imageHeight;
					doc.addImage(imgData, 'PNG', imageX, imageY, pdfWidth, 0);
				  })
		  
				  const header = document.getElementById("header")
				  await html2canvas(header).then((canvas) => {
					const headerImgData = canvas.toDataURL('image/png');
					const headerX = 0;
					const headerY = 2;
					doc.addImage(headerImgData, 'PNG', headerX, headerY, pdfWidth, 0);
				  });
				}


			//   const footer = document.getElementById("footer")
			//   await html2canvas(footer)
			// 	.then(async(canvas) => {
			// 	  const imgData = canvas.toDataURL('image/png');
			// 	  const pdfWidth = doc.internal.pageSize.getWidth();
			// 	  const pdfHeight = doc.internal.pageSize.getHeight();
			// 	  const height = actionPlan.offsetHeight * (pdfWidth / actionPlan.offsetWidth);
			// 	  const imageHeight = canvas.height * (pdfWidth / canvas.width);
			// 	  const imageX = 0; // Align left
			// 	  const imageY = pdfHeight - imageHeight; // Align bottom
			// 	  doc.addImage(imgData, 'PNG', imageX, imageY, pdfWidth, 0);
			// 	})
			})
	
		  doc.save(`top10risk.pdf`);
		  document.getElementById("footer").style.display = "none"
		})
		.catch((error) => {
		  console.error('Error generating PDF:', error);
		});
	  };

	  const generatePDFViaHTML = async () => {
		setPdfDownloadButton(true)
		document.getElementById("top10Risk").style.display = "block";
		setTimeout(async () => {      
		  const getChart = document.getElementById("content");
			  if (getChart) {
				await html2canvas(getChart, {
				  scale: 3,
				  useCORS: true,
				}).then((canvas) => {
				  const imgData = canvas.toDataURL("image/png");
				  setTopRiskchart(imgData);
				});
			  }
		}, 1000);
		const waitFor1 = (ms) => new Promise(resolve => setTimeout(resolve, ms));
		await waitFor1(2500);
		let screen = 0
		console.log(window.outerWidth);
		if (window.outerWidth <= 1024) {
		  screen = 1024
		}
		const doc = new jsPDF({
		  orientation: "p",
		  unit: "mm",
		  format: [300, 320],
		  compress: true,
		});
	
		document.getElementById("top10footer").style.display = "block";
		var element = document.getElementById("top10header");
		element.classList.remove("headerForRiskDetails");
	
		const addHtmlToPdf = async (doc, elementId, x, y, width, height) => {
		  const element = document.getElementById(elementId);
		  await doc.html(element, {
			callback: async function (doc) {
			  // Handle additional elements or adjustments here
			},
			x: x,
			y: y,
			width: width,
			windowWidth: width * 3.7795, // Convert mm to px for rendering width
		  });
		};
	
		(async () => {
		  await addHtmlToPdf(doc, "top10Risk", 5, -12, 290, 230);
			
		  

		  const pageCount = doc.internal.getNumberOfPages();
		  for (let i = 1; i <= pageCount; i++) {
			doc.setPage(i);
			const pageSize = doc.internal.pageSize;
			const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
			const pageHeight = pageSize.height
			  ? pageSize.height
			  : pageSize.getHeight();
	
			//Header
			const header = document.getElementById("top10header");
			let headerBase64 = await html2canvas(header, {
			  scale: 3,
			  useCORS: true,
			}).then(async (canvas) => {
				return canvas.toDataURL("image/png");
			});
			
			if(screen == 1024){
			  doc.addImage(headerBase64, "PNG", 0, 0, pageWidth, 15); //1024
			}else{
			  doc.addImage(headerBase64, "PNG", 0, 0, pageWidth, 12);
			}
	
			//Footer
			const footer = document.getElementById("top10footer");
			let footerbase64 = await html2canvas(footer, {
			  scale: 3,
			  useCORS: true,
			}).then(async (canvas) => {
			  return canvas.toDataURL("image/png");
			});
	
			if(screen == 1024){
			  console.log(screen);
			  doc.addImage(footerbase64, "PNG", -7, pageHeight - 22, pageWidth+14, 22);
			}else{
			  doc.addImage(footerbase64, "PNG", -4, pageHeight - 18, pageWidth+7, 17);
			}
		  }
	
		doc.save(`top10Risk.pdf`);
		document.getElementById("top10footer").style.display = "none";
		document.getElementById("top10Risk").style.display = "none";
		element.classList.add("headerForRiskDetails");
		setPdfDownloadButton(false)
		})().catch((error) => {
		  console.error("Error generating PDF:", error);
		});
	  };
	return (
		<>
			<div className="mb-4">
				<div className="d-flex align-items-center justify-content-between">
					<select
						onChange={(e) => setStatus(e.target.value)}
						style={{
							padding: ".8em 1em",
							border: "none",
							outline: "none",
							borderRadius: "6px",
						}}
						value={status} // Use the value attribute to set the selected option
					>
						<option value="Factored">Factored</option>
						<option value="Unfactored">Unfactored</option>
					</select>
					<button
						className="btn btn-dark fs-16 px-5 h-48 align-items-center d-flex"
						onClick={() => generatePDFViaHTML()}
						// onClick={() => generatePDF()}
						// onClick={() => handleExportToPDF()}
					>
						PDF Download
					</button>
				</div>
			</div>
			{list === false ? (
				<NoData />
			) : (
				<>
					<div id="header" className="topHeaderWrapper sticky-top d-flex justify-content-between align-items-center" style={{marginBottom:'30px'}}>
        				<div>
        					<h5 className="title-head mb-0">Risks</h5>
        				</div>
        				<div className="d-flex gap-3 align-items-center">
          				<Dropdown>
            				<Dropdown.Toggle
              					id="dropdown-basic"
              					variant="transparent"
              					className="p-0 border-0"
            				>
            				</Dropdown.Toggle>
          				</Dropdown>
          				<Dropdown>
            				<Dropdown.Toggle
              					id="dropdown-basic"
              					variant="transparent"
              					className="p-0 border-0"
            					>
              					<div className="d-flex gap-2 align-items-center">
                					<div className="text-end">
                  					<h5 class="mb-0 fs-16 fw-bold lh-1">{profileInfo?.name}</h5>
                  					<p className="mb-0 fs-11 text-dark lh-base mt-1">
                    					{profileInfo?.email.toLowerCase()}
                  					</p>
                					</div>
                					<div>
                  					<div className="nameCircle">
                    					{profileInfo?.name.split(" ")[0].charAt(0)}
                    					{profileInfo?.name.split(" ")[1].charAt(0)}
                  					</div>
               					 </div>

              					</div>
            				</Dropdown.Toggle>
          				</Dropdown>
        				</div>
      				</div>
					  <div className="px-5" ref={targetRef} id="content">
							<ResponsiveContainer width="100%" height={300}>
								
								<BarChart
									data={sortDecList}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<CartesianGrid strokeDasharray="1 1" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />
									<Legend />
									<Bar dataKey="Target Evaluation" fill="#503F35" />
									<Bar dataKey="Current Evalution" fill="#E75D0F" />
									<Bar dataKey="Initial Evaluation" fill="#F9CDB0" />
								</BarChart>
							</ResponsiveContainer>
						</div>
						<div className="px-5" id="content2">
						<div className="table-responsive">
								<table
									class="table  table-style-1 mb-0 mt-4"
									style={{ width: "100%" }}
								>
									<thead>
										<tr className="toprisktable">
											<th>Ranking</th>
											<th>Risk ID</th>
											<th>Cause
											</th>
											<th style={colTwo}>
												Event
											</th>
											<th style={colTwo}>
												Consequence
											</th>
											<th>
												Owner
											</th>
											<th>
												Initial Evaluation
											</th>
											<th>
												Current Evaluation
											</th>
											<th>
												Target Evaluation
											</th>
										</tr>
									</thead>

									<tbody>
										{!!info?.data?.data?.length &&
											info?.data?.data?.map((item, index) => {
												return (
													<tr key={item?._id} className="actiontd toprisktable">
														<td style={{ width: "2em" }}>{index + 1}</td>
														<td style={{ textAlign: "left" }}>
															{item?.riskDetail?.riskNumber}
														</td>
														<td style={colTwo}>
															{item?.riskDetail?.casuse?.slice(0, 15)}
														</td>
														<td style={colTwo}>{item?.riskDetail?.riskEvent}</td>
														<td style={colTwo}>{item?.riskDetail?.riskImpact}</td>
														<td>{item?.userDetail?.name || ""}</td>
														<td>{getFinalEvalution(item?.data,"UnMitigated") || ""}</td>
														<td>
															{getFinalEvalution(item?.data,"Current") || "Not Evaluated"}
														</td>
														<td>
															{getFinalEvalution(item?.data,"Mitigated") || "Not Evaluated"}
														</td>
													</tr>
												);
											})}
										<tr>
											<td style={{ width: "2em" }}></td>
											<td style={{ textAlign: "left" }}></td>
											<td style={colTwo}></td>
											<td style={colTwo}></td>
											<td style={colTwo}></td>
											<td style={{ fontWeight: "bold" }}>Total</td>
											<td style={{ fontWeight: "bold" }}>
												{info?.data?.unMitigatedGrandTotal.toFixed(3)}
											</td>
											<td style={{ fontWeight: "bold" }}>
												{info?.data?.currentGrandTotal.toFixed(3) || "N/A"}
											</td>
											<td style={{ fontWeight: "bold" }}>
												{info?.data?.mitigatedGrandTotal.toFixed(3)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					<div style={{display:"none"}} id="footer">
        				<div className="topHeaderWrapper d-flex justify-content-between align-items-center">
        				  <img src={LOGO} width="120" alt="Logo" />
        				  <div style={{ height: "100%", marginTop: "5%" }}>
        				    <h6>Date: {currentDate}</h6>
        				    <h6>Time: {currentTime}</h6>
        				  </div>
        				</div>
      				</div>
					<div id="top10Risk" style={{display:"none"}}>
						<div style={{height:"1200px", marginTop:"120px"}}>
							<img src={topRiskchart} width="100%" height={500} />
						</div>
						<div className="px-5" style={{height:"1130px"}}>
						<div className="table-responsive">
								<table
									class="table-style-1 mb-0 mt-4"
									style={{ width: "100%" }}
								>
									<thead>
										<tr className="toprisktable">
											<th style={fontStyle}>Ranking</th>
											<th style={fontStyle}>Risk ID</th>
											<th style={fontStyle}>Cause
											</th>
											<th style={colTwo}>
												Event
											</th>
											<th style={colTwo}>
												Consequence
											</th>
											<th style={fontStyle}>
												Owner
											</th>
											<th style={fontStyle}>
												Initial Evaluation
											</th>
											<th style={fontStyle}>
												Current Evaluation
											</th>
											<th style={fontStyle}>
												Target Evaluation
											</th>
										</tr>
									</thead>

									<tbody>
										{!!info?.data?.data?.length &&
											info?.data?.data?.map((item, index) => {
												return (
													<tr key={item?._id} className="actiontd toprisktable">
														<td style={{ width: "2em",fontFamily: "Arial" }}>{index + 1}</td>
														<td style={{ textAlign: "left",fontFamily: "Arial" }}>
															{item?.riskDetail?.riskNumber}
														</td>
														<td style={colTwo}>
															{item?.riskDetail?.casuse?.slice(0, 15)}
														</td>
														<td style={colTwo}>{item?.riskDetail?.riskEvent}</td>
														<td style={colTwo}>{item?.riskDetail?.riskImpact}</td>
														<td style={fontStyle}>{item?.userDetail?.name || ""}</td>
														<td style={fontStyle}>{getFinalEvalution(item?.data,"UnMitigated") || ""}</td>
														<td style={fontStyle}>
															{getFinalEvalution(item?.data,"Current") || "Not Evaluated"}
														</td>
														<td style={fontStyle}>
															{getFinalEvalution(item?.data,"Mitigated") || "Not Evaluated"}
														</td>
													</tr>
												);
											})}
										<tr>
											<td style={{ width: "2em",fontFamily: "Arial" }}></td>
											<td style={{ textAlign: "left",fontFamily: "Arial" }}></td>
											<td style={colTwo}></td>
											<td style={colTwo}></td>
											<td style={colTwo}></td>
											<td style={{ fontWeight: "bold",fontFamily: "Arial" }}>Total</td>
											<td style={{ fontWeight: "bold",fontFamily: "Arial" }}>
												{info?.data?.unMitigatedGrandTotal.toFixed(3)}
											</td>
											<td style={{ fontWeight: "bold",fontFamily: "Arial" }}>
												{info?.data?.currentGrandTotal.toFixed(3) || "N/A"}
											</td>
											<td style={{ fontWeight: "bold",fontFamily: "Arial" }}>
												{info?.data?.mitigatedGrandTotal.toFixed(3)}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div
        style={{ marginBottom: "30px" }}
        className="headerForRiskDetails topHeaderWrapper sticky-top d-flex justify-content-between align-items-center"
        id="top10header"
      >
        <div></div>
        <div className="d-flex gap-3 align-items-center justify-content-end">
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="transparent"
              className="p-0 border-0"
            ></Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              variant="transparent"
              className="p-0 border-0"
            >
              <div className="d-flex gap-2 align-items-center">
                <div className="text-end">
                  <h5 class="mb-0 fs-16 fw-bold lh-1">{profileInfo?.name}</h5>
                  <p className="mb-0 fs-11 text-dark lh-base mt-1" style={{fontSize:"9px"}}>
                    {profileInfo?.email.toLowerCase()}
                  </p>
                </div>
                <div>
                  <div className="nameCircle" style={{fontSize:"9px"}}>
                    {profileInfo?.name.split(" ")[0].charAt(0)}
                    {profileInfo?.name.split(" ")[1].charAt(0)}
                  </div>
                </div>
              </div>
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>
      <div style={{ display: "none" }} id="top10footer">
        <div className="topHeaderWrapper">
          <div>
            <h6 style={{fontSize:"10px"}}>Date: {currentDate}</h6>
            <h6 style={{fontSize:"10px"}}>Time: {currentTime}</h6>
          </div>
          <img src={LOGO} width="80" alt="Logo" />
        </div>
      </div>
				</>
			)}
		</>
	);
};

export default ChartOne;
