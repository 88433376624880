import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import UserList from "./UserList";
import { useSelector } from "react-redux";


const RiskManager = () => {
  const rmName = useSelector((state) => state?.constants?.rmName);
  const data = {
    userRole: 1,
    userType: 0,
  };
  return (
    <div className="input-group">
      <label className="cf_label mb-1" htmlFor="">EP Risk Manager</label>
      <Dropdown className="w-100">
        <Dropdown.Toggle
          className="input-2 w-100 dropWithArrow2 px-3"
          id="dropdown-basic"
        >
         {(rmName?.length > 20 ? rmName?.slice(0, 20) + "..." : rmName) ||
            ""}
        </Dropdown.Toggle>

        <UserList data={data} dropValue={2} />
      </Dropdown>
    </div>
  );
};

export default RiskManager;
